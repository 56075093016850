import React from 'react';
import './SchoolInfo.css';

const SchoolInfo = (props) => {
  const { img, alt, schoolName, schoolAddress } = props;

  return (
    <div className="school-details">
      <img className="school-details__image" src={img} alt={alt} />
      <div className="school-details__name">{schoolName}</div>
      <div className="school-details__address">{schoolAddress}</div>
    </div>
  );
};

export default SchoolInfo;
