import React from 'react';
import './LoginSuccess.css';
import GreenTick from '../../Assets/Login/ResetPasswordScreen/green-check.png';
import arrowLogin from '../../Assets/Login/ResetPasswordScreen/study-circle-grey.png';

const SuccessMessage = (props) => {
  const { sendMail } = props;

  return (
    <div className="container">
      {sendMail ? (
        <div className="container__element" style={{ width: '22%' }}>
          <img src={GreenTick} alt="Main sent tick mark" />
          Mail Sent!
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p className="container__element-message">Your new password have been saved.</p>
          <div className="container__element" style={{ width: '18%' }}>
            <a href="/login" className="container__element-text">
              {' '}
              login
            </a>
            <img src={arrowLogin} alt="Login link" />
          </div>
        </div>
      )}
    </div>
  );
};

export default SuccessMessage;
