import React from 'react';
import './WebUrl.css';

const WebUrl = (props) => {
  const { handleUrlChange, value } = props;

  return (
    <div>
      <input className="attachment-url" value={value} type="url" placeholder="Paste any web link here" onChange={handleUrlChange} />
    </div>
  );
};

export default WebUrl;
