import React from 'react';
import './TertiaryCard.css';

const TertiaryCard = (props) => {
  const { name, date } = props;
  return (
    <div className="tertiary-card">
      <div className="tertiary-card__value">
        {name}: {date}
      </div>
    </div>
  );
};

export default TertiaryCard;
