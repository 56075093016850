import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from './Screens/LoginScreen/Login/Login';
import ResetPassword from './Screens/LoginScreen/ResetPassword/ResetPassword.js';
import NewPassword from './Screens/LoginScreen/NewPassword/NewPassword.js';
import ContentLibrary from './Screens/ContentLibrary/ContentLibrary/ContentLibrary';
import UploadContent from './Screens/ContentLibrary/UploadContent/UploadContent.js';
import ContentDetails from './Screens/ContentLibrary/ContentDetails/ContentDetails';
import ActivityStack from './Screens/ActivityStack/ActivityStackDashboard';
import UploadActivity from './Screens/ActivityStack/UploadActivity/UploadActivity';
import ExplainerVideos from './Screens/ExplainerVideos/ExplainerVideos/ExplainerVideos';
import Settings from './Screens/Settings/Settings';
import Reports from './Screens/Reports';
import ActivityDetails from './Screens/ActivityStack/ActivityDetails/ActivityDetails';
import PlayKit from './Screens/PlayKit/PlayKit.js';
import UploadPlayKit from './Screens/PlayKit/UploadPlayKit/UploadPlayKit.js';
import UpdatePlayKit from './Screens/PlayKit/UpdatePlayKit/UpdatePlayKit.js';

function App(props) {
  return (
    <div className="App">
      <Switch>
        <Route path="/" exact={true}>
          <Redirect to="/login" />
        </Route>
        <Route path="/login" component={Login} exact={true} />
        <Route path="/:user_id/new-password" component={NewPassword} exact={true} />
        <Route path="/reset_password" component={ResetPassword} exact={true} />
        <Route path="/content-library" component={ContentLibrary} exact={true} />
        <Route path="/activity-stack" component={ActivityStack} exact={true} />
        <Route path="/settings" component={Settings} exact={true} />
        <Route path="/reports" component={Reports} exact={true} />
        <Route path="/playKit" component={PlayKit} exact={true} />
        <Route path="/explainer-videos" component={ExplainerVideos} exact={true} />
        {/* <Route path="qb.stucle.in" component={QuestionBank} exact={true} /> */}
        <Route path="/upload-content" component={UploadContent} exact={true} />
        <Route path="/upload-activity" component={UploadActivity} exact={true} />
        <Route path="/upload-playKit" component={UploadPlayKit} exact={true} />
        <Route path="/playKit-details/:id" component={UpdatePlayKit} exact={true} />
        <Route path="/content-details/:id" component={ContentDetails} exact={true} />
        <Route path="/activity-details/:id" component={ActivityDetails} exact={true} />
        <Route path="*">
          <Redirect to="/content-library" />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
