import React from 'react';
import './AddContentSelection.css';

const AddContentSelection = (props) => {
  const {
    default_option,
    values,
    id_name,
    display_name,
    handleIdChange,
    label,
    name,
    formErrors,
    id,
    marginTop,
    value,
    display_eng_name,
    isRequired,
  } = props;

  return (
    <div style={{ marginTop: marginTop ? marginTop : null }}>
      <div className={formErrors ? 'form-dropdown__left' : 'form-dropdown__left--err'}>
        <div className="add-content-label">
          {label}
          {isRequired && <span style={{ marginLeft: '3px', color: 'red' }}>*</span>}
        </div>
        <select id={id} name={name} className="add-content-selection" onChange={handleIdChange} value={value}>
          <option value={'-1'}>{default_option}</option>
          {values &&
            values.map((ele, index) => {
              let displayName = ele[display_eng_name] ? `${ele[display_name]} - ${ele[display_eng_name]}` : `${ele[display_name]}`;
              return (
                <option key={index} value={ele[id_name]}>
                  {displayName}
                </option>
              );
            })}
        </select>
      </div>
      {formErrors[name] && <div className="add-content-form-error">{formErrors[name]}</div>}
    </div>
  );
};

export default AddContentSelection;
