import React from 'react';
import './ActivityDescriptionInfo.css';

const ActivityDescriptionInfo = (props) => {
  const { name, src, marginTop } = props;

  return (
    <div className="activity-description-info" style={{ marginTop: marginTop && marginTop }}>
      <img src={src} alt={name} className="activity-description-info__img" />
      <div className="activity-description-info__title">{name}</div>
    </div>
  );
};

export default ActivityDescriptionInfo;
