import React, { useState, useRef, useEffect } from 'react';
import { fetchTileCaption } from '../../api/master_data';
import { fetchExplainerVideoDetail } from '../../api/activity_stack';
import './TileSearch.css';

const TileSearch = (props) => {
  const {
    label,
    formErrors,
    name,
    setTileSearch,
    tileSearch,
    withinModal,
    placeholder,
    updateSearchText,
    prop_value,
    getSelectedExplainerVideoData,
    setExplainerVideoData,
    explainerVideoData,
  } = props;

  const [suggestions, setSuggestions] = useState([]);
  const wrapper = useRef(null);
  const searchInput = useRef();
  let tileSuggestionsArr = [];

  const handleTextInputChange = async (e) => {
    let searchText = e.target.value;
    setTileSearch(searchText);
    if (name === 'explainerVideoTitle') {
      if (searchText.length) {
        setExplainerVideoData({
          ...explainerVideoData,
          explainer_video_id: -1,
        });
      } else {
        setExplainerVideoData({
          ...explainerVideoData,
          explainer_video_id: 0,
        });
      }
    }
    withinModal && updateSearchText(searchText);
  };

  const handleClickOutSide = (e) => {
    const { current: wrap } = wrapper; //wrapper.current = wrap
    if (wrap && !wrap.contains(e.target)) {
      setSuggestions([]);
    }
  };

  const keyStrokeFunction = (e) => {
    let keysToPress = ['Enter', 'ArrowUp', 'ArrowDown', 'Escape'];
    const keyPressed = e.key;
    if (keyPressed === 'ArrowUp' || keyPressed === 'ArrowDown' || keyPressed === 'Escape' || keyPressed === 'Enter') {
      if (keysToPress.indexOf(keyPressed) > -1) {
        e.preventDefault();
      }

      // This is to get the array of all the elments inside ul
      const resultItems = Array.from(wrapper.current.children);
      // console.log("resultItems", resultItems)

      // This is to get the index of the li which is focused presently.
      const activeResultIndex = resultItems.findIndex((child) => {
        return child.querySelector('div') === document.activeElement;
      });
      // console.log("activeResultIndex", activeResultIndex)

      if (keyPressed === 'Enter') {
        let selectedTileCaption = resultItems[activeResultIndex]?.querySelector('div')?.innerText;
        // console.log("selectedTileCaption", selectedTileCaption)
        setTileSearch(selectedTileCaption);
        setSuggestions([]);
        return;
      }

      const inputIsFocused = searchInput.current;

      if (keyPressed === 'ArrowUp') {
        if (inputIsFocused === document.activeElement) {
          resultItems[resultItems.length - 1].querySelector('div').focus();
        } else if (resultItems[activeResultIndex - 1]) {
          resultItems[activeResultIndex - 1].querySelector('div').focus();
        } else {
          inputIsFocused.focus();
        }
      }

      if (keyPressed === 'ArrowDown') {
        if (inputIsFocused === document.activeElement) {
          resultItems[0].querySelector('div').focus();
        } else if (resultItems[activeResultIndex + 1]) {
          resultItems[activeResultIndex + 1].querySelector('div').focus();
        } else {
          searchInput.current.focus();
        }
      }

      if (keyPressed === 'Escape') {
        setSuggestions([]);
      }
    }
  };

  useEffect(() => {
    if (suggestions.length > 0) {
      document.addEventListener('mousedown', handleClickOutSide);
      document.addEventListener('keydown', keyStrokeFunction);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
      document.removeEventListener('keydown', keyStrokeFunction);
    };
    // eslint-disable-next-line
  }, [suggestions]);

  useEffect(() => {
    const getData = setTimeout(() => {
      tileSuggestions(tileSearch);
    }, 200);
    return () => clearTimeout(getData);
    // eslint-disable-next-line
  }, [tileSearch]);

  const tileSuggestions = async (val) => {
    let mysearchtext = val;
    if (mysearchtext === undefined || mysearchtext === '') {
      console.log('Text field is empty');
      return setSuggestions([]);
    } else {
      console.log('Search text is ' + mysearchtext);
      withinModal && updateSearchText(mysearchtext);
    }

    try {
      if (prop_value === 'tile_caption') {
        tileSuggestionsArr = await fetchTileCaption(mysearchtext);
      } else if (prop_value === 'explainer_video_title') {
        tileSuggestionsArr = await fetchExplainerVideoDetail(mysearchtext);
      }
      let firstTile = tileSuggestionsArr[0][prop_value];
      firstTile = firstTile.replace(/\s+/g, ' ').trim();
      // eslint-disable-next-line
      if (firstTile == mysearchtext) return;
      setSuggestions(tileSuggestionsArr);
    } catch (err) {
      console.log(err);
    }
  };

  const selectedOption = (suggestionText, suggestionObj) => {
    setTileSearch(suggestionText);
    setSuggestions([]);
    if (name === 'explainerVideoTitle') getSelectedExplainerVideoData(suggestionObj);
  };

  return (
    <div>
      <div className="form-right-text form-right-text-input">
        {label && <div className="form-right-text__label">{label}</div>}
        <input
          className="form-right-text__input-field"
          type="text"
          value={tileSearch}
          onChange={handleTextInputChange}
          ref={searchInput}
          placeholder={placeholder}
        />
      </div>
      {suggestions.length > 0 && (
        <ul className={withinModal ? 'text-input__suggestions--modal' : 'form-right-text__input-suggestions'} ref={wrapper}>
          {suggestions.map((suggestion, index) => {
            return (
              <li key={index}>
                <div
                  className="text-input__suggestion--element"
                  onClick={(e) => selectedOption(e.target.innerText, suggestion)}
                  tabIndex={index}
                >
                  {suggestion[prop_value]}
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {!withinModal && formErrors[name] && <div className="add-content-form-error">{formErrors[name]}</div>}
    </div>
  );
};

export default TileSearch;
