import React, { useState } from 'react';
import Modal from 'react-modal';
import './ToolModal.css';
import RiseLoader from 'react-spinners/RiseLoader';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PlayKitTextInput from '../PlayKitTextInput/PlayKitTextInput';
import FilterButtons from './../FilterButtons/FilterButtons';
import { cmdsFileUpload } from '../../FileUploads/cmdsFileUpload';
import { TOOL_NAME_LENGTH, TOOL_DESCRIPTION_LENGTH } from './../../utils/playKitConstants';

const customStyles = {
  content: {
    position: 'relative',
    left: '50%',
    display: 'inline-block',
    width: '340px',
    maxHeight: '585px',
    background: '#FFFFFF',
    borderRadius: '16px',
    transform: 'translate(-50%, 5%)',
    overflowX: 'hidden',
  },
  overlay: {
    zIndex: '2',
  },
};

Modal.setAppElement('#root');

const ToolModal = (props) => {
  const { openModal, handleModalOpenClose, toggleEditComponent, updateLocalToolList, editToolInfo, editToolMode, toggleEditMode } =
    props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState('');
  const [filePath, setFilePath] = useState('');
  const [toolInfo, setToolInfo] = useState({
    tool_name: editToolMode ? editToolInfo.tool_name : '',
    tool_description: editToolMode ? editToolInfo.tool_description : '',
    tool_image_url: editToolMode ? editToolInfo.tool_image_url : '',
    tool_id: editToolMode ? editToolInfo.tool_id : '',
    newlyAdded: editToolMode ? editToolInfo.newlyAdded : false,
    alreadyExisted: editToolMode ? editToolInfo.alreadyExisted : false,
    edited: editToolMode ? editToolInfo.edited : false,
    deleted: editToolMode ? editToolInfo.edited : false,
  });
  const [toolFormErrors, setToolFormErrors] = useState({
    tool_name: '',
    tool_description: '',
    toolThumbnail: '',
    tool_image_url: '',
  });
  let errObj = {};

  const modalOpenClose = () => {
    setModalIsOpen(!modalIsOpen);
    handleModalOpenClose();
    toggleEditMode();
  };

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    if (name === 'tool_name') {
      if (value?.length <= TOOL_NAME_LENGTH) {
        setToolInfo((prevState) => {
          return { ...prevState, [name]: value };
        });
        setToolFormErrors((prevState) => {
          return { ...prevState, [name]: '' };
        });
      } else {
        setToolFormErrors((prevState) => {
          return { ...prevState, [name]: 'Tool name cannot exceed more than 30 characters' };
        });
      }
    }
    if (name === 'tool_description') {
      if (value.length <= TOOL_DESCRIPTION_LENGTH) {
        setToolInfo((prevState) => {
          return { ...prevState, [name]: value };
        });
        setToolFormErrors((prevState) => {
          return { ...prevState, [name]: '' };
        });
      } else {
        setToolFormErrors((prevState) => {
          return { ...prevState, [name]: 'Tool description cannot exceed more than 90 characters' };
        });
      }
    }
  };

  const handleFileInput = async (e) => {
    setLoading(true);
    const infile = e?.target?.files[0];
    if (infile) {
      setFile(infile);
      const filePath = await cmdsFileUpload(infile, setFilePath, 'playkit_tool_thumbnail');
      setToolInfo((prevState) => {
        return { ...prevState, tool_image_url: filePath };
      });
      setToolFormErrors((prevState) => {
        return { ...prevState, tool_image_url: '' };
      });
      setLoading(false);
    } else {
      toast.error('Trouble uploading the ToolKit thumbnail');
      setLoading(false);
    }
  };

  const handleRemoveThumbnail = () => {
    setToolInfo((prevState) => {
      return { ...prevState, toolThumbnail: null, tool_image_url: '' };
    });
  };

  const runValidations = async () => {
    if (toolInfo.tool_name.trim().length <= 0) {
      errObj.tool_name = 'Tool Name cannot be empty';
    }
    if (toolInfo.tool_description.trim().length <= 0) {
      errObj.tool_description = 'Tool Description cannot be empty';
    }
    if (editToolMode) {
      if (toolInfo.tool_image_url.trim().length === 0) {
        errObj.tool_image_url = 'Error in uploading thumbnail, please try again!';
      }
      if (toolInfo.tool_image_url.trim().length === 0 && !file) {
        errObj.tool_image_url = 'Please upload thumbnail image!';
      }
    } else {
      if (!file && toolInfo.tool_image_url.trim().length === 0) {
        errObj.tool_image_url = 'Error in uploading thumbnail, please try again!';
      }
      if (!file) {
        errObj.tool_image_url = 'Please upload thumbnail image!';
      }
    }
  };

  const resetForm = () => {
    setToolInfo((prevState) => {
      return {
        ...prevState,
        tool_description: '',
        tool_name: '',
        toolThumbnail: null,
        tool_image_url: '',
        tool_id: '',
        base64String: '',
      };
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    await runValidations();
    if (Object.keys(errObj).length === 0) {
      setToolFormErrors({});
      updateLocalToolList(toolInfo);
      editToolMode && toggleEditMode();
      resetForm();
      handleModalOpenClose();
      setLoading(false);
    } else {
      setToolFormErrors(errObj);
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="loading__spinners" style={{ zIndex: '10' }}>
          <RiseLoader
            color="#CC435F"
            margin={5}
            cssOverride={{
              position: 'absolute',
              top: '50%',
              left: '45%',
              zIndex: 5,
            }}
            loading={loading}
            size={50}
            speedMultiplier={1}
          />
        </div>
      ) : null}
      <Modal
        isOpen={openModal}
        onRequestClose={modalOpenClose}
        style={customStyles}
        contentLabel="Add Tool"
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}>
        <div className="add-tool-form">
          <div className="add-tool-form__header">
            <div className="add-tool-form__header-text">{toggleEditComponent ? `Edit Tool` : `Add Tool`}</div>
          </div>
          <PlayKitTextInput
            width={'100%'}
            label={'Tool Name'}
            value={toolInfo.tool_name}
            characters={`${toolInfo.tool_name?.length}/${TOOL_NAME_LENGTH - toolInfo.tool_name?.length}`}
            name={'tool_name'}
            handleChange={handleInputChange}
            maxChar={TOOL_NAME_LENGTH}
            errorMessage={toolFormErrors?.tool_name}
            placeHolder="Enter Tool name"
          />
          <PlayKitTextInput
            width={'100%'}
            label={'Tool Description'}
            value={toolInfo.tool_description}
            characters={`${toolInfo.tool_description?.length}/${TOOL_DESCRIPTION_LENGTH - toolInfo.tool_description?.length}`}
            name={'tool_description'}
            handleChange={handleInputChange}
            maxChar={TOOL_DESCRIPTION_LENGTH}
            errorMessage={toolFormErrors?.tool_description}
            height={'100px'}
            placeHolder="Enter Tool description"
          />
          {toolInfo?.tool_image_url ? (
            <div className="add-tool-form__add-thumbnail-holder">
              <img
                // src={
                //   toolInfo?.base64String
                //     ? `${toolInfo?.base64String}`
                //     : toolInfo?.alreadyExisted
                //       ? `${toolInfo?.tool_image_url}`
                //       : `data:image/jpeg;base64,${toolInfo?.tool_image_url}`
                // }
                src={`${toolInfo?.tool_image_url}`}
                alt="tool-thumbnail"
                className="add-tool-form__add-thumbnail-img"
                loading="eager"
              />
              <div className="add-tool-form__add-thumbnail-btns">
                <div className="add-tool-form__add-thumbnail-btns__add">
                  <input
                    type="file"
                    value={''}
                    accept=".png, .jpg, .jpeg"
                    className="add-tool-form__add-thumbnail-input"
                    onChange={handleFileInput}
                    multiple={false}
                  />
                  <div style={{ cursor: 'pointer', padding: '4px 10px', width: '100%', height: '100%' }}>{'Change'}</div>
                </div>
                <div className="add-tool-form__add-thumbnail-btns__remove" onClick={handleRemoveThumbnail}>
                  {'Remove'}
                </div>
              </div>
            </div>
          ) : (
            <div className="add-tool-form__add-img-holder">
              <input
                type="file"
                value={''}
                accept=".png, .jpg, .jpeg"
                style={{ position: 'absolute', opacity: '0', top: '0%', height: '100%', cursor: 'pointer' }}
                onChange={handleFileInput}
                multiple={false}
              />
              <div className="add-tool-form__add-img-btn">{'Add Image'}</div>
            </div>
          )}
          {toolFormErrors?.tool_image_url?.length > 0 && (
            <div className="playKit-input-holder__err">{`${toolFormErrors.tool_image_url}`}</div>
          )}
          <FilterButtons handleModalApply={handleSubmit} modalOpenClose={modalOpenClose} apply={'Submit'} marginTop={'0'} />
        </div>
      </Modal>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />
    </>
  );
};

export default ToolModal;
