import React from 'react';
import './Button.css';

const Button = (props) => {
  const { type, img, name } = props;

  return (
    <button type={type} className="action-button">
      <div style={{ display: 'flex', width: '100%', justifyContent: img ? 'space-between' : 'center' }}>
        <div style={{ textAlign: img ? 'left' : 'center' }}>{name}</div>
        {img && <img src={img} alt="signin" />}
      </div>
    </button>
  );
};

export default Button;
