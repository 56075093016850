import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Password from '../Password/Password.js';
import Button from '../Button/Button.js';
import '../../Assets/css/msedge.css';
import { confirmPasswordForm } from '../../api/login';

const NewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .min(8, 'The password must contain 8 - 64 characters')
    .max(64, 'The password must contain 8 - 64 characters')
    .required('Password cannot be empty')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]/,
      'Must Contain One Uppercase, One Lowercase, One Number and one special case Character',
    ),
  confirmPassword: Yup.string()
    .trim()
    .required('Confirm Password field cannot be empty')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const NewPasswordForm = (props) => {
  const { handleToggle, userId } = props;

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: async (formData, { resetForm }) => {
      try {
        let response = await confirmPasswordForm(formData, userId);
        console.log('response', response);
        resetForm();
        toast.success('New password saved successfully!');
        setTimeout(() => {
          handleToggle();
        }, 4000);
      } catch (err) {
        toast.error(err.message);
      }
    },
    validateOnChange: true,
    validationSchema: NewPasswordSchema,
  });

  return (
    <>
      <div className="new-password-form">
        <div className="new-password-form__container">
          <form onSubmit={formik.handleSubmit} className="formik" style={{ justifyContent: 'flex-start' }}>
            <div className="formik-input" style={{ width: '70%', padding: '0%' }}>
              <Password
                onChange={formik.handleChange}
                value={formik.values.password}
                errors={formik.errors.password}
                placeholder="Password"
                name="password"
                showPassword={false}
              />

              <Password
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                errors={formik.errors.confirmPassword}
                placeholder="Re-enter password"
                name="confirmPassword"
                showPassword={true}
              />

              <Button type="submit" name="Save" />
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />
    </>
  );
};

export default NewPasswordForm;
