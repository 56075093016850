import React from 'react';
import { Link } from 'react-router-dom';
import './DisplayContent.css';
import ArrowLeft from '../../../../Assets/ContentLibrary/ContentDetails/arrow-left.png';
import ContentMenu from '../../../../components/ContentMenu/ContentMenu';
import DisplayCards from '../../../../components/DisplayCards/DisplayCards';
import Suggestions from '../../../../components/Suggestions/Suggestions';
import TimeLine from '../../../../components/TimeLine/TimeLine';

const DisplayContent = (props) => {
  const { handleToggle, contentDetails, contentFeedback, setContentFeedback, contentHistory } = props;

  return (
    <div className="display-activity">
      <div className="display-content__header">
        <Link to="/content-library">
          <img className="header-img" src={ArrowLeft} alt="back to content library" />
        </Link>
        <div className="header-text">
          Content {'>'} <span style={{ textTransform: 'none' }}>Content Details</span>
        </div>
      </div>
      <div className="display-content__main">
        <ContentMenu contentInfo={contentDetails} id_name="content_id" handleToggle={handleToggle} />
        <DisplayCards contentDetails={contentDetails} id_name="content_id" />
        <Suggestions contentFeedback={contentFeedback} setContentFeedback={setContentFeedback} />
        <TimeLine contentHistory={contentHistory} />
      </div>
    </div>
  );
};

export default DisplayContent;
