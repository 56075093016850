import React from 'react';
import './SettingsCheckbox.css';

const SettingsCheckbox = (props) => {
  const { name, description, value, marginTop } = props;
  return (
    <div className="settings-form-checkbox" style={{ marginTop: marginTop && marginTop }}>
      <div className="settings-form-checkbox__header">{name}</div>
      <label className="settings-form-checkbox__label">
        <input type="checkbox" className="settings-form-checkbox-input" value={value} />
        <span className="settings-form-checkbox-description">{description}</span>
      </label>
    </div>
  );
};

export default SettingsCheckbox;
