import React from 'react';
import './ToggleSwitch.css';

const ToggleSwitch = (props) => {
  const { retriveToggleValue, is_min_recommended } = props;

  const handleToggleChange = () => {
    retriveToggleValue(!is_min_recommended);
  };

  return (
    <label className="toggle-switch">
      <input type="checkbox" value={is_min_recommended} checked={is_min_recommended} onChange={handleToggleChange} />
      <span className="toggle-switch__slider" />
    </label>
  );
};

export default ToggleSwitch;
