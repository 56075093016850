import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js/auto';

ChartJS.register(ArcElement, Tooltip, Legend);

const ActivityPieChart = (props) => {
  const { chartData, backgroundColorArr } = props;
  let dataArr = [],
    labelArr = [];

  chartData.forEach((obj) => {
    for (const keys in obj) {
      let value = obj[keys];
      dataArr.push(value);
    }
  });

  chartData.forEach((obj) => {
    for (const keys in obj) {
      let value = keys;
      labelArr.push(value);
    }
  });

  const data = {
    datasets: [
      {
        data: dataArr,
        backgroundColor: backgroundColorArr,
        weight: 2,
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: labelArr,
  };

  const options = {
    elements: {
      arc: {
        borderWidth: 1,
      },
    },
    segmentShowStroke: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: 'rgb(255, 99, 132)',
        },
      },
      tooltip: {
        enabled: true,
        padding: 2,
        xAlign: 'right',
        yAlign: 'center',
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    animation: { animateRotate: true, animateScale: true },
  };

  return (
    <div style={{ height: '90%', width: '40%', display: 'flex', justifyContent: 'center' }}>
      <Pie data={data} options={options} width={70} height={70} />
    </div>
  );
};

export default ActivityPieChart;
