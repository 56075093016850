import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './AddExplainerVideoModal.css';
import isURL from 'validator/lib/isURL';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import RiseLoader from 'react-spinners/RiseLoader';
import FilterButtons from '../FilterButtons/FilterButtons';
import ModalCancel from '../../Assets/ActivityStack/modal-cancel.png';
import UploadThumbnail from './../UploadThumbnail/UploadThumbnail';
import BrowseFile from './../BrowseFile/BrowseFile';
import TextInput from '../TextInput/TextInput';
import AddExplainerVideoSelection from '../AddExplainerVideoSelection/AddExplainerVideoSelection';
import VideoPlayer from './../VideoPlayer/VideoPlayer';
import { cmdsFileUpload } from '../../FileUploads/cmdsFileUpload';
import { MultiSelect } from 'react-multi-select-component';
import { fetchBoards, fetchCourse, fetchLanguageList, fetchSubjects } from '../../api/master_data';
import { uploadExplainerVideo, editVideoDetail } from '../../api/explainer_video_table';

const customStyles = {
  content: {
    position: 'relative',
    left: '50%',
    display: 'inline-block',
    width: '340px',
    height: '560px',
    background: '#FFFFFF',
    borderRadius: '16px',
    transform: 'translate(-50%, -5%)',
    overflowX: 'hidden',
  },
  overlay: {
    zIndex: '2',
  },
};

Modal.setAppElement('#root');

const AddExplainerVideoModal = (props) => {
  const { openModal, handleModalOpenClose, videoDetail, editVideoId, toggleEditComponent, setRender } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [videoFilePath, setVideoFilePath] = useState(
    toggleEditComponent && videoDetail['link_type'] === 'FILE' ? videoDetail.video_url : '',
  );
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [videoThumbnailPath, setVideoThumbnailPath] = useState(
    toggleEditComponent && videoDetail.thumbnail_url ? videoDetail.thumbnail_url : '',
  );
  const [boards, setBoards] = useState([]);
  const [board_id, setBoardId] = useState(toggleEditComponent && videoDetail.board_id ? videoDetail.board_id : -1);
  const [courses, setCourses] = useState([]);
  const [course_id, setCourseId] = useState(toggleEditComponent && videoDetail.course_id ? videoDetail.course_id : -1);
  const [subjects, setSubjects] = useState([]);
  const [subject_id, setSubjectId] = useState(toggleEditComponent && videoDetail.subject_id ? videoDetail.subject_id : -1);
  const [duration, setDuration] = useState(toggleEditComponent && videoDetail.duration_min ? videoDetail.duration_min : '');
  const [allLanguages, setAllLanguages] = useState([]);
  const [audio_language_id, setAudioLanguageId] = useState(
    toggleEditComponent && videoDetail.audio_language_id ? videoDetail.audio_language_id : -1,
  );
  const [selectedSubtitleLang, setSelectedSubtitleLang] = useState(
    toggleEditComponent && videoDetail.hasOwnProperty('subtitle_language_json') ? videoDetail.subtitle_language_json : [],
  );
  const [title, setTitle] = useState(toggleEditComponent && videoDetail.title ? videoDetail.title : '');
  const [videoLink, setVideoLink] = useState(
    toggleEditComponent && videoDetail['link_type'] === 'WEBLINK' ? videoDetail.video_url : '',
  );
  const [description, setDescription] = useState(toggleEditComponent && videoDetail.description ? videoDetail.description : '');
  const [formErrors, setFormErrors] = useState({});
  const [explainer_video_link_type, setExplainerVideoLinkType] = useState('');
  const [isVideoPreviewOpen, setIsVideoPreviewOpen] = useState(false);
  const [toggleLinkType, setToggleLinkType] = useState('');

  const institution_id = localStorage.getItem('institution_id');
  const user_id = localStorage.getItem('user_id');
  let err = {};

  useEffect(() => {
    const getValues = async () => {
      setLoading(true);
      setBoards(await fetchBoards());
      setCourses(await fetchCourse(board_id));
      setSubjects(await fetchSubjects(board_id, course_id));
      setLoading(false);
    };
    try {
      getValues();
    } catch (err) {
      console.log('err in getValues - Add explainer video', err);
      toast.error('Something went wrong try again');
      if (err?.message) {
        toast.error(err.message);
      }
      setLoading(false);
    }
  }, [board_id, course_id, subject_id]);

  useEffect(() => {
    const fetchLang = async () => {
      let lang = await fetchLanguageList();
      let tempArray = lang.map((lang) => {
        return { id: lang.id, value: lang.code, label: lang.name };
      });
      setAllLanguages(tempArray);
    };
    try {
      fetchLang();
    } catch (err) {
      console.log('err in fetchLang - Add explainer video', err);
      toast.error('Something went wrong try again');
      if (err?.message) {
        toast.error(err.message);
      }
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (videoFilePath.length > 0) {
      setToggleLinkType('File');
    }
    if (videoLink.length > 0) {
      setToggleLinkType('Web Link');
    }
  }, [videoFilePath, videoLink]);

  const modalOpenClose = async () => {
    setModalIsOpen(!modalIsOpen);
    handleModalOpenClose();
    // setVideoDetail({})
  };

  const handleIdChange = async (e) => {
    let value = e.target.name;
    if (value === 'board') {
      setBoardId(e.target.value);
    } else if (value === 'course') {
      setCourseId(e.target.value);
    } else if (value === 'subject') {
      setSubjectId(e.target.value);
    } else if (value === 'audio_language') {
      setAudioLanguageId(e.target.value);
    } else if (value === 'duration') {
      setDuration(e.target.value);
    } else if (value === 'video_link') {
      setExplainerVideoLinkType('WEBLINK');
      setVideoLink(e.target.value);
      setVideoFile(null);
      setVideoFilePath('');
    } else if (value === 'title') {
      setTitle(e.target.value);
    } else if (value === 'video_description') {
      setDescription(e.target.value);
    }
  };

  const handleFileUpload = async (e) => {
    setLoading(true);
    setExplainerVideoLinkType('FILE');
    setVideoLink('');
    let uploadedFile = e.target.files[0];
    if (uploadedFile.size > 10e8) {
      toast.error('File size should be less than 100mb');
    }
    setVideoFile(uploadedFile);
    cmdsFileUpload(uploadedFile, setVideoFilePath, null);
    setLoading(false);
    if (toggleEditComponent) {
      setVideoLink('');
      videoFilePath.trim().length !== 0 && toast.success('File uploaded successfully!!');
    }
  };

  const handleFileRemove = (e) => {
    e.preventDefault();
    setVideoFile(null);
    setVideoFilePath('');
    setExplainerVideoLinkType('');
    if (toggleEditComponent) toast.success('File removed successfully!!');
  };

  const handleUploadThumbnail = async (e) => {
    let uploadedThumbnail = e.target.files[0];
    if (uploadedThumbnail) {
      setLoading(true);
      setVideoThumbnail(uploadedThumbnail);
      await cmdsFileUpload(uploadedThumbnail, setVideoThumbnailPath, 'thumbnail');
      setLoading(false);
    }
    if (toggleEditComponent && videoThumbnailPath !== '') {
      toast.success('Thumbnail changed successfully!!');
    }
  };

  const runValidations = async () => {
    if (+board_id === -1) {
      err.board = 'Please select Board';
    }
    if (+course_id === -1) {
      err.course = 'Please select Course';
    }
    if (+subject_id === -1) {
      err.subject = 'Please select Subject';
    }
    if (+audio_language_id === -1) {
      err.audio_language = 'Please select Audio Language';
    }
    if (title.trim().length === 0) {
      err.title = 'Please provide Video Title';
    }
    if (description.trim().length === 0) {
      err.video_description = 'Please provide Video Description';
    }
    if (videoDetail) {
      if (String(duration).trim().length === 0) {
        err.duration = 'Please provide Video Duration';
      }
    } else if (duration.trim().length === 0) {
      err.duration = 'Please provide Video Duration';
    }
    if (selectedSubtitleLang.length === 0) {
      err.selectedSubtitleLang = 'Please select Subtitle language';
    }
    // Add explainer video form validation for video thumbnail
    if (!toggleEditComponent) {
      if (videoThumbnail === null) {
        err.videoThumbnail = 'Please provide thumbnail';
      }
      if (videoThumbnailPath.trim().length === 0) {
        err.videoThumbnail = 'Please upload thumbnail once again';
      }
    }

    // Edit explainer video form validation for video thumbnail
    if (videoThumbnail === null && toggleEditComponent && videoThumbnailPath.trim().length === 0) {
      err.videoThumbnail = 'Please provide thumbnail';
    }

    // Add explainer video form validation for File
    if (!toggleEditComponent) {
      if (videoFile === null && videoFilePath.trim().length === 0 && videoLink.trim().length === 0) {
        err.videoFilePath = 'Please upload file';
        err.video_link = 'Please upload file or paste valid url';
      }
    }

    // Edit explainer video form validation for file
    if (toggleEditComponent && videoFilePath.trim().length === 0 && videoLink.trim().length === 0 && isURL(videoLink)) {
      err.videoFilePath = 'Please upload file';
      err.video_link = 'Please upload file or paste valid url';
    }
  };

  const resetForm = async () => {
    setBoardId(-1);
    setCourseId(-1);
    setSubjectId(-1);
    setAudioLanguageId(-1);
    setTitle('');
    setDescription('');
    setVideoFile(null);
    setVideoLink('');
    setVideoFilePath('');
    setVideoThumbnail(null);
    setSelectedSubtitleLang([]);
    setDuration('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await runValidations();
    setModalIsOpen(!modalIsOpen);

    if (Object.keys(err).length === 0) {
      setFormErrors({});

      let formData = {
        title: title,
        description: description,
        video_url: videoFilePath.length > 0 ? videoFilePath : videoLink,
        thumbnail_url: videoThumbnailPath,
        institution_id: institution_id,
        board_id: +board_id,
        course_id: +course_id,
        subject_id: +subject_id,
        duration_min: +duration,
        audio_language_id: +audio_language_id,
        subtitle_language_json: selectedSubtitleLang,
        added_by_user_id: +user_id,
        link_type: explainer_video_link_type,
      };
      console.log('formData', formData);

      try {
        if (!toggleEditComponent) {
          setLoading(true);
          await uploadExplainerVideo(formData);
          toast.success('Explainer video saved successfully!');
          await resetForm();
        } else {
          setLoading(true);
          await editVideoDetail(editVideoId, formData);
          toast.success('Explainer video updated successfully!');
          await resetForm();
        }
        setTimeout(() => {
          modalOpenClose();
        }, 3000);
      } catch (err) {
        console.log('err in activity stack uploading', err);
        toast.error('Something went wrong try again');
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        }
        setLoading(false);
      }
      setRender(true);
    } else {
      toast.error('Please provide required details');
      setFormErrors(err);
      setLoading(false);
    }
  };

  const handleVideoPreviewModal = () => {
    if (videoFilePath.length > 0 || videoLink.length > 0) {
      setIsVideoPreviewOpen((isVideoPreviewOpen) => !isVideoPreviewOpen);
    } else {
      toast.error('Error in playing video due to bad URL');
    }
  };
  console.log('videoFilePath, videoLink, toggleLinkType', { videoFilePath, videoLink, toggleLinkType });

  return (
    <>
      {loading ? (
        <div className="loading__spinners">
          <RiseLoader
            color="#CC435F"
            margin={5}
            cssOverride={{
              position: 'absolute',
              top: '50%',
              left: '45%',
              zIndex: 1,
            }}
            loading={loading}
            size={50}
            speedMultiplier={1}
          />
        </div>
      ) : null}
      <Modal
        isOpen={openModal}
        onRequestClose={modalOpenClose}
        style={customStyles}
        contentLabel="More Filters"
        shouldCloseOnOverlayClick={false}>
        <div className="explainer-video-form">
          <div className="explainer-video-form__header">
            <div className="explainer-video-form__header-text">
              {toggleEditComponent ? `Edit Explainer Video` : `Add Explainer Video`}
            </div>
            <img src={ModalCancel} alt="close button" style={{ cursor: 'pointer' }} onClick={modalOpenClose} />
          </div>
          <div>
            <hr className="explainer-video-form__seperator"></hr>
          </div>

          <form onSubmit={handleSubmit}>
            <BrowseFile
              file={videoFile}
              filePath={videoFilePath}
              handleFileUpload={handleFileUpload}
              handleFileRemove={handleFileRemove}
              toggleEditComponent={toggleEditComponent}
              editContentDetails={videoDetail}
              prop_value="video_url"
              width={'100%'}
              // accept={"video/*"}
              accept={'.webm, .mp4'}
            />
            {formErrors.videoFilePath && (
              <div className="add-content-form-error" style={{ marginTop: '5px', marginBottom: '8px' }}>
                {formErrors.videoFilePath}
              </div>
            )}
            {(videoFilePath.length > 0 || videoLink.length > 0) && (
              <div className="edit-explainer-video-preview-info">
                {`Currently using Video ${toggleLinkType}`}
                <div className="video-preview-btn" onClick={handleVideoPreviewModal} style={{ width: '100px', height: 'auto' }}>
                  Preview
                </div>
              </div>
            )}
            <UploadThumbnail
              width={'100%'}
              thumbnail={videoThumbnail}
              handleUploadThumbnail={handleUploadThumbnail}
              formErrors={formErrors.videoThumbnail}
              toggleEditComponent={toggleEditComponent}
              editContentDetails={videoDetail}
            />
            <div className="add-content-label" style={{ margin: '15px 0 7px 0' }}>
              {'Board'}
            </div>
            <AddExplainerVideoSelection
              name="board"
              default_option="Select"
              withinModal={true}
              values={boards}
              id_name="board_id"
              display_name="board_name"
              marginTop={'0px'}
              formErrors={formErrors}
              value={board_id}
              handleIdChange={handleIdChange}
            />

            <div className="add-content-label" style={{ margin: '15px 0 7px 0' }}>
              {'Course'}
            </div>
            <AddExplainerVideoSelection
              name="course"
              default_option="Select"
              withinModal={true}
              values={courses}
              id_name="course_id"
              display_name="course_name"
              marginTop={'0px'}
              handleIdChange={handleIdChange}
              formErrors={formErrors}
              value={course_id}
            />

            <div className="add-content-label" style={{ margin: '15px 0 7px 0' }}>
              {'Subject'}
            </div>
            <AddExplainerVideoSelection
              name="subject"
              default_option="Select"
              withinModal={true}
              values={subjects}
              id_name="subject_id"
              display_name="subject_name"
              marginTop={'0px'}
              handleIdChange={handleIdChange}
              formErrors={formErrors}
              value={subject_id}
            />

            <TextInput
              name="duration"
              label="Duration (min.)"
              formErrors={formErrors}
              value={duration}
              type="number"
              handleTextInputChange={handleIdChange}
            />

            <div className="add-content-label" style={{ margin: '15px 0 7px 0' }}>
              {'Audio Language'}
            </div>
            <AddExplainerVideoSelection
              name="audio_language"
              default_option="Select"
              withinModal={true}
              values={allLanguages}
              id_name="id"
              display_name="label"
              marginTop={'0px'}
              handleIdChange={handleIdChange}
              formErrors={formErrors}
              value={audio_language_id}
            />

            <div className="add-content-label" style={{ margin: '15px 0 7px 0' }}>
              {'Subtitle'}
            </div>
            <MultiSelect
              options={allLanguages}
              value={selectedSubtitleLang}
              onChange={setSelectedSubtitleLang}
              labelledBy="Select"
              hasSelectAll={false}
              className="multi-select-checkbox-drpdwn"
            />
            {formErrors.selectedSubtitleLang && (
              <div className="add-content-form-error" style={{ marginTop: '5px', marginBottom: '0' }}>
                {formErrors.selectedSubtitleLang}
              </div>
            )}

            <TextInput
              name="video_link"
              label="Video Link"
              formErrors={formErrors}
              value={videoLink}
              type="text"
              handleTextInputChange={handleIdChange}
              height={'72px'}
            />

            <TextInput
              name="title"
              label="Title"
              formErrors={formErrors}
              value={title}
              type="text"
              handleTextInputChange={handleIdChange}
            />

            <TextInput
              name="video_description"
              label="Description"
              formErrors={formErrors}
              value={description}
              type="text"
              handleTextInputChange={handleIdChange}
            />

            <FilterButtons handleModalApply={handleSubmit} modalOpenClose={modalOpenClose} apply={'Apply'} />
          </form>
        </div>
      </Modal>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />
      {isVideoPreviewOpen && (
        <VideoPlayer
          openModal={isVideoPreviewOpen}
          handleModalOpenClose={handleVideoPreviewModal}
          url={toggleLinkType === 'File' ? videoFilePath : toggleLinkType === 'Web Link' ? videoLink : null}
        />
      )}
    </>
  );
};

export default AddExplainerVideoModal;
