import React, { useState } from 'react';
import './Suggestions.css';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import RiseLoader from 'react-spinners/RiseLoader';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';
import SuggestionBox from '../SuggestionBox/SuggestionBox';
import NoDataFound from '../../Assets/NoDataFound.png';
import { deleteContentFeedback } from '../../api/content';

const Suggestions = (props) => {
  const { contentFeedback, setContentFeedback } = props;

  const [loading, setLoading] = useState(false);

  const eraseContentFeedback = async (deleteFeedbackId) => {
    let filterdFeedback = contentFeedback.filter((obj) => {
      return obj.id !== deleteFeedbackId;
    });
    try {
      setLoading(true);
      setContentFeedback(filterdFeedback);
      await deleteContentFeedback(deleteFeedbackId);
      setLoading(false);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const handleFeedbackDelete = async (deleteFeedbackId) => {
    confirmAlert({
      // title: 'Confirm to submit',
      message: 'Are you sure you want to remove this suggestion?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => eraseContentFeedback(deleteFeedbackId),
        },
        {
          label: 'No',
        },
      ],
    });
  };

  return (
    <>
      {loading ? (
        <div className="loading__spinners">
          <RiseLoader
            color="#CC435F"
            margin={5}
            cssOverride={{
              position: 'absolute',
              top: '50%',
              left: '45%',
              zIndex: 1,
            }}
            loading={loading}
            size={50}
            speedMultiplier={1}
          />
        </div>
      ) : null}
      <div className="suggestion-container">
        <div className="suggestion-container__header">
          <div className="suggestion-header-text">Suggestions</div>
        </div>
        <div className="suggestion-container__body">
          {contentFeedback && contentFeedback.length > 0 ? (
            contentFeedback.map((obj, index) => {
              let date = obj.feedback_date;
              let suggestionDate = new Date(date).toLocaleDateString('en-in', { day: 'numeric', month: 'long', year: 'numeric' });
              return (
                <SuggestionBox
                  suggestorName={obj.full_name}
                  suggestorMessage={obj.user_message}
                  suggestionDate={suggestionDate}
                  key={index}
                  feedbackId={obj.id}
                  handleFeedbackDelete={handleFeedbackDelete}
                />
              );
            })
          ) : (
            <div className="no-data-block" style={{ width: '99%' }}>
              <img
                src={NoDataFound}
                alt="pic showing no data found message"
                style={{ width: '150px', height: 'auto', marginTop: '-10px' }}
              />
              <div className="no-data-text">{'No suggestions found for this Content.'}</div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />
    </>
  );
};

export default Suggestions;
