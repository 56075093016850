import https from '../init/https';
import { toast } from 'react-toastify';

// CMDS-ACT-01: Upload Activity
export const uploadActivity = async (formData) => {
  try {
    let postData = await https.post(`/activities`, formData);
    // console.log("postData", postData)
    return postData.data.data;
  } catch (err) {
    console.log('err in upload activity', err);
    throw err;
  }
};

// CMDS-ACT-02: Retrieve Activity List in Tabular form
export const fetchActivityStackTable = async (
  limit,
  offset,
  board_id,
  course_id,
  subject_id,
  topic_id,
  subTopic_id,
  searchTerm,
  activityModalData,
) => {
  const user_id = localStorage.getItem('user_id');

  let tableData = {};
  try {
    let condition = [],
      cond = '';
    if (limit) {
      condition.push(`limit=${limit}`);
    }
    condition.push(`offset=${offset}`);
    if (user_id) {
      condition.push(`user_id=${user_id}`);
    }
    if (activityModalData && activityModalData.authorId && +activityModalData.authorId !== -1) {
      condition.push(`added_by_user_id=${activityModalData.authorId}`);
    }
    if (board_id && +board_id !== -1) {
      condition.push(`board_id=${board_id}`);
    }
    if (course_id && +course_id !== -1) {
      condition.push(`course_id=${course_id}`);
    }
    if (subject_id && +subject_id !== -1) {
      condition.push(`subject_id=${subject_id}`);
    }
    if (topic_id && +topic_id !== -1) {
      condition.push(`topic_1_id=${topic_id}`);
    }
    if (subTopic_id && +subTopic_id !== -1) {
      condition.push(`sub_topic_1_id=${subTopic_id}`);
    }

    if (activityModalData && activityModalData.is_min_recommended) {
      condition.push(`is_min_recommended=${activityModalData.is_min_recommended}`);
    }

    if (activityModalData && activityModalData.activity_mode_ids && activityModalData.activity_mode_ids !== -1) {
      condition.push(`activity_mode_ids=${activityModalData.activity_mode_ids}`);
    }

    if (activityModalData && activityModalData.activity_category_ids && activityModalData.activity_category_ids !== -1) {
      condition.push(`activity_category_ids=${activityModalData.activity_category_ids}`);
    }

    if (activityModalData && activityModalData.activity_type_ids && activityModalData.activity_type_ids !== -1) {
      condition.push(`activity_type_ids=${activityModalData.activity_type_ids}`);
    }

    if (searchTerm && searchTerm !== '') {
      condition.push(`search_text=${searchTerm}`);
    }

    if (activityModalData && activityModalData.link_type && activityModalData.link_type !== '') {
      condition.push(`link_type=${activityModalData.link_type}`);
    }

    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }
    // console.log("cond for fetch activity", cond)
    tableData = await https.get(`/activities/search${cond}`);
  } catch (err) {
    console.log(err);
    if (err) {
      throw err;
    }
  }
  return tableData.data.data;
};

// CMDS-ACT-02-01: Get  Count result for CMDS-ACT-02
export const fetchActivityStackCount = async (
  limit,
  offset,
  board_id,
  course_id,
  subject_id,
  topic_id,
  subTopic_id,
  searchTerm,
  activityModalData,
) => {
  const user_id = localStorage.getItem('user_id');

  try {
    let condition = [],
      cond = '';
    if (limit) {
      condition.push(`limit=${limit}`);
    }
    condition.push(`offset=${offset}`);
    if (user_id) {
      condition.push(`user_id=${user_id}`);
    }
    if (activityModalData && activityModalData.authorId && +activityModalData.authorId !== -1) {
      condition.push(`added_by_user_id=${activityModalData.authorId}`);
    }
    if (board_id && +board_id !== -1) {
      condition.push(`board_id=${board_id}`);
    }
    if (course_id && +course_id !== -1) {
      condition.push(`course_id=${course_id}`);
    }
    if (subject_id && +subject_id !== -1) {
      condition.push(`subject_id=${subject_id}`);
    }
    if (topic_id && +topic_id !== -1) {
      condition.push(`topic_1_id=${topic_id}`);
    }
    if (subTopic_id && +subTopic_id !== -1) {
      condition.push(`sub_topic_1_id=${subTopic_id}`);
    }

    if (activityModalData && activityModalData.is_min_recommended) {
      condition.push(`is_min_recommended=${activityModalData.is_min_recommended}`);
    }

    if (activityModalData && activityModalData.activity_mode_ids && activityModalData.activity_mode_ids !== -1) {
      condition.push(`activity_mode_ids=${activityModalData.activity_mode_ids}`);
    }

    if (activityModalData && activityModalData.activity_category_ids && activityModalData.activity_category_ids !== -1) {
      condition.push(`activity_category_ids=${activityModalData.activity_category_ids}`);
    }

    if (activityModalData && activityModalData.activity_type_ids && activityModalData.activity_type_ids !== -1) {
      condition.push(`activity_type_ids=${activityModalData.activity_type_ids}`);
    }

    if (searchTerm && searchTerm !== '') {
      condition.push(`search_text=${searchTerm}`);
    }

    if (activityModalData && activityModalData.link_type && activityModalData.link_type !== '') {
      condition.push(`link_type=${activityModalData.link_type}`);
    }

    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }

    let count = await https.get(`/activities/count_activities${cond}`);

    if (count && count.data && count.data.data) {
      return count.data.data.count;
    } else {
      let err = { message: 'Fetch Activity Count - No data found' };
      throw err;
    }
  } catch (err) {
    console.log(err);
    if (err) {
      throw err;
    }
  }
};

// CMDS-ACT-03: Update Activity
export const editActivity = async (id, formData) => {
  try {
    let editedActivity = await https.put(`/activities/${id}`, formData);
    console.log('editedActivity.data.data', editedActivity.data.data);
    return editedActivity.data.data;
  } catch (err) {
    throw err;
  }
};

// CMDS-ACT-04: Delete activities
export const deleteActivity = async (activity_id) => {
  try {
    const deleteActivity = await https.delete(`/activities/${activity_id}`);
    if (deleteActivity.status === 200) {
      return activity_id;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-ACT-05: Display the  Activity details page
export const fetchActivityDetails = async (id) => {
  try {
    const getActivityDetails = await https.get(`/activities/?activity_id=${id}`);
    // console.log("getActivityDetails", getActivityDetails)
    if (getActivityDetails?.data?.data && Object.keys(getActivityDetails.data.data)?.length > 0) {
      return getActivityDetails.data.data;
    } else {
      let error = { message: 'Fetching actvivity details failed' };
      throw error;
    }
  } catch (err) {
    console.log('err', err);
    toast.error(err && err.message && err.message);
    throw err;
  }
};

// CMDS-ACT-06: Retrieve the History of Activity
export const fetchActivityHistory = async (id) => {
  try {
    const activityHistory = await https.get(`/activities/activity_history?activity_id=${id}`);
    return activityHistory.data.data;
  } catch (err) {
    console.log('err', err);
    throw err;
  }
};

// CMDS-ACT-07: Retrieve the User feedbacks of Activity
export const fetchActivityFeedback = async (id) => {
  try {
    const activityFeedback = await https.get(`/activities/activity_user_feedbacks?activity_id=${id}`);
    console.log('activity feedback', activityFeedback);
    return activityFeedback.data.data;
  } catch (err) {
    console.log('err', err);
    throw err;
  }
};

// CMDS-ACT-08: Delete the user feedback after correct the activity
export const deleteActivityFeedback = async (id, is_deleted = true) => {
  let userId = localStorage.getItem('user_id');

  try {
    const deletedActivityFeedback = await https.delete(`/activities/${id}/${is_deleted}/${userId}`);
    console.log('deletedAcrivityFeedback', deletedActivityFeedback);
    return id;
  } catch (err) {
    console.log('err', err);
    throw err;
  }
};

// CMDS-ACT-10: Retrieve the list of Explainer_video details for Activity
export const fetchExplainerVideoDetail = async (search_text) => {
  try {
    let getCaption = await https.get(`/activities/explainer_videos?search_text=${search_text}`);
    return getCaption.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-ACT-11: Get activity summary
export const fetchActivitySummary = async (
  board_id,
  course_id,
  subject_id,
  topic_id,
  subTopic_id,
  searchTerm,
  activityModalData,
) => {
  const user_id = localStorage.getItem('user_id');
  try {
    let condition = [],
      cond = '';
    if (user_id) {
      condition.push(`user_id=${user_id}`);
    }
    if (board_id && +board_id !== -1) {
      condition.push(`board_id=${board_id}`);
    }
    if (course_id && +course_id !== -1) {
      condition.push(`course_id=${course_id}`);
    }
    if (subject_id && +subject_id !== -1) {
      condition.push(`subject_id=${subject_id}`);
    }
    if (topic_id && +topic_id !== -1) {
      condition.push(`topic_1_id=${topic_id}`);
    }
    if (subTopic_id && +subTopic_id !== -1) {
      condition.push(`sub_topic_1_id=${subTopic_id}`);
    }
    if (activityModalData && activityModalData.is_min_recommended) {
      condition.push(`is_min_recommended=${activityModalData.is_min_recommended}`);
    }
    if (activityModalData && activityModalData.activity_mode_ids && activityModalData.activity_mode_ids !== -1) {
      condition.push(`activity_mode_ids=${activityModalData.activity_mode_ids}`);
    }
    if (activityModalData && activityModalData.activity_category_ids && activityModalData.activity_category_ids !== -1) {
      condition.push(`activity_category_ids=${activityModalData.activity_category_ids}`);
    }
    if (activityModalData && activityModalData.activity_type_ids && activityModalData.activity_type_ids !== -1) {
      condition.push(`activity_type_ids=${activityModalData.activity_type_ids}`);
    }
    if (activityModalData && activityModalData.authorId && +activityModalData.authorId !== -1) {
      condition.push(`added_by_user_id=${activityModalData.authorId}`);
    }
    if (activityModalData && activityModalData.link_type && activityModalData.link_type !== '') {
      condition.push(`link_type=${activityModalData.link_type}`);
    }
    if (searchTerm && searchTerm !== '') {
      condition.push(`search_text=${searchTerm}`);
    }
    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }
    console.log('condition for activity piechart summary', cond);
    let data = await https.get(`/activities/activity_summary${cond}`);
    if (data?.data?.data) {
      return data.data.data;
    } else {
      let err = { message: 'Fetch Activity Count - No data found' };
      throw err;
    }
  } catch (err) {
    console.log(err);
    if (err) {
      throw err;
    }
  }
};
