/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import './MainBar.css';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RiseLoader from 'react-spinners/RiseLoader';
import Filter from '../../../../components/Filter/Filter';
import Stats from '../../../../components/Stats/Stats';
import Table from '../../../../components/Table/Table';
// import Pagination from '../../../../components/Pagination/Pagination';
import {
  fetchBoards,
  fetchCourse,
  fetchSubjects,
  fetchSubSubjects,
  fetchTopics,
  fetchSubTopics /* fetchMediaList */,
} from '../../../../api/master_data';
import {
  filteredTable,
  fetchCount,
  fetchContentObjectivesCount,
  fetchMultimediaChartData,
  fetchReadableChartData,
} from '../../../../api/content';
import { fetchAuthorList, fetchObjective, fetchFileTypeList } from '../../../../api/master_data';

const MainBar = (props) => {
  const [boards, setBoards] = useState([]);
  const [board_id, setBoardId] = useState(-1);
  const [courses, setCourses] = useState([]);
  const [course_id, setCourseId] = useState(-1);
  const [subjects, setSubjects] = useState([]);
  const [subject_id, setSubjectId] = useState(-1);
  const [subSubjects, setSubSubjects] = useState([]);
  const [subSubject_id, setSubSubjectId] = useState(-1);
  const [topics, setTopics] = useState([]);
  const [topic_id, setTopicId] = useState(-1);
  const [subTopics, setSubTopics] = useState([]);
  const [subTopicId, setSubTopicId] = useState(-1);
  const [searchTerm, setSearchTerm] = useState('');
  // const [media, setMedia] = useState("")
  const [modalData, setModalData] = useState({
    authorId: -1,
    searchTerm: '',
    objectiveId: '',
    fileTypeId: '',
    link_type: 'ALL',
  });
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [multimediaChartData, setMultimediaChartData] = useState([]);
  const [readableChartData, setReadableChartData] = useState([]);
  const [contentObjectives, setContentObjectives] = useState([]);
  const initialRender = useRef();
  const userId = localStorage.getItem('user_id');
  const LIMIT = 10;

  // more filters
  const [authors, setAuthors] = useState([]);
  const [objective, setObjective] = useState([]);
  const [fileType, setFileType] = useState([]);

  useEffect(() => {
    const getAllOptions = async () => {
      // setLoading(true)
      setAuthors(await fetchAuthorList());
      setObjective(await fetchObjective());
      setFileType(await fetchFileTypeList());
      // setLoading(false)
    };
    try {
      getAllOptions();
    } catch (err) {
      console.log('err in Mainbar - getAllOptions', err);
      toast.error('Error fetching Authors, Objective type and File type');
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (board_id === -1) {
      setCourseId(-1);
      setSubjectId(-1);
      setTopicId(-1);
      setSubTopicId(-1);
    }
    if (course_id === -1) {
      setSubjectId(-1);
      setTopicId(-1);
      setSubTopicId(-1);
    }
    if (subject_id === -1) {
      setTopicId(-1);
      setSubTopicId(-1);
    }
    if (topic_id === -1) setSubTopicId(-1);
  }, [board_id, course_id, subject_id, topic_id, subTopicId]);

  // console.log({ boards, courses, subjects, topics, subTopics })
  useEffect(() => {
    const getTableData = async () => {
      setLoading(true);
      const countValue = await fetchCount(
        board_id,
        course_id,
        subject_id,
        subSubject_id,
        topic_id,
        subTopicId,
        searchTerm,
        modalData,
        LIMIT,
        offset,
      );
      setCount(countValue);
      const initialTableData = await filteredTable(
        board_id,
        course_id,
        subject_id,
        subSubject_id,
        topic_id,
        subTopicId,
        searchTerm,
        modalData,
        LIMIT,
        offset,
      );
      // console.log("initialTableData", { initialTableData, tableData })
      if (initialRender.current /* && initialTableData.length > 0 */) {
        setTableData([...tableData, ...initialTableData]);
      } else {
        if (initialTableData.length > 0) setTableData([...tableData, ...initialTableData]);
        initialRender.current = true;
      }
      localStorage.removeItem('clickedContentId');
      setLoading(false);
    };
    try {
      getTableData();
    } catch (err) {
      console.log('err getTableData- MainBar', err);
      toast.error('Something went wrong try again');
      if (err?.message) {
        toast.error(err.message);
      }
      if (err?.response?.data?.type === 'TOKEN_ERR') {
        toast.info('Session expired, Please login once again');
        setTimeout(() => {
          return (window.location.href = '/login');
        }, 3000);
      }
      setLoading(false);
    }
  }, [board_id, course_id, subject_id, subSubject_id, topic_id, subTopicId, searchTerm, modalData, LIMIT, offset]);

  useEffect(() => {
    const fetchAll = async () => {
      setLoading(true);
      setBoards(await fetchBoards());
      setCourses(await fetchCourse(board_id));
      setSubjects(await fetchSubjects(board_id, course_id));
      setSubSubjects(await fetchSubSubjects(board_id, course_id, subject_id));
      setTopics(await fetchTopics(board_id, course_id, subject_id, subSubject_id));
      setSubTopics(await fetchSubTopics(board_id, course_id, subject_id, subSubject_id, topic_id));
      setContentObjectives(
        await fetchContentObjectivesCount(board_id, course_id, subject_id, subSubject_id, searchTerm, modalData),
      );
      setMultimediaChartData(await fetchMultimediaChartData(board_id, course_id, subject_id, subSubject_id, searchTerm, modalData));
      setReadableChartData(await fetchReadableChartData(board_id, course_id, subject_id, subSubject_id, searchTerm, modalData));
      setLoading(false);
    };
    try {
      fetchAll();
    } catch (err) {
      console.log('err in MainBar', err);
      toast.error('Something went wrong try again');
      if (err?.message) {
        toast.error(err.message);
      }
      if (err && err.response.status === 400 && err.response.data && err.response.data.type === 'TOKEN_ERR') {
        toast.info('Session expired, Please login once again');
        return (window.location.href = '/login');
      }
      setLoading(false);
    }
  }, [board_id, course_id, subject_id, subSubject_id, topic_id, subTopicId, modalData, LIMIT, offset, searchTerm, userId]);

  const setTheModalData = (v) => {
    setModalData(v);
  };

  const handleModalOpenClose = () => {
    setOpenModal(!openModal);
  };

  const handleIdChange = async (e) => {
    let value = e.target.name;
    if (value === 'Board') {
      setBoardId(e.target.value);
      setTableData([]);
      setCourseId(-1);
      setSubjectId(-1);
      setTopicId(-1);
      setSubTopicId(-1);
    } else if (value === 'Course') {
      console.log('Test Dropdown: Course, ', e.target.value);
      setCourseId(e.target.value);
      setTableData([]);
      setSubjectId(-1);
      setTopicId(-1);
      setSubTopicId(-1);
    } else if (value === 'Subject') {
      console.log('Test Dropdown: Subject, ', e.target.value);
      setSubjectId(e.target.value);
      setTableData([]);
      setTopicId(-1);
      setSubTopicId(-1);
    } else if (value === 'Sub-subject') {
      console.log('Test Dropdown: Sub-Subject, ', e.target.value);
      setSubSubjectId(e.target.value);
      setTableData([]);
      setTopicId(-1);
    } else if (value === 'Topic') {
      console.log('Test Dropdown: Topic, ', e.target.value);
      setTopicId(e.target.value);
      setTableData([]);
      setSubTopicId(-1);
    } else if (value === 'Sub-topic') {
      setSubTopicId(e.target.value);
      setTableData([]);
    } else if (value === 'Search-bar') {
      setSearchTerm(e.target.value);
      setTableData([]);
    }
    setOffset(0);
    initialRender.current = false;
  };

  return (
    <>
      {loading ? (
        <div className="loading__spinners">
          <RiseLoader
            color="#CC435F"
            margin={5}
            cssOverride={{
              position: 'absolute',
              top: '50%',
              left: '45%',
              zIndex: 1,
            }}
            loading={loading}
            size={50}
            speedMultiplier={1}
          />
        </div>
      ) : null}
      <div className="main-bar">
        <div className="main-bar__header">
          <div className="page-name">Content Library</div>
        </div>
        <div className="main-bar__filter-component">
          <Filter
            boards={boards}
            courses={courses}
            subjects={subjects}
            subSubjects={subSubjects}
            topics={topics}
            subTopics={subTopics}
            handleIdChange={handleIdChange}
            searchTerm={searchTerm}
            openModal={openModal}
            handleModalOpenClose={handleModalOpenClose}
            modalData={modalData}
            setModalData={setTheModalData}
            modalName={'ContentFilterModal'}
            authors={authors}
            objective={objective}
            fileType={fileType}
            setAuthors={setAuthors}
            setFileType={setFileType}
            setObjective={setObjective}
            setOffset={setOffset}
            initialRender={initialRender}
            board_id={board_id}
            course_id={course_id}
            subject_id={subject_id}
            topic_id={topic_id}
            subSubject_id={subSubject_id}
            subTopicId={subTopicId}
          />
        </div>
        <div className="main-bar__stats-component">
          <Stats
            tableData={tableData}
            multimediaChartData={multimediaChartData}
            readableChartData={readableChartData}
            contentObjectives={contentObjectives}
          />
        </div>
        <div className="main-bar__table-component">
          <Table
            tableData={tableData}
            setTableData={setTableData}
            count={count}
            offset={offset}
            LIMIT={LIMIT}
            board_id={board_id}
            course_id={course_id}
            subject_id={subject_id}
            subSubject_id={subSubject_id}
            searchTerm={searchTerm}
            modalData={modalData}
            sortData={tableData}
            setCount={setCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setOffset={setOffset}
            topic_id={topic_id}
            subTopicId={subTopicId}
          />
        </div>
        {/* <div className="main-bar__pagination-component">
                    <Pagination
                        paginate={paginate}
                        count={count}
                        LIMIT={LIMIT}
                        currentPage={currentPage}
                    />
                </div> */}
      </div>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />
    </>
  );
};

export default MainBar;
