import React from 'react';
import './SuggestionBox.css';
import DeleteIcon from '../../Assets/ContentLibrary/ContentDetails/delete-icon.png';

const SuggestionBox = (props) => {
  const { suggestorName, suggestorMessage, suggestionDate, feedbackId, handleFeedbackDelete } = props;

  return (
    <div className="suggestion-box">
      <div className="suggestion-box__suggestor-detail">
        <div className="suggestor-name">{suggestorName}</div>
        <div className="suggestor-message">{suggestorMessage}</div>
        <div className="suggestor-date">{suggestionDate}</div>
      </div>
      <div className="suggestion-box__delete-btn">
        <img
          src={DeleteIcon}
          alt="delete suggestion"
          style={{ cursor: 'pointer' }}
          onClick={() => handleFeedbackDelete(feedbackId)}
        />
      </div>
    </div>
  );
};

export default SuggestionBox;
