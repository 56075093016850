import React from 'react';
import './ModulesItem.css';
import { NavLink } from 'react-router-dom';

const ModulesItem = (props) => {
  const { name, img, alt, link, isTrue } = props;

  return (
    <NavLink className={isTrue ? 'modules-item--clicked' : 'modules-item'} to={link}>
      <img src={img} alt={alt} className="modules-item__details-img" />
      <div className="modules-item__details-div">{name}</div>
    </NavLink>
  );
};

export default ModulesItem;
