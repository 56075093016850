import React, { useState, useEffect } from 'react';
import './ActivityDetails.css';
import RiseLoader from 'react-spinners/RiseLoader';
import SideBar from '../../../components/SideBar/SideBar';
import DisplayActivity from './DisplayActivity/DisplayActivity';
import AddActivity from '../UploadActivity/AddActivity/AddActivity';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchActivityDetails, fetchActivityHistory } from '../../../api/activity_stack';

const ActivityDetails = (props) => {
  const [toggleEditComponent, setToggleEditComponent] = useState(false);
  const [activityDetails, setActivityDetails] = useState({});
  const [activityFeedback, setActivityFeedback] = useState([]);
  const [activityHistory, setActivityHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const url = window.location.href;
  console.log('activityDetails', activityDetails);
  useEffect(() => {
    const getDetails = async () => {
      setLoading(true);
      let id = url.substring(url.lastIndexOf('/') + 1);
      setActivityDetails(await fetchActivityDetails(id));
      // setActivityFeedback(await fetchActivityFeedback(id))
      setActivityHistory(await fetchActivityHistory(id));
      setLoading(false);
    };
    const getActivityDetails = async () => {
      try {
        await getDetails();
      } catch (err) {
        console.log(err);
        if (err && err.message) {
          toast.error(err.message);
          // setTimeout(() => {
          //   window.location.href = '/activity-stack';
          // }, 3000);
        }
      }
    };
    getActivityDetails();
  }, [url]);

  const handleToggle = () => {
    setToggleEditComponent(!toggleEditComponent);
  };

  return (
    <>
      {loading ? (
        <div className="loading__spinners">
          <RiseLoader
            color="#CC435F"
            margin={5}
            cssOverride={{
              position: 'absolute',
              top: '50%',
              left: '45%',
              zIndex: 1,
            }}
            loading={loading}
            size={50}
            speedMultiplier={1}
          />
        </div>
      ) : null}
      <div className="activity-main-page">
        <div className="activity-main-page__bar-container">
          <SideBar />
          {toggleEditComponent ? (
            <AddActivity
              toggleEditComponent={toggleEditComponent}
              editContentDetails={activityDetails}
              handleToggle={handleToggle}
            />
          ) : (
            <DisplayActivity
              contentDetails={activityDetails}
              handleToggle={handleToggle}
              activityFeedback={activityFeedback}
              setActivityFeedback={setActivityFeedback}
              activityHistory={activityHistory}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ActivityDetails;
