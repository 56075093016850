import React from 'react';
import './PlayKitButton.css';
import cross from '../../Assets/ActivityStack/cross.png';

const PlayKitButton = (props) => {
  const { playKitArray, handleRemovePlayKit, marginTop } = props;

  return (
    <div className="playKit-btn-holder" style={{ marginTop: marginTop ?? marginTop }}>
      {playKitArray.map((obj, index) => {
        return (
          <div className="playKit-btn-holder__btn" key={index}>
            <div className="playKit-btn-holder__btn-description">{obj.name}</div>
            <img
              src={cross}
              alt="remove playkit"
              style={{ width: '10px', height: '10px', marginRight: '4px' }}
              onClick={() => handleRemovePlayKit(obj.id)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default PlayKitButton;
