import React from 'react';
import './SearchBar.css';

const SearchBar = (props) => {
  const { searchTerm, handleIdChange, name } = props;

  return (
    <input
      name={name}
      type="text"
      className={name === 'playKit' ? 'playKit-searchBar' : 'search-bar'}
      value={searchTerm}
      onChange={handleIdChange}
    />
  );
};

export default SearchBar;
