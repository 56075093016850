/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './ExplainerVideosMainBar.css';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RiseLoader from 'react-spinners/RiseLoader';
import Filter from '../../../../components/Filter/Filter';
// import Pagination from '../../../../components/Pagination/Pagination';
import ExplainerVideosTable from '../../../../components/ExplainerVideosTable/ExplainerVideosTable';
import { fetchBoards, fetchCourse, fetchSubjects, fetchLanguageList } from '../../../../api/master_data';
import { filteredExplainerVideoTable, fetchExplainerVideosCount } from '../../../../api/explainer_video_table';

const ExplainerVideosMainBar = (props) => {
  const [boards, setBoards] = useState([]);
  const [board_id, setBoardId] = useState(-1);
  const [courses, setCourses] = useState([]);
  const [course_id, setCourseId] = useState(-1);
  const [subjects, setSubjects] = useState([]);
  const [subject_id, setSubjectId] = useState(-1);
  const [audioLanguage, setAudioLanguage] = useState([]);
  const [audio_language_id, setAudioLanguageId] = useState(-1);
  const [subtitle, setSubtitle] = useState([]);
  const [subtitle_language_id, setSubTitleLanguageId] = useState(-1);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [explainerVideoTableData, setExplainerVideoTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openVideoFilterModal, setopenVideoFilterModal] = useState(false);
  const [explainerVideosCount, setExplainerVideosCount] = useState(0);
  const [toggleEditComponent, setToggleEditComponent] = useState(false);

  const userId = localStorage.getItem('user_id');

  // Pagination
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  let limit = 20;
  // const paginate = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  //   setOffset(limit * (pageNumber - 1));
  // };

  const handleModalOpenClose = (video_id) => {
    setOpenModal(!openModal);
    video_id ? setToggleEditComponent(true) : setToggleEditComponent(false);
  };

  const handleVideoFilterModalOpenClose = () => {
    setopenVideoFilterModal(!openVideoFilterModal);
  };

  useEffect(() => {
    const fetchAll = async () => {
      setLoading(true);
      setBoards(await fetchBoards());
      setCourses(await fetchCourse(board_id));
      setSubjects(await fetchSubjects(board_id, course_id));
      userId &&
        setExplainerVideoTableData(
          await filteredExplainerVideoTable(
            limit,
            offset,
            board_id,
            course_id,
            subject_id,
            audio_language_id,
            subtitle_language_id,
            searchTerm,
          ),
        );
      setExplainerVideosCount(
        await fetchExplainerVideosCount(
          limit,
          offset,
          board_id,
          course_id,
          subject_id,
          audio_language_id,
          subtitle_language_id,
          searchTerm,
        ),
      );
      setAudioLanguage(await fetchLanguageList());
      setSubtitle(await fetchLanguageList());
      setLoading(false);
    };
    try {
      fetchAll();
    } catch (err) {
      console.log('err in fetchAll - explainer video', err);
      toast.error('Something went wrong try again');
      if (err?.message) {
        toast.error(err.message);
      }
      // eslint-disable-next-line
      if (err && err.response.status == 400 && err.response.data && err.response.data.type === 'TOKEN_ERR') {
        toast.info('Session expired, Please login once again');
        setTimeout(() => {
          return (window.location.href = '/login');
        }, 3000);
      }
      setLoading(false);
    }
  }, [board_id, course_id, subject_id, subtitle_language_id, offset, searchTerm, userId]);

  const handleIdChange = async (e) => {
    let value = e.target.name;
    if (value === 'Board') {
      setBoardId(e.target.value);
    } else if (value === 'Course') {
      setCourseId(e.target.value);
    } else if (value === 'Subject') {
      setSubjectId(e.target.value);
    } else if (value === 'Audio Language') {
      setAudioLanguageId(e.target.value);
    } else if (value === 'Subtitle') {
      setSubTitleLanguageId(e.target.value);
    } else if (value === 'Search-bar') {
      setSearchTerm(e.target.value);
    }
  };

  return (
    <>
      {loading ? (
        <div className="loading__spinners">
          <RiseLoader
            color="#CC435F"
            margin={5}
            cssOverride={{
              position: 'absolute',
              top: '50%',
              left: '45%',
              zIndex: 1,
            }}
            loading={loading}
            size={50}
            speedMultiplier={1}
          />
        </div>
      ) : null}
      <div className="explainer-videos-main-bar">
        <div className="explainer-videos-main-bar__header">
          <div className="page-name">Explainer Videos</div>
        </div>
        <div className="explainer-videos-main-bar__filter-component">
          <Filter
            boards={boards}
            courses={courses}
            subjects={subjects}
            audioLanguage={audioLanguage}
            subtitle={subtitle}
            handleIdChange={handleIdChange}
            searchTerm={searchTerm}
            openModal={openVideoFilterModal}
            handleModalOpenClose={handleVideoFilterModalOpenClose}
            // modalData={modalData}
            // setModalData={setTheModalData}
            filterName="ExplainerVideosFilter"
            modalName={'ExplainerVideosModal'}
            audio_language_id={audio_language_id}
            subtitle_language_id={subtitle_language_id}
          />
        </div>
        <div className="explainer-videos-main-bar__table-component">
          <ExplainerVideosTable
            explainerVideoTableData={explainerVideoTableData}
            setExplainerVideoTableData={setExplainerVideoTableData}
            openModal={openModal}
            handleModalOpenClose={handleModalOpenClose}
            explainerVideosCount={explainerVideosCount}
            limit={limit}
            offset={offset}
            board_id={board_id}
            course_id={course_id}
            subject_id={subject_id}
            audio_language_id={audio_language_id}
            subtitle_language_id={subtitle_language_id}
            searchTerm={searchTerm}
            setExplainerVideosCount={setExplainerVideosCount}
            toggleEditComponent={toggleEditComponent}
            setToggleEditComponent={setToggleEditComponent}
            setOffset={setOffset}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>
        {/* <div className="explainer-videos-main-bar__pagination-component">
                    <Pagination
                        paginate={paginate}
                        count={explainerVideosCount}
                        limit={limit}
                        currentPage={currentPage}
                    />
                </div> */}
      </div>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />
    </>
  );
};

export default ExplainerVideosMainBar;
