import React from 'react';
import './TextInput.css';

const TextInput = (props) => {
  const { label, height, placeholder, type, formErrors, name, value, handleTextInputChange, readOnly, margin, isRequired } = props;
  // console.log('name, isRequired', name, isRequired);
  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <div>
      {type === 'number' ? (
        <div className="form-right-text" style={{ marginTop: '15px' }}>
          <div className="form-right-text__label">
            {label}
            {isRequired && <span style={{ marginLeft: '3px', color: 'red' }}>*</span>}
          </div>
          <div className="form-right-text-input">
            <input
              className="form-right-text__input-field"
              name={name}
              type={type}
              placeholder={placeholder ?? placeholder}
              min="0"
              value={value}
              onChange={handleTextInputChange}
              onWheel={numberInputOnWheelPreventChange}
            />
          </div>
        </div>
      ) : (
        <div className="form-right-text" style={{ margin: margin ? margin : '32px 0 25px 0' }}>
          <div className="form-right-text__label">
            {label}
            {isRequired && <span style={{ marginLeft: '3px', color: 'red' }}>*</span>}
          </div>
          <div className="form-right-text-input" style={{ height: height && 'height' }}>
            {height ? (
              <textarea
                name={name}
                style={{
                  height: height ? height : 'auto',
                  resize: 'none',
                  lineBreak: 'anywhere',
                  cursor: readOnly && 'not-allowed',
                }}
                className="form-right-text__input-field"
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={handleTextInputChange}
                readOnly={readOnly ? true : false}
              ></textarea>
            ) : (
              <input
                className="form-right-text__input-field"
                name={name}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={handleTextInputChange}
                readOnly={readOnly ? true : false}
                style={{ cursor: readOnly && 'not-allowed' }}
              />
            )}
          </div>
        </div>
      )}
      {formErrors && formErrors[name] && <div className="add-content-form-error ">{formErrors[name]}</div>}
    </div>
  );
};

export default TextInput;
