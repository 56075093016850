/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import './ActivityStackTable.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import View from '../../Assets/Table/TableHeader/view.png';
import Circle from '../../Assets/Table/TableHeader/circle.png';
import Namaste from '../../Assets/Table/TableHeader/namaste.png';
import Star from '../../Assets/Table/TableHeader/star.png';
import sortArrowDown from '../../Assets/Table/TableHeader/sort-arrow-down.png';
import sortArrowUp from '../../Assets/Table/TableHeader/sort-arrow-up.png';
import Institution from '../../Assets/Table/TableBody/institution.png';
import Private from '../../Assets/Table/TableBody/private.png';
import Global from '../../Assets/Table/TableBody/global.png';
import MinRecTrue from '../../Assets/ActivityStack/min-rec-true.png';
import MinRecFalse from '../../Assets/ActivityStack/min-rec-false.png';
import Advanced from '../../Assets/ActivityStack/advanced.png';
import Digital from '../../Assets/ActivityStack/digital.png';
import Easy from '../../Assets/ActivityStack/easy.png';
import Individual from '../../Assets/ActivityStack/individual.png';
import Learning from '../../Assets/ActivityStack/learning.png';
import Moderate from '../../Assets/ActivityStack/moderate.png';
import Phygital from '../../Assets/ActivityStack/phygital.png';
import Physical from '../../Assets/ActivityStack/physical.png';
import Practice from '../../Assets/ActivityStack/practice.png';
import Team from '../../Assets/ActivityStack/team.png';
import WorkSheet from '../../Assets/ActivityStack/work-sheet.png';
import HulaHoop from '../../Assets/ActivityStack/hula-hoop.png';
import NoDataFound from '../../Assets/NoDataFound.png';
import TableControlButtons from '../TableControlButtons/TableControlButtons';
import { deleteActivity, fetchActivityStackCount, fetchActivityStackTable } from '../../api/activity_stack';

const ActivityStackTable = (props) => {
  const {
    activityCount,
    activityTableData,
    setActivityTableData,
    setActivityCount,
    limit,
    offset,
    board_id,
    course_id,
    subject_id,
    topic_id,
    subTopic_id,
    searchTerm,
    activityModalData,
    currentPage,
    setCurrentPage,
    setOffset,
    LIMIT,
  } = props;

  // console.log("activityTableData", activityTableData)
  const [checkAll, setCheckAll] = useState(false);
  const [render, setRender] = useState(false);
  const initialRender = useRef(false);
  const listRef = useRef();
  const columns = [
    { column_key: 'display_name', columnName: 'Display Name', hasSort: true, columnType: 'text' },
    { column_key: 'activity_details', columnName: 'Activity Details', hasSort: false, columnType: 'text' },
    { column_key: 'min_rec', columnName: 'Min Rec.', hasSort: false, columnType: 'text' },
    { column_key: 'added_date', columnName: 'Date', hasSort: true, columnType: 'text' },
    { column_key: 'duration_in_min', columnName: 'Duration (min.)', hasSort: true, columnType: 'text' },
    { column_key: 'is_curated', columnName: 'Curated', hasSort: false, columnType: 'text' },
    { column_key: 'sharing', columnName: 'Sharing', hasSort: false, columnType: 'text' },
    {
      column_key: 'View',
      columnName: View,
      hasSort: false,
      columnType: 'image',
      imagePath: '../../Assets/Table/TableHeader/Eye.png',
    },
    {
      column_key: 'Star',
      columnName: Star,
      hasSort: false,
      columnType: 'image',
      imagePath: '../../Assets/Table/TableHeader/Star.png',
    },
    {
      column_key: 'Namaste',
      columnName: Namaste,
      hasSort: false,
      columnType: 'image',
      imagePath: '../../Assets/Table/TableHeader/Namaste.png',
    },
    {
      column_key: 'Study_Circle',
      columnName: Circle,
      hasSort: false,
      columnType: 'image',
      imagePath: '../../Assets/Table/TableHeader/Circle.png',
    },
  ];

  useEffect(() => {
    setOffset(LIMIT * (currentPage - 1));
  }, [currentPage]);

  useEffect(() => {
    if (initialRender.current && listRef.current && activityTableData.length > 10) {
      const { clientHeight, scrollHeight } = listRef.current;
      const percentageFactor = (activityTableData.length - 10) / activityTableData.length;
      listRef.current.scrollTo({ top: scrollHeight * percentageFactor - clientHeight * 0.85, behaviour: 'smooth' });
    } else {
      initialRender.current = true;
    }
  }, [currentPage, JSON.stringify(activityTableData.length)]);

  useEffect(() => {
    const initiateReRender = async () => {
      setActivityTableData(
        await fetchActivityStackTable(
          limit,
          offset,
          board_id,
          course_id,
          subject_id,
          topic_id,
          subTopic_id,
          searchTerm,
          activityModalData,
        ),
      );
      setActivityCount(
        await fetchActivityStackCount(
          limit,
          offset,
          board_id,
          course_id,
          subject_id,
          topic_id,
          subTopic_id,
          searchTerm,
          activityModalData,
        ),
      );
    };
    if (render) {
      try {
        initiateReRender();
      } catch (err) {
        console.log(err);
        if (err && err.message) toast.error(err.message);
        if (err && err.response.status === 400 && err.response.data && err.response.data.type === 'TOKEN_ERR') {
          toast.info('Session expired, Please login once again');
          setTimeout(() => {
            return (window.location.href = '/login');
          }, 3000);
        }
      }
    }
    // eslint-disable-next-line
  }, [activityCount, render]);

  const eraseActivity = async () => {
    activityTableData.forEach(async (obj) => {
      if (obj.checked) {
        await deleteActivity(obj.activity_id);
        setRender(!render);
      }
    });
    try {
      setActivityTableData(
        await fetchActivityStackTable(
          limit,
          offset,
          board_id,
          course_id,
          subject_id,
          topic_id,
          subTopic_id,
          searchTerm,
          activityModalData,
        ),
      );
      setActivityCount(
        await fetchActivityStackCount(
          limit,
          offset,
          board_id,
          course_id,
          subject_id,
          topic_id,
          subTopic_id,
          searchTerm,
          activityModalData,
        ),
      );
      setCheckAll(false);
      toast.success('Activity removed successfully!');
    } catch (err) {
      console.log(err);
      if (err && err.message) toast.error(err.message);
      if (err && err.response.status === 400 && err.response.data && err.response.data.type === 'TOKEN_ERR') {
        toast.info('Session expired, Please login once again');
        setTimeout(() => {
          return (window.location.href = '/login');
        }, 3000);
      }
    }
  };

  const handleCheckAll = async (e) => {
    let checked = e.target.checked;
    setCheckAll(checked);
    let trueArray = activityTableData.map((obj) => {
      return { ...obj, checked: checked };
    });
    await setActivityTableData(trueArray);
  };

  const handleCheckBoxClick = (e) => {
    const checked = e.target.checked;
    const selectedActivityId = e.target.value;
    let trueArray = activityTableData.map((obj) => {
      // eslint-disable-next-line
      if (selectedActivityId == obj.activity_id) {
        return { ...obj, checked: checked };
      } else {
        return obj;
      }
    });
    setActivityTableData(trueArray);
  };

  const handleRemoveActivity = () => {
    // let openConfirmFlag = false
    // console.log("openConfirmFlag", openConfirmFlag)
    // for (let index = 0; index < activityTableData.length; index++) {
    //     const element = activityTableData[index];

    //     if (/* element.hasOwnProperty("checked") &&  */element.checked) {
    //         openConfirmFlag = true
    //         console.log("openConfirmFlag", openConfirmFlag)
    //         return
    //     } else {
    //         return toast.info("Please select activity to delete")
    //     }
    // }
    // if (openConfirmFlag) {
    //     confirmAlert({
    //         message: "Are you sure you want to remove this activity",
    //         buttons: [
    //             { label: "Yes", onClick: () => eraseActivity() },
    //             { label: "No" }
    //         ]
    //     })
    // }

    confirmAlert({
      message: 'Are you sure you want to remove this activity',
      buttons: [{ label: 'Yes', onClick: () => eraseActivity() }, { label: 'No' }],
    });
  };

  const handleRowClick = (id) => {
    return (window.location.href = `/activity-details/${id}`);
  };

  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      const isNearBottom = scrollHeight - scrollTop - 5 < clientHeight;
      if (isNearBottom && offset <= activityCount - LIMIT) {
        setCurrentPage(currentPage + 1);
        listRef.current.scrollTo({ top: 0, behaviour: 'smooth' });
      }
    }
  };

  return (
    <>
      <div className="activity-stack__table">
        <TableControlButtons
          count={activityCount}
          addName="+ Add Activity"
          removeName="Remove Activity"
          link={'/upload-activity'}
          handleRemove={handleRemoveActivity}
        />
        <div className="table-responsive" onScroll={handleScroll} ref={listRef}>
          <table className="activity-table-display" style={{ width: '100%', height: '100%' }}>
            <thead>
              <tr style={{ display: !activityTableData?.length && 'inline-table' }}>
                <td>
                  <input type="checkbox" value={checkAll} checked={checkAll} onChange={handleCheckAll} />
                </td>
                {columns.map((ele, index) => {
                  return (
                    <td key={index}>
                      {ele.columnType === 'text' ? (
                        ele.hasSort ? (
                          <div className="activity-stack__table-display__row--data__has-sort">
                            <div className="activity-stack__table-display__header__text">{ele.columnName}</div>
                            <div className="sort__arrows">
                              <img
                                src={sortArrowUp}
                                alt="sort ascending order"
                                // onClick={() => {
                                //     try {
                                //         let newArray = _.orderBy(sortData, [ele.column_key], ["asc"])
                                //         setTableData(newArray)
                                //     } catch (err) {
                                //         console.log(err)
                                //     }
                                // }}
                              />
                              <img
                                src={sortArrowDown}
                                alt="sort descending order"
                                //  onClick={() => {
                                //     try {
                                //         let newArray = _.orderBy(sortData, [ele.column_key], ["desc"])
                                //         setTableData(newArray)
                                //     } catch (err) {
                                //         console.log(err)
                                //     }
                                // }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="activity-stack__table-display__header__text">{ele.columnName}</div>
                        )
                      ) : (
                        <div>
                          <img src={ele.columnName} alt={ele.column_key} title={ele.column_key} />
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {activityTableData && activityTableData.length > 0 ? (
                activityTableData.map((obj, index) => {
                  let actualDate = new Date(obj.added_date).toLocaleDateString('en-in', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                  });

                  return (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          id={obj.activity_id}
                          value={obj.activity_id}
                          onChange={handleCheckBoxClick}
                          checked={obj.checked}
                        />
                      </td>
                      <td onClick={() => handleRowClick(obj.activity_id)}>{obj.display_name}</td>
                      <td onClick={() => handleRowClick(obj.activity_id)}>
                        {/* Activity Mode */}
                        {obj.activity_details.activity_mode_id === 1 ? (
                          <img src={Learning} alt="learning" />
                        ) : obj.activity_details.activity_mode_id === 2 ? (
                          <img src={Practice} alt="practice" />
                        ) : null}

                        {/* Difficulty Level */}
                        {obj.activity_details.difficulty_level_id === 1 ? (
                          <img src={Easy} alt="easy" />
                        ) : obj.activity_details.difficulty_level_id === 2 ? (
                          <img src={Moderate} alt="moderate" />
                        ) : obj.activity_details.difficulty_level_id === 3 ? (
                          <img src={Advanced} alt="advanced" />
                        ) : null}

                        {/* Activity Category */}
                        {obj.activity_details.activity_category_id === 1 ? (
                          <img src={Team} alt="team" />
                        ) : obj.activity_details.activity_category_id === 2 ? (
                          <img src={Individual} alt="individual" />
                        ) : null}

                        {/* Activity Type */}
                        {obj.activity_details.activity_type_id === 1 ? (
                          <img src={Physical} alt="physical" />
                        ) : obj.activity_details.activity_type_id === 2 ? (
                          <img src={Digital} alt="digital" />
                        ) : obj.activity_details.activity_type_id === 3 ? (
                          <img src={Phygital} alt="phygital" />
                        ) : obj.activity_details.activity_type_id === 4 ? (
                          <img src={WorkSheet} alt="worksheet" />
                        ) : null}

                        {/* Playkit availability */}
                        {obj.activity_details.playkit_name.length > 0 ? (
                          obj.activity_details.playkit_name.length === 1 ? (
                            <img src={HulaHoop} alt="hula-hoop-thumbnail" />
                          ) : (
                            <span>
                              <img src={HulaHoop} alt="hula-hoop-thumbnail" />
                              {`+ ${obj.activity_details.playkit_name.length - 1}`}
                            </span>
                          )
                        ) : null}
                      </td>
                      <td onClick={() => handleRowClick(obj.activity_id)}>
                        {obj.is_min_recommended ? (
                          <img src={MinRecTrue} alt="min rec true" />
                        ) : (
                          <img src={MinRecFalse} alt="min rec false" />
                        )}
                      </td>
                      <td onClick={() => handleRowClick(obj.activity_id)}>{actualDate}</td>
                      <td onClick={() => handleRowClick(obj.activity_id)}>{obj.activity_time_in_min}</td>
                      <td onClick={() => handleRowClick(obj.activity_id)}>
                        {obj.curated ? <div>{'Yes'}</div> : <div>{'No'}</div>}
                      </td>
                      <td onClick={() => handleRowClick(obj.activity_id)}>
                        {obj.access_level_id === 1 ? (
                          <img src={Global} alt="Global access" />
                        ) : obj.access_level_id === 2 ? (
                          <img src={Institution} alt="Organization access" />
                        ) : obj.access_level_id === 3 ? (
                          <img src={Private} alt="Private access" />
                        ) : null}
                      </td>
                      <td onClick={() => handleRowClick(obj.activity_id)}>{obj.view ? obj.view : 0}</td>
                      <td onClick={() => handleRowClick(obj.activity_id)}>{obj.rating ? obj.rating : 0}</td>
                      <td onClick={() => handleRowClick(obj.activity_id)}>{obj.namaste ? obj.namaste : 0}</td>
                      <td onClick={() => handleRowClick(obj.activity_id)}>{obj.study_circle_count ? obj.study_circle_count : 0}</td>
                    </tr>
                  );
                })
              ) : (
                <tr className="explainer-video-table">
                  <td className="no-data-block" style={{ width: '99%' }}>
                    <img
                      src={NoDataFound}
                      alt="pic showing no data found message"
                      style={{ width: '300px', marginLeft: '-85px', marginTop: '-10px' }}
                    />
                    <div className="no-data-text">{'No data found , please reset the filter or add data'}</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ActivityStackTable;
