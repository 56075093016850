import React from 'react';
import './RadioButtonGroup.css';

const RadioButtonGroup = (props) => {
  const { radio, name, handleChecked, clicked, width, access_level_id } = props;

  return (
    <div className="form-radio" style={{ width: width ? width : '100%' }}>
      {radio.map((ele, index) => {
        return (
          <div className="form-radio__button" key={index}>
            <input
              className="button-icon"
              type="radio"
              name={name}
              value={ele[access_level_id]}
              // eslint-disable-next-line
              checked={ele[access_level_id] == clicked}
              onChange={handleChecked}
            />
            <label className="button-label">{ele.code}</label>
          </div>
        );
      })}
    </div>
  );
};

export default RadioButtonGroup;
