import React, { useState } from 'react';
import Modal from 'react-modal';
import './ExplainerVideoMoreFilters.css';

const customStyles = {
  content: {
    position: 'relative',
    left: '50%',
    display: 'inline-block',
    width: '340px',
    height: '520px',
    background: '#FFFFFF',
    borderRadius: '16px',
    transform: 'translate(-50%, 0%)',
  },
};

Modal.setAppElement('#root');

const ExplainerVideoMoreFilters = (props) => {
  const { openModal, handleModalOpenClose } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const modalOpenClose = () => {
    setModalIsOpen(!modalIsOpen);
    handleModalOpenClose();
  };

  return (
    <div>
      <Modal isOpen={openModal} onRequestClose={modalOpenClose} style={customStyles} contentLabel="More Filters">
        <div>I am ExplainerVideoMoreFilters component, click outside to hide me</div>
      </Modal>
    </div>
  );
};

export default ExplainerVideoMoreFilters;
