import https from '../init/https';

// CMDS-EXV-01: Upload the Explainer Video
export const uploadExplainerVideo = async (formData) => {
  console.log('formData in upload', formData);
  try {
    let postData = await https.post(`/explainer_videos`, formData);
    // console.log('postData.data.data', postData.data.data);
    return postData?.data?.data;
  } catch (err) {
    console.log('errrrr', err);
    throw err;
  }
};

// CMDS-EXV-02: Delete Explainer Videos
export const deleteExplainerVideo = async (id) => {
  try {
    let deleteVideo = await https.delete(`/explainer_videos/${id}`);
    if (deleteVideo?.status === 200) {
      return id;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-EXV-03: Get the list of Explainer Videos
export const filteredExplainerVideoTable = async (
  limit,
  offset,
  board_id,
  course_id,
  subject_id,
  audio_language_id,
  subtitle_language_id,
  searchTerm,
) => {
  const user_id = localStorage.getItem('user_id');
  let explainerVideoTable = {};
  try {
    let condition = [],
      cond = '';
    if (limit) {
      condition.push(`limit=${limit}`);
    }
    condition.push(`offset=${offset}`);
    if (user_id) {
      condition.push(`user_id=${user_id}`);
    }
    if (board_id && +board_id !== -1) {
      condition.push(`board_id=${board_id}`);
    }
    if (course_id && +course_id !== -1) {
      condition.push(`course_id=${course_id}`);
    }
    if (subject_id && +subject_id !== -1) {
      condition.push(`subject_id=${subject_id}`);
    }
    if (audio_language_id && +audio_language_id !== -1) {
      condition.push(`audio_language_id=${audio_language_id}`);
    }
    if (subtitle_language_id && +subtitle_language_id !== -1) {
      condition.push(`subtitle_language_id=${subtitle_language_id}`);
    }
    if (searchTerm && searchTerm !== '') {
      condition.push(`search_text=${searchTerm}`);
    }
    if (user_id) {
      condition.push(`added_by_user_id=${user_id}`);
    }
    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }
    console.log('cond for fetching explainer video table', cond);
    explainerVideoTable = await https.get(`/explainer_videos/search${cond}`);
    // console.log('explainerVideoTable', explainerVideoTable);
  } catch (err) {
    console.log('err in fetching explainer video table data', err);
    throw err;
  }
  return explainerVideoTable?.data?.data;
};

// CMDS-EXV-03-01: Count list of Explainer Videos
export const fetchExplainerVideosCount = async (
  limit,
  offset,
  board_id,
  course_id,
  subject_id,
  audio_language_id,
  subtitle_language_id,
  searchTerm,
) => {
  const user_id = localStorage.getItem('user_id');

  try {
    let condition = [],
      cond = '';
    if (limit) {
      condition.push(`limit=${limit}`);
    }
    condition.push(`offset=${offset}`);
    if (user_id) {
      condition.push(`user_id=${user_id}`);
    }
    if (board_id && +board_id !== -1) {
      condition.push(`board_id=${board_id}`);
    }
    if (course_id && +course_id !== -1) {
      condition.push(`course_id=${course_id}`);
    }
    if (subject_id && +subject_id !== -1) {
      condition.push(`subject_id=${subject_id}`);
    }
    if (audio_language_id && +audio_language_id !== -1) {
      condition.push(`audio_language_id=${audio_language_id}`);
    }
    if (subtitle_language_id && +subtitle_language_id !== -1) {
      condition.push(`subtitle_language_id=${subtitle_language_id}`);
    }
    if (searchTerm && searchTerm !== '') {
      condition.push(`search_text=${searchTerm}`);
    }
    if (user_id) {
      condition.push(`added_by_user_id=${user_id}`);
    }

    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }

    let explainerVideosCount = await https.get(`/explainer_videos/count_explainer_videos${cond}`);

    if (explainerVideosCount && explainerVideosCount.data && explainerVideosCount.data.data) {
      return explainerVideosCount.data.data.count;
    } else {
      let err = { message: 'Fetch Content explainerVideosCount - Data is not available' };
      throw err;
    }
  } catch (err) {
    console.log('err in fetching explainer video count', err);
    throw err;
  }
};

// CMDS-EXV-04: Retrieve Explainer Video Details for given Id
export const fetchVideoDetail = async (video_id) => {
  try {
    const videoDetail = await https.get(`/explainer_videos/?explainer_video_id=${video_id}`);
    console.log('fetch videoDetail.data.data', videoDetail.data.data);
    return videoDetail?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-EXV-05: Update the Explainer Video
export const editVideoDetail = async (id, formData) => {
  console.log('formData in update', formData);
  try {
    let editVideoDetail = await https.put(`explainer_videos/${id}`, formData);
    // console.log('editVideoDetail.data.data', editVideoDetail.data.data);
    return editVideoDetail?.data?.data;
  } catch (err) {
    console.log('err', err);
    throw err;
  }
};
