import axios from 'axios';
import { base_url } from '../config/Api.js';

const https = axios.create({
  baseURL: base_url,
});

// Add a request interceptor
https.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    let userToken = localStorage.getItem('token');
    if (userToken) {
      // config.headers['Access-Control-Allow-Origin'] = '*';
      config.headers.Authorization = `Bearer ${userToken}`;
    }
    // OR config.headers.common['Authorization'] = `Bearer ${your_token}`;
    config.baseURL = base_url;
    return config;
  },
  function (error) {
    // Do something with request error
    console.log('error', error.message);
    // toast.error(error.message)
    return Promise.reject(error);
  },
);

// Add a response interceptor
https.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.data) {
      console.log('error in response intercepter', error);
      console.log('error', error?.response?.data?.message);
      // toast.error(error.response.data.message)
      throw error;
    }
    return Promise.reject(error);
  },
);

export default https;
