import React from 'react';
import './CheckBox.css';

const CheckBox = (props) => {
  const { id, name, value, onChanged, checked, label } = props;
  return (
    <label key={id} className="checkbox-element">
      <input type="checkbox" id={id} name={name} value={value} onChange={onChanged} checked={checked} />
      <div className="checkbox-element__label">{label}</div>
    </label>
  );
};

export default CheckBox;
