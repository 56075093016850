import React, { useState, useEffect } from 'react';
import './ContentDetails.css';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RiseLoader from 'react-spinners/RiseLoader';
import SideBar from '../../../components/SideBar/SideBar';
import DisplayContent from './DisplayContent/DisplayContent';
import AddContent from '../UploadContent/AddContent/AddContent';
import { fetchContentDetails, fetchContentFeedback, fetchContentHistory } from '../../../api/content';

const ContentDetails = (props) => {
  const [toggleEditComponent, setToggleEditComponent] = useState(false);
  const [contentDetails, setContentDetails] = useState({});
  const [contentFeedback, setContentFeedback] = useState([]);
  const [contentHistory, setContentHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const url = window.location.href;

  const getUpdatedValues = (obj) => {
    setContentDetails(obj);
  };

  useEffect(() => {
    const getDetails = async () => {
      setLoading(true);
      let id = url.substring(url.lastIndexOf('/') + 1);
      setContentDetails(await fetchContentDetails(id));
      setContentFeedback(await fetchContentFeedback(id));
      setContentHistory(await fetchContentHistory(id));
      setLoading(false);
    };
    const getContentDetails = async () => {
      try {
        await getDetails();
      } catch (err) {
        console.log(err);
        if (err && err.message) {
          toast.error(err.message);
          setTimeout(() => {
            window.location.href = '/content-library';
          }, 3000);
        }
      }
    };
    getContentDetails();
  }, [url]);

  const handleToggle = () => {
    setToggleEditComponent(!toggleEditComponent);
  };

  return (
    <>
      {loading ? (
        <div className="loading__spinners">
          <RiseLoader
            color="#CC435F"
            margin={5}
            cssOverride={{
              position: 'absolute',
              top: '50%',
              left: '45%',
              zIndex: 1,
            }}
            loading={loading}
            size={50}
            speedMultiplier={1}
          />
        </div>
      ) : null}
      <div className="content-main-page">
        <div className="content-main-page__bar-container">
          <SideBar />
          {toggleEditComponent ? (
            <AddContent
              editContentDetails={contentDetails}
              toggleEditComponent={toggleEditComponent}
              getUpdatedValues={getUpdatedValues}
              handleToggle={handleToggle}
            />
          ) : (
            <DisplayContent
              handleToggle={handleToggle}
              contentDetails={contentDetails}
              contentFeedback={contentFeedback}
              setContentFeedback={setContentFeedback}
              contentHistory={contentHistory}
            />
          )}
        </div>
      </div>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />
    </>
  );
};

export default ContentDetails;
