import https from '../init/https';

// CMDS-LOGIN-1: Send Password Reset Mail
export const passwordResetMail = async (formData) => {
  try {
    let res = await https.post(`/user/sendmail`, formData);
    const result = res.data;
    return result;
  } catch (err) {
    console.log('err', err);
    throw err;
  }
};

// CMDS-LOGIN-3: Set password
export const confirmPasswordForm = async (formData, userId) => {
  try {
    let response = await https.post(`/user/save`, {
      user_id: userId,
      password: formData.password,
    });
    const result = response.data;
    return result;
  } catch (err) {
    console.log('err', err);
    throw err;
  }
};

// CMDS-LOGIN-4: Sign in
export const loginForm = async (formData) => {
  console.log('formData', formData);
  try {
    let response = await https.post('/user/signin', formData);
    const result = response.data;
    return result;
  } catch (err) {
    console.log('err', err);
    throw err;
  }
};
