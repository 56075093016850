import React from 'react';
import './FilterButtons.css';

const FilterButtons = (props) => {
  const { modalOpenClose, handleModalApply, apply, marginTop } = props;
  return (
    <div className="filter-button" style={{ marginTop: marginTop && marginTop }}>
      <button className="filter-button__cancel" onClick={modalOpenClose}>
        <div className="cancel-text">Close</div>
      </button>
      <button className="filter-button__apply">
        <div className="apply-text" onClick={handleModalApply}>
          {apply}
        </div>
      </button>
    </div>
  );
};

export default FilterButtons;
