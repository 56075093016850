import React from 'react';
import './ExplainerVideos.css';
import ExplainerVideosMainBar from './ExplainerVideosMainBar/ExplainerVideosMainBar';
import SideBar from '../../../components/SideBar/SideBar';

const ExplainerVideos = (props) => {
  return (
    <div className="explainer-videos-main-page">
      <div className="explainer-videos-main-page__bar-container">
        <SideBar />
        <ExplainerVideosMainBar />
      </div>
    </div>
  );
};

export default ExplainerVideos;
