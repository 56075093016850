import React, { useState } from 'react';
import './NewPassword.css';
import PersonRight from '../../../Assets/Login/LoginScreen/person-right.png';
import '../../../Assets/css/common.css';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import NewPasswordForm from '../../../components/NewPasswordForm/NewPasswordForm';
import SuccessMessage from '../../../components/LoginSuccess/LoginSuccess';

const NewPassword = (props) => {
  const [toggleForm, setToggleForm] = useState(false);
  const user_id = props.match.params.user_id;
  const handleToggle = () => {
    setToggleForm(!toggleForm);
  };

  return (
    <div className="new-password-page">
      <Header />
      <div className="new-password-page__form">
        <div className="new-password-form-left">
          <div className="new-password-box">
            <p>New Password</p>
            <div className="new-password-box-description">
              <p>To change your current Password, type the new password, and then retype it. Click Save.</p>

              <p>We recommend you to have long password with a mix of capital and lowercase letters, numbers, and symbols.</p>
            </div>
            {toggleForm ? <SuccessMessage /> : <NewPasswordForm handleToggle={handleToggle} userId={user_id} />}
          </div>
        </div>
        <div className="new-password-form-right">
          <img src={PersonRight} alt="Person demonstartion" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewPassword;
