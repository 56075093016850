import { S3 } from '@aws-sdk/client-s3';

const s3Client = new S3({
  forcePathStyle: false,
  endpoint: process.env.REACT_APP_ENDPOINT,
  region: process.env.REACT_APP_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
  },
});

export { s3Client };
