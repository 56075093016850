/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import './PlayKitMainBar.css';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RiseLoader from 'react-spinners/RiseLoader';
import SearchBar from '../../../components/SearchBar/SearchBar';
import PlayKitTable from '../../../components/PlayKitTable/PlayKitTable';
import promiseAllCall from './../../../utils/promise';
import { fetchPlayKitList, fetchPlayKitCount } from '../../../api/playKit';

const PlayKitMainBar = (props) => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [playKitList, setPlayKitList] = useState([]);
  const [playKitCount, setPlayKitCount] = useState(0);
  const [isDelete, setIsDelete] = useState(false);

  const initialRender = useRef();
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 12;

  useEffect(() => {
    const getTableData = async () => {
      setLoading(true);
      const callAll = await promiseAllCall([fetchPlayKitList(limit, offset, searchTerm), fetchPlayKitCount(limit, 0, searchTerm)]);
      // console.log('callAll', callAll[0]);
      if (callAll && callAll?.length > 0) {
        if (callAll[1] !== undefined && !callAll[1]?.message) {
          setPlayKitCount(callAll[1]);
        }
      }
      const initialTableData = callAll.length && callAll[0];
      if (initialRender.current) {
        setPlayKitList((prevState) => {
          return [...prevState, ...initialTableData];
        });
      } else {
        if (initialTableData.length > 0) {
          setPlayKitList(initialTableData);
        }
        initialRender.current = true;
      }
      setLoading(false);
    };
    try {
      getTableData();
    } catch (err) {
      console.log('err getTableData- PlayKit Mainbar', err);
      toast.error('Something went wrong try again');
      if (err?.message) {
        toast.error(err.message);
      }
      if (err?.response?.data?.type === 'TOKEN_ERR') {
        toast.info('Session expired, Please login once again');
        setTimeout(() => {
          return (window.location.href = '/login');
        }, 3000);
      }
      setLoading(false);
    }
  }, [searchTerm, offset, limit, isDelete]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setPlayKitList([]);
    setOffset((prevVal) => (prevVal = 0));
  };

  const toggleDelete = () => {
    setIsDelete(!isDelete);
  };

  const updateTableData = async (incomingNewTable) => {
    setPlayKitList(incomingNewTable);
    setPlayKitCount(incomingNewTable.length);
  };

  return (
    <>
      {loading ? (
        <div className="loading__spinners">
          <RiseLoader
            color="#CC435F"
            margin={5}
            cssOverride={{
              position: 'absolute',
              top: '50%',
              left: '45%',
              zIndex: 1,
            }}
            loading={loading}
            size={50}
            speedMultiplier={1}
          />
        </div>
      ) : null}
      <div className="playKit-main-bar">
        <div className="playKit-main-bar__header">
          <div className="page-name">Play Kit</div>
          <SearchBar searchTerm={searchTerm} name={'playKit'} handleIdChange={handleSearch} />
        </div>
        <div className="playKit-main-bar__table-component">
          <PlayKitTable
            tableData={playKitList}
            setTableData={setPlayKitList}
            count={playKitCount}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            toggleDelete={toggleDelete}
            updateTableData={updateTableData}
          />
        </div>
      </div>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />
    </>
  );
};

export default PlayKitMainBar;
