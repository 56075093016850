import React, { useState } from 'react';
import './ContentMenu.css';
import ContentMenuBtns from '../ContentMenuBtns/ContentMenuBtns';
import PreviewThumbnail from '../../Assets/ContentLibrary/ContentDetails/preview-thumbnail.png';
import View from '../../Assets/ContentLibrary/ContentDetails/view.png';
import Namaste from '../../Assets/ContentLibrary/ContentDetails/namaste.png';
import Circle from '../../Assets/ContentLibrary/ContentDetails/circle.png';
import Curated from '../../Assets/ContentLibrary/ContentDetails/curated.png';
import NotCurated from '../../Assets/ContentLibrary/ContentDetails/not-curated.png';
import Global from '../../Assets/ContentLibrary/ContentDetails/global.png';
import Organization from '../../Assets/ContentLibrary/ContentDetails/organization.png';
import Private from '../../Assets/ContentLibrary/ContentDetails/private.png';
import PDF from '../../Assets/ContentLibrary/ContentDetails/pdf.png';
import DOC from '../../Assets/ContentLibrary/ContentDetails/doc.png';
import PPT from '../../Assets/ContentLibrary/ContentDetails/ppt.png';
import XLS from '../../Assets/ContentLibrary/ContentDetails/xls.png';
import WEBM from '../../Assets/ContentLibrary/ContentDetails/webm.png';
import UserPic from '../../Assets/ContentLibrary/ContentDetails/user.png';
import Readable from '../../Assets/ContentLibrary/ContentDetails/readable.png';
import minRecTrue from '../../Assets/ActivityStack/ActivityDetails/min_rec_true.png';
import minRecFalse from '../../Assets/ActivityStack/ActivityDetails/min_rec_false.png';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

const ContentMenu = (props) => {
  const { contentInfo, handleToggle, id_name } = props;

  const [openModal, setOpenModal] = useState(false);

  const handleModalOpenClose = () => {
    setOpenModal(!openModal);
  };

  // console.log("id_name", id_name)
  console.log('contentInfo', contentInfo);

  const handleEditBtn = () => {
    handleToggle(true);
  };

  return (
    <>
      <div className="display-content">
        <div className="display-content__menu-preview">
          <div className="content-thumbnail">
            {contentInfo && (contentInfo.file_type === 'WEBM' || contentInfo.file_type === 'MP4') ? (
              <div className="content-thumbnail__holder">
                <img className="thumbnail-image" src={contentInfo.thumbnail_url} alt="preview thumbnail" />
                <button
                  className="preview-btn"
                  onClick={handleModalOpenClose}
                  style={{ width: '120px', height: '40px', borderColor: 'black' }}
                >
                  Preview
                </button>
              </div>
            ) : contentInfo.thumbnail_url ? (
              <img className="thumbnail-image" src={contentInfo.thumbnail_url} alt="preview thumbnail" />
            ) : (
              <img className="thumbnail-image" src={PreviewThumbnail} alt="preview thumbnail" />
            )}
          </div>
          <div className="preview-details">
            <div className="id-holder">
              <div className="content-id">ID: {contentInfo[id_name]}</div>
              <button className="content-edit-btn" onClick={() => handleEditBtn()}>
                {id_name === 'activity_id' ? 'Edit Activity' : 'Edit Content'}
              </button>
            </div>
            <div className="display-stats">
              <ContentMenuBtns contentInfo={contentInfo} src={View} prop_value="view" />
              <ContentMenuBtns contentInfo={contentInfo} src={Namaste} prop_value="namaste" />
              <ContentMenuBtns contentInfo={contentInfo} src={Circle} prop_value="study_circle_count" />
            </div>
            <hr className="stats-partition"></hr>
            <div className="display-name">{contentInfo.display_name}</div>
            <div className="user-details">
              <img
                className="profile-pic"
                src={contentInfo.profile_pic_url ? contentInfo.profile_pic_url : UserPic}
                alt="user profile pic"
              />
              <div className="user-name">{contentInfo.added_by_user_name}</div>
            </div>
            <hr className="stats-partition"></hr>
            <div className="content-description">{contentInfo.description ? contentInfo.description : 'NA'}</div>
            <hr className="stats-partition"></hr>
            <div className={id_name === 'activity_id' ? 'display-stats--activity-stack' : 'display-stats'}>
              <ContentMenuBtns
                contentInfo={contentInfo}
                src={contentInfo.case !== 'Not_Curated' ? Curated : NotCurated}
                msg={contentInfo.case !== 'Not_Curated' ? 'Curated' : 'Not Curated'}
              />
              <ContentMenuBtns
                contentInfo={contentInfo}
                src={
                  contentInfo.access_level_id === 1
                    ? Global
                    : contentInfo.access_level_id === 2
                      ? Organization
                      : contentInfo.access_level_id === 3
                        ? Private
                        : null
                }
                prop_value="sharing"
              />
              {id_name === 'content_id' ? (
                <>
                  <ContentMenuBtns
                    contentInfo={contentInfo}
                    src={
                      contentInfo.content_file_type_id === 1
                        ? PDF
                        : contentInfo.content_file_type_id === 2
                          ? DOC
                          : contentInfo.content_file_type_id === 3
                            ? PPT
                            : contentInfo.content_file_type_id === 4
                              ? XLS
                              : contentInfo.content_file_type_id === 5
                                ? WEBM
                                : contentInfo.content_file_type_id === 6
                                  ? DOC
                                  : contentInfo.content_file_type_id === 7
                                    ? DOC
                                    : contentInfo.content_file_type_id === 9
                                      ? DOC
                                      : null
                    }
                    prop_value="file_type"
                    msg={contentInfo['file_type'] ? contentInfo['file_type'] : 'WEB URL'}
                  />
                  <ContentMenuBtns
                    contentInfo={contentInfo}
                    src={contentInfo.media_id === 1 ? DOC : contentInfo.media_id === 2 ? Readable : null}
                    prop_value="media_name"
                  />
                </>
              ) : id_name === 'activity_id' ? (
                <ContentMenuBtns
                  contentInfo={contentInfo}
                  src={contentInfo.is_min_recommended ? minRecTrue : minRecFalse}
                  msg={contentInfo.is_min_recommended ? 'Min Recommended' : 'Non Recommended'}
                  width={'160px'}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <VideoPlayer
          openModal={openModal}
          url={contentInfo && contentInfo.content_url && contentInfo.content_url}
          handleModalOpenClose={handleModalOpenClose}
        />
      )}
    </>
  );
};

export default ContentMenu;
