import React from 'react';
import './PrimaryCard.css';

const PrimaryCard = (props) => {
  const { name, contentDetails, prop_value, width } = props;
  return (
    <div className="primary-card" style={{ width: width && width }}>
      <div className="primary-card__description">
        <div className="content">{name}</div>
      </div>
      <div className="primary-card__value">{contentDetails[prop_value]}</div>
    </div>
  );
};

export default PrimaryCard;
