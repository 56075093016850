import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './SideBar.css';
import ModulesItem from '../ModulesItem/ModulesItem';
import SchoolInfo from '../SchoolInfo/SchoolInfo';
import shape from '../../Assets/ContentLibrary/ContentLibrary/SideBar/shape.png';
import youtube from '../../Assets/ContentLibrary/ContentLibrary/SideBar/youtube.png';
import questionBank from '../../Assets/ContentLibrary/ContentLibrary/SideBar/question-bank.png';
import vector from '../../Assets/ContentLibrary/ContentLibrary/SideBar/vector.png';
import contentLibrary from '../../Assets/ContentLibrary/ContentLibrary/SideBar/content-library.png';
import schoolLogo from '../../Assets/ContentLibrary/ContentLibrary/SideBar/school-logo.png';
import Settings from '../../Assets/ContentLibrary/ContentLibrary/SideBar/settings.png';
import playKit from '../../Assets/ContentLibrary/ContentLibrary/SideBar/playKit.png';
import { fetchInstitution } from '../../api/master_data';

const SideBar = (props) => {
  const [linkName, setLinkName] = useState('');
  const [schoolInfo, setSchoolInfo] = useState('');
  const user_id = localStorage.getItem('user_id');
  const url = window.location.href;
  const location = useLocation();

  useEffect(() => {
    const fetchSchoolInfo = async () => {
      setSchoolInfo(await fetchInstitution(user_id));
      setLinkName(url.substring(url.lastIndexOf('/') + 1));
    };
    try {
      fetchSchoolInfo();
    } catch (err) {
      console.log(err);
      throw err;
    }
  }, [user_id, url]);

  const handleLogout = () => {
    localStorage.clear();
    return (window.location.href = '/login');
  };

  return (
    <div className="side-bar">
      <div className="side-bar__school-info">
        <SchoolInfo
          img={schoolInfo.emblem_file_path ? schoolInfo.emblem_file_path : schoolLogo}
          alt="school-logo"
          schoolName={schoolInfo.institution_name}
          schoolAddress={schoolInfo.full_address}
        />
      </div>
      <div className="side-bar__menu-btns">
        <div className="menu-btn-modules">
          <ModulesItem
            name="Dashboard"
            img={shape}
            alt="shape_icon"
            link="/dashboard"
            isTrue={linkName === 'dashboard' ? true : false}
            linkName={'dashboard'}
          />

          <ModulesItem
            name="Content Library"
            img={contentLibrary}
            alt="contentLibrary_icon"
            link="/content-library"
            isTrue={['content-library', 'upload-content', 'content-details'].includes(location.pathname?.split('/')?.[1])}
            linkName={'content-library'}
          />

          <ModulesItem
            name="Activity Stack"
            img={vector}
            alt="vector_icon"
            link="/activity-stack"
            isTrue={['activity-stack', 'upload-activity', 'activity-details'].includes(location.pathname?.split('/')?.[1])}
            linkName={'activity-stack'}
          />

          <ModulesItem
            name="Question Bank"
            img={questionBank}
            alt="question-bank_icon"
            link="https://qb.stucle.in/"
            isTrue={linkName === 'question-bank' ? true : false}
            linkName={'question-bank'}
          />

          <ModulesItem
            name="Explainer Videos"
            img={youtube}
            alt="youtube_icon"
            link="/explainer-videos"
            isTrue={linkName === 'explainer-videos' ? true : false}
            linkName={'explainer-videos'}
          />

          <ModulesItem
            name="Play Kit"
            img={playKit}
            alt="playkit_icon"
            link="/playKit"
            isTrue={['playKit', 'playKit-details', 'upload-playKit'].includes(location.pathname?.split('/')?.[1])}
            linkName={'playKit'}
          />

          <ModulesItem
            name="Reports"
            img={Settings}
            alt="reports_icon"
            link="/reports"
            isTrue={linkName === 'reports' ? true : false}
            linkName={'reports'}
          />

          <ModulesItem
            name="Settings"
            img={Settings}
            alt="settings_icon"
            link="/settings"
            isTrue={linkName === 'settings' ? true : false}
            linkName={'settings'}
          />
        </div>
        <button className="logout-btn" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default SideBar;
