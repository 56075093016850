import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import SideBar from '../../../components/SideBar/SideBar';
import AddPlayKit from '../UploadPlayKit/AddPlayKit/AddPlayKit';
// import './UpdatePlayKit.css';

const UpdatePlayKit = (props) => {
  const { id } = useParams();
  const {
    state: { playKitClicked, mode },
  } = useLocation();
  const [toggleEditComponent, setToggleEditComponent] = useState(true);

  console.log('playKitClicked', { id, playKitClicked, mode });

  const updateToggleEditComponent = () => {
    setToggleEditComponent(!toggleEditComponent);
  };

  return (
    <div className="upload-playKit-main-page">
      <div className="upload-playKit-main-page__bar-container">
        <SideBar />
        <AddPlayKit
          playKitDetails={playKitClicked}
          toggleEditComponent={toggleEditComponent}
          updateToggleEditComponent={updateToggleEditComponent}
        />
      </div>
    </div>
  );
};

export default UpdatePlayKit;
