import https from '../init/https';

// CMDS-PKT-01: Add a playkit
export const addPlayKit = async (formData) => {
  // const body = { data: { playkit_id: id } };
  console.log('formData inside addPlaykit', formData);
  try {
    let result = await https.post(`/playkit`, formData);
    // console.log('result', result, result?.data);
    return result?.data;
  } catch (err) {
    throw err;
  }
};

// CMDS-PKT-02: Edit a playkit
export const editPlayKit = async (formData) => {
  try {
    let result = await https.put(`/playkit`, formData);
    // console.log('result', result, result?.data);
    return result?.data;
  } catch (err) {
    throw err;
  }
};

// CMDS-PKT-03: Delete a playkit
export const deleteSinglePlayKit = async (id) => {
  // console.log('deleteSinglePlayKit', id);
  const body = { data: { playkit_id: id } };
  try {
    let result = await https.delete(`/playkit`, body);
    // console.log('result', result);
    return result;
  } catch (err) {
    throw err;
  }
};

// CMDS-PKT-04: List playkits with search criteria and pagination
export const fetchPlayKitList = async (limit, offset, searchTerm) => {
  let tableData = {};
  try {
    let condition = [],
      cond = '';
    if (limit) {
      condition.push(`limit=${limit}`);
    }
    condition.push(`offset=${offset}`);
    if (searchTerm && searchTerm !== '') {
      condition.push(`search_text=${searchTerm}`);
    }

    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }
    // console.log("cond for fetch activity", cond)
    tableData = await https.get(`/playkit${cond}`);
    // console.log('tableData', tableData);
    return tableData?.data?.data;
  } catch (err) {
    console.log(err);
    if (err) {
      throw err;
    }
  }
  return tableData?.data?.data;
};

// CMDS-PKT-04-01 : Count of playkits
export const fetchPlayKitCount = async (limit, offset, searchTerm) => {
  try {
    let condition = [],
      cond = '';
    if (limit) {
      condition.push(`limit=${limit}`);
    }
    condition.push(`offset=${offset}`);
    if (searchTerm && searchTerm !== '') {
      condition.push(`search_text=${searchTerm}`);
    }

    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }

    let count = await https.get(`/playkit/count${cond}`);
    // console.log('count', count);
    if (count?.data?.data) {
      return count.data.data?.playkit_count;
    } else {
      let err = { message: 'Fetch Activity Count - No data found' };
      throw err;
    }
  } catch (err) {
    // console.log('CMDS-PKT-04-01 : Count of playkits', err);
    if (err) {
      throw err;
    }
  }
};

// CMDS-TOOL-01: Add a tool to playkit
export const addTool = async (formData) => {
  try {
    let result = await https.post(`/playkit/tool`, formData);
    // console.log('result in addTool', result, result?.data);
    return result?.data;
  } catch (err) {
    throw err;
  }
};

// CMDS-TOOL-02: Edit tool details
export const editTool = async (formData) => {
  console.log('formData', formData);
  const { playkit_id, ...actualFormData } = formData;
  try {
    let result = await https.put(`/playkit/tool`, actualFormData);
    console.log('result in editTool', result, result?.data);
    return result?.data;
  } catch (err) {
    throw err;
  }
};

// CMDS-TOOL-03: Delete tool
export const deleteTool = async (id) => {
  const body = { data: { tool_id: id } };
  try {
    let result = await https.delete(`/playkit/tool`, body);
    // console.log('result in deleteTool', result, result?.data);
    return result?.data;
  } catch (err) {
    throw err;
  }
};
