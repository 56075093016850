/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import isURL from 'validator/lib/isURL';
import './AddActivity.css';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RiseLoader from 'react-spinners/RiseLoader';
import ArrowLeft from '../../../../Assets/ContentLibrary/UploadContent/arrow-left.png';
import BrowseFile from '../../../../components/BrowseFile/BrowseFile';
import WebUrl from '../../../../components/WebUrl/WebUrl';
import UploadThumbnail from '../../../../components/UploadThumbnail/UploadThumbnail';
import AddContentSelection from '../../../../components/AddContentSelection/AddContentSelection';
import TextInput from '../../../../components/TextInput/TextInput';
import RadioButtonGroup from '../../../../components/RadioButtonGroup/RadioButtonGroup';
import MultiSelectDropDown from '../../../../components/MultiSelectDropDown/MultiSelectDropDown';
import TileSearch from '../../../../components/TileSearch/TileSearch';
import { cmdsFileUpload } from '../../../../FileUploads/cmdsFileUpload';
import {
  fetchBoards,
  fetchCourse,
  fetchSubjects,
  fetchTopics,
  fetchSubTopics,
  fetchRadioButtons,
  fetchFileTypeList,
  fetchActivityType,
  fetchActivityCategory,
  fetchActivityMode,
  fetchDifficultyLevels,
} from '../../../../api/master_data.js';
import { editActivity, /* fetchExplainerVideoDetail ,*/ uploadActivity } from '../../../../api/activity_stack';

const AddActivity = (props) => {
  const { toggleEditComponent, editContentDetails, handleToggle } = props;

  const [loading, setLoading] = useState(false);
  const [activityFile, setActivityFile] = useState(null);
  const [activityFilePath, setActivityFilePath] = useState(
    editContentDetails && editContentDetails['link_type'] === 'FILE' ? editContentDetails.activity_url : '',
  );
  const [activityUrl, setActivityUrl] = useState(
    toggleEditComponent && editContentDetails['link_type'] === 'WEBLINK' ? editContentDetails.activity_url : '',
  );
  const [activityThumbnail, setActivityThumbnail] = useState(null);
  const [activityThumbnailPath, setActivityThumbnailPath] = useState(editContentDetails ? editContentDetails.thumbnail_url : '');
  // console.log("activityFilePath", activityFilePath)
  // console.log("activityUrl", activityUrl)
  const [boards, setBoards] = useState([]);
  const [board_id, setBoardId] = useState(editContentDetails ? editContentDetails.board_id : -1);
  const [courses, setCourses] = useState([]);
  const [course_id, setCourseId] = useState(editContentDetails ? editContentDetails.course_id : -1);
  const [subjects, setSubjects] = useState([]);
  const [subject_id, setSubjectId] = useState(editContentDetails ? editContentDetails.subject_id : -1);
  const [topicOne, setTopicOne] = useState([]);
  const [topicOne_id, setTopicOne_id] = useState(editContentDetails ? editContentDetails.topic_1_id : -1);
  const [subTopicOne, setSubTopicOne] = useState([]);
  const [subTopicOne_id, setSubTopicOne_id] = useState(editContentDetails ? editContentDetails.sub_topic_1_id : -1);
  const [topicTwo, setTopicTwo] = useState([]);
  const [topicTwo_id, setTopicTwo_id] = useState(editContentDetails ? editContentDetails.topic_2_id : -1);
  const [subTopictwo, setSubTopicTwo] = useState('');
  const [subTopicTwo_id, setSubTopicTwo_id] = useState(editContentDetails ? editContentDetails.sub_topic_2_id : -1);

  const [activityId, setActivityId] = useState(editContentDetails ? editContentDetails.activity_id : 'New Activity');
  const [displayName, setDisplayName] = useState(editContentDetails ? editContentDetails.display_name : '');
  const [description, setDescription] = useState(editContentDetails ? editContentDetails.description : '');
  const [activityTime, setActivityTime] = useState(editContentDetails ? editContentDetails.activity_time_in_min : '');

  const [radio, setRadio] = useState([]);
  const [difficultyLevels, setDifficltyLevels] = useState([]);
  const [activityType, setActivityType] = useState([]);
  const [activityCategory, setActivityCategory] = useState([]);
  const [activityMode, setActivityMode] = useState([]);

  const [clicked, setClicked] = useState(editContentDetails ? editContentDetails.access_level_id : 1);
  const [activityModeClicked, setActivityModeClicked] = useState(editContentDetails ? editContentDetails.activity_mode_id : 1);
  const [activityCategoryClicked, setActivityCategoryClicked] = useState(
    editContentDetails ? editContentDetails.activity_category_id : 1,
  );
  const [difficulty_level_id, setDifficultyLevelId] = useState(editContentDetails ? editContentDetails.difficulty_level_id : -1);
  const [activity_type_id, setActivityTypeId] = useState(editContentDetails ? editContentDetails.activity_type_id : -1);
  const [minRecommended, setMinRecommended] = useState(editContentDetails ? editContentDetails.is_min_recommended : false);

  const [playKitArray, setPlayKitArray] = useState(editContentDetails ? editContentDetails.playkit_json : []);
  const [playKit, setPlayKit] = useState({ id: '', name: '' });

  // const [explainerVideoArray, setExplainerVideoArray] = useState([]);
  const [explainerVideoTitle, setExplainerVideoTitle] = useState(
    editContentDetails ? editContentDetails.explainer_video.explainer_video_title : '',
  );
  const [explainerVideoData, setExplainerVideoData] = useState({
    explainer_video_id: editContentDetails ? editContentDetails.explainer_video.explainer_video_id : 0,
    explainer_video_url: editContentDetails ? editContentDetails.explainer_video.explainer_video_url : '',
  });
  const [activity_link_type, setActivityLinkType] = useState(editContentDetails ? editContentDetails.link_type : '');
  let activityFileType = useRef([]);

  // form validations
  const [formErrors, setFormErrors] = useState({});
  let err = {};

  const institution_id = localStorage.getItem('institution_id');
  const user_id = localStorage.getItem('user_id');

  useEffect(() => {
    const fetchAll = async () => {
      setLoading(true);
      setBoards(await fetchBoards());
      setCourses(await fetchCourse(board_id));
      setSubjects(await fetchSubjects(board_id, course_id));
      setTopicOne(await fetchTopics(board_id, course_id, subject_id, -1));
      setSubTopicOne(await fetchSubTopics(board_id, course_id, subject_id, -1, topicOne_id));
      setTopicTwo(await fetchTopics(board_id, course_id, subject_id, -1));
      setSubTopicTwo(await fetchSubTopics(board_id, course_id, subject_id, -1, topicTwo_id));
      setRadio(await fetchRadioButtons());
      setActivityType(await fetchActivityType());
      setActivityCategory(await fetchActivityCategory());
      setActivityMode(await fetchActivityMode());
      setDifficltyLevels(await fetchDifficultyLevels());
      setLoading(false);
    };
    try {
      fetchAll();
    } catch (err) {
      console.log('err in AddActivity fetchAll', err);
      toast.error('Something went wrong try again');
      if (err?.message) {
        toast.error(err.message);
      }
      setLoading(false);
    }
  }, [board_id, course_id, subject_id, topicOne_id, subTopicOne_id, topicTwo_id, subTopicTwo_id]);

  useEffect(() => {
    const getTypeId = async () => {
      setLoading(true);
      activityFileType.current = await fetchFileTypeList();
      setLoading(false);
    };
    try {
      getTypeId();
    } catch (err) {
      console.log('err in AddActivity fetchAll', err);
      toast.error('Something went wrong try again');
      if (err?.message) {
        toast.error(err.message);
      }
      setLoading(false);
    }
  }, []);

  // const fetchVideoArray = async () => {
  //   if (explainerVideoTitle?.trim().length !== 0) {
  //     setExplainerVideoArray(await fetchExplainerVideoDetail(explainerVideoTitle));
  //   }
  // };

  // const setVideoIdAndUrl = async () => {
  //   explainerVideoArray &&
  //     explainerVideoArray?.forEach((obj) => {
  //       if (obj.explainer_video_title == explainerVideoTitle) {
  //         setExplainerVideoData({
  //           ...explainerVideoData,
  //           explainer_video_id: obj.explainer_video_id,
  //           explainer_video_url: obj.explainer_video_url,
  //         });
  //       }
  //     });
  // };

  // useEffect(() => {
  //   if (explainerVideoTitle?.trim()?.length > 0) {
  //     console.log("I am calling bro")
  //     setLoading(true);
  //     fetchVideoArray();
  //     // setVideoIdAndUrl();
  //     setLoading(false);
  //   }
  // }, [explainerVideoTitle]);

  const handleBoardIdChange = async (e) => {
    let boardId = e.target.value;
    setBoardId(boardId);
  };

  const handleCourseIdChange = (e) => {
    let courseId = e.target.value;
    setCourseId(courseId);
  };

  const handleSubjectIdChange = (e) => {
    let subjectId = e.target.value;
    setSubjectId(subjectId);
  };

  const handleTopicOneChange = (e) => {
    let topicOneId = e.target.value;
    setTopicOne_id(topicOneId);
  };

  const handleSubTopicOneIdChange = (e) => {
    let subTopicOne = e.target.value;
    setSubTopicOne_id(subTopicOne);
  };

  const handleTopicTwoChange = (e) => {
    let topicTwoId = e.target.value;
    setTopicTwo_id(topicTwoId);
  };

  const handleSubTopicTwoIdChange = (e) => {
    let subTopicTwoId = e.target.value;
    setSubTopicTwo_id(subTopicTwoId);
  };

  const handleDifficultyLevelIdChange = (e) => {
    const newDifficultyLevelId = e.target.value;
    setDifficultyLevelId(newDifficultyLevelId);
  };

  const handleActivityTypeIdChange = (e) => {
    const newActivityTypeId = e.target.value;
    setActivityTypeId(newActivityTypeId);
  };

  const handleChecked = (e) => {
    let nameAttribute = e.target.name;
    if (nameAttribute === 'sharing') {
      setClicked(e.target.value);
    } else if (nameAttribute === 'acivityMode') {
      setActivityModeClicked(e.target.value);
    } else if (nameAttribute === 'acivityCategory') {
      setActivityCategoryClicked(e.target.value);
    }
  };

  const handleTextInputChange = (e) => {
    let attr = e.target.name;
    if (attr === 'displayName') {
      setDisplayName(e.target.value);
    } else if (attr === 'description') {
      setDescription(e.target.value);
    } else if (attr === 'activityTime') {
      setActivityTime(e.target.value);
    }
  };

  const handleFileUpload = async (e) => {
    setLoading(true);
    setActivityLinkType('FILE');
    setActivityUrl('');
    let uploadedFile = e.target.files[0];
    if (uploadedFile.size > 10e8) {
      toast.error('File size should be less than 100mb');
    }
    setActivityFile(uploadedFile);
    await cmdsFileUpload(uploadedFile, setActivityFilePath);
    setLoading(false);
    /* if (toggleEditComponent) {
            setActivityUrl("")
            activityFilePath.trim().length !== 0 && toast.success("File uploaded successfully!!")
        } */
    activityFilePath.trim().length !== 0 && toast.success('File uploaded successfully!!');
  };

  const handleFileRemove = (e) => {
    e.preventDefault();
    setActivityFile(null);
    setActivityFilePath('');
  };

  const handleUrlChange = (e) => {
    setActivityUrl(e.target.value);
    setActivityLinkType('WEBLINK');
    setActivityFile(null);
    setActivityFilePath('');
  };

  const handleUploadThumbnail = async (e) => {
    let uploadThumbNail = e.target.files[0];
    if (uploadThumbNail) {
      setLoading(true);
      setActivityThumbnail(uploadThumbNail);
      await cmdsFileUpload(uploadThumbNail, setActivityThumbnailPath, 'uploadThumbnail');
      toast.success('Thumbnail uploaded successfully!!');
      setLoading(false);
    }
    /* if (toggleEditComponent) {
            toast.success("Thumbnail changed successfully!!")
        } */
  };

  // const checkValidTitle = explainerVideoArray.length > 0 && explainerVideoArray.some(ele => ele.explainer_video_title === explainerVideoTitle)
  // console.log("checkValidTitle ", checkValidTitle)

  const getSelectedExplainerVideoData = (explainerVideoObj) => {
    setExplainerVideoData({
      ...explainerVideoData,
      explainer_video_id: explainerVideoObj.explainer_video_id,
    });
  };

  const runValidations = async () => {
    if (+board_id === -1) {
      err.board = 'Please select Board';
    }
    if (+course_id === -1) {
      err.course = 'Please select course';
    }
    if (+subject_id === -1) {
      err.subject = 'Please select subject';
    }
    if (+topicOne_id === -1) {
      err.topicOne = 'Please select topic one';
    }
    if (displayName.trim().length === 0) {
      err.displayName = 'Display name cannot be empty';
    }
    if (description.trim().length === 0) {
      err.description = 'Please provide description';
    }
    if (String(activityTime).trim().length === 0) {
      err.activityTime = 'Provide minimum reading time';
    }
    // if (explainerVideoTitle.trim().length === 0) {
    //   err.explainerVideoTitle = 'Please provide valid video title';
    // }
    // if (!checkValidTitle && explainerVideoTitle.trim().length) {
    //   err.explainerVideoTitle = 'Please provide valid Explainer Video Title from dropdown suggestions';
    //   toast.error("Please select Explainer Video Title from dropdown suggestions.")
    // }
    if (explainerVideoData.explainer_video_id === -1) {
      err.explainerVideoTitle = 'Please provide valid Explainer Video Title from dropdown suggestions';
      toast.error('Please select Explainer Video Title from dropdown suggestions.');
    }
    if (playKitArray.length === 0) {
      err.playKit = 'Please mention playkit for the activity';
    }
    if (+difficulty_level_id === -1) {
      err.difficulty_level_id = 'Please select difficulty level';
    }
    if (+activity_type_id === -1) {
      err.activity_type_id = 'Please select activity type';
    }

    // uplaoding File
    if (toggleEditComponent) {
      if (activityUrl.trim().length === 0 && activityFilePath.trim().length === 0) {
        err.activityFile = 'Please upload file or enter url';
      }
      if (activityFile !== null && activityFilePath.trim().length === 0) {
        err.activityFile = 'Please upload your Activity File once again';
      }
      if (activityFilePath.trim().length === 0 && activityUrl.trim().length !== 0 && isURL(activityUrl) === false) {
        err.activityFile = 'Please enter valid url';
      }
    } else {
      // Add Activity Page -- Uploading file
      if (activityFile === null && activityUrl.trim().length === 0 && activityFilePath.trim().length === 0) {
        err.activityFile = 'Please upload file or enter url';
      }
      if (activityFile !== null && activityFilePath.trim().length === 0) {
        err.activityFile = 'Please upload your Activity File once again';
      }
      if (activityFilePath.trim().length === 0 && activityUrl.trim().length !== 0 && isURL(activityUrl) === false) {
        err.activityFile = 'Please enter valid url';
      }
    }

    // Uploading thumbnail
    if (toggleEditComponent) {
      // Edit Activity Page
      if (activityThumbnailPath.trim().length === 0) {
        err.activityThumbnail = 'Please upload activityThumbnail once again';
      }
    } else {
      // Add activity Page
      if (activityThumbnail === null) {
        err.activityThumbnail = 'Please provide activityThumbnail';
      }
      if (activityThumbnailPath.trim().length === 0) {
        err.activityThumbnail = 'Please upload activityThumbnail once again';
      }
    }
  };

  const resetForm = async () => {
    setBoardId(-1);
    setDifficultyLevelId(-1);
    setActivityTypeId(-1);
    setActivityId('New Activity');
    setDisplayName('');
    setDescription('');
    setActivityTime('');
    setActivityFile(null);
    setActivityUrl('');
    setActivityThumbnail(null);
    setActivityThumbnailPath('');
    setExplainerVideoTitle('');
    setClicked(1);
    setActivityModeClicked(1);
    setActivityCategoryClicked(1);
    setMinRecommended(false);
    setPlayKitArray([]);
  };
  // console.log("explainerVideoData", explainerVideoData)
  const handleSubmit = async (e) => {
    e.preventDefault();
    runValidations();
    if (Object.keys(err).length === 0) {
      setFormErrors({});

      let formData = {
        display_name: displayName,
        activity_url: activityFilePath ? activityFilePath : activityUrl,
        thumbnail_url: activityThumbnailPath,
        board_id: +board_id,
        course_id: +course_id,
        subject_id: +subject_id,
        topic_1_id: +topicOne_id,
        sub_topic_1_id: +subTopicOne_id,
        topic_2_id: +topicTwo_id,
        sub_topic_2_id: +subTopicTwo_id,
        description: description,
        is_min_recommended: minRecommended,
        activity_mode_id: +activityModeClicked,
        difficulty_level_id: +difficulty_level_id,
        activity_category_id: +activityCategoryClicked,
        activity_type_id: +activity_type_id,
        playkit_json: playKitArray,
        activity_time_in_min: +activityTime,
        access_level_id: +clicked,
        explainer_video_id: explainerVideoData.explainer_video_id,
        // explainer_video_url: explainerVideoData.explainer_video_url,
        added_by_user_id: +user_id,
        institution_id: +institution_id,
        link_type: activity_link_type,
      };

      console.log('formData', formData);

      try {
        if (!toggleEditComponent) {
          setLoading(true);
          await uploadActivity(formData);
          setLoading(false);
          toast.success('Activity saved successfully!');
          await resetForm();
        } else {
          setLoading(true);
          await editActivity(editContentDetails.activity_id, formData);
          setLoading(false);
          toast.success('Activity updated successfully!');
          setTimeout(() => {
            window.location.href = '/activity-stack';
          }, 4000);
          await resetForm();
        }
      } catch (err) {
        console.log('err in activity stack uploading', err);
        toast.error(err?.response?.data?.message);
        toast.error('Activity Upload failed! Try again!');
        setLoading(false);
      }
    } else {
      toast.error('Please provide required details properly!');
      setFormErrors(err);
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="loading__spinners">
          <RiseLoader
            color="#CC435F"
            margin={5}
            cssOverride={{
              position: 'absolute',
              top: '50%',
              left: '45%',
              zIndex: 1,
            }}
            loading={loading}
            size={50}
            speedMultiplier={1}
          />
        </div>
      ) : null}
      <div className="add-activity">
        <div className="add-activity__header">
          <Link to={toggleEditComponent ?? '/activity-stack'} onClick={handleToggle}>
            <img className="header-img" src={ArrowLeft} alt="back to content library" />
          </Link>
          <div className="header-text">{toggleEditComponent ? 'Edit Activity' : 'Upload Activity'}</div>
        </div>
        <form className="add-activity__form" onSubmit={handleSubmit}>
          <div className="form-attachment">
            <BrowseFile
              file={activityFile}
              handleFileUpload={handleFileUpload}
              handleFileRemove={handleFileRemove}
              toggleEditComponent={toggleEditComponent}
              editContentDetails={editContentDetails}
              accept={'.doc, .docx, .xml, .pdf, .ppt, .xls, xlsx, .webm, .pptx, .jpg, .jpeg, .jfif, .png'}
              prop_value="activity_url"
            />

            <WebUrl value={activityUrl} handleUrlChange={handleUrlChange} />
          </div>
          {formErrors.activityFile && (
            <div className="add-content-form-error" style={{ textAlign: 'center', marginBottom: '10px', marginTop: '-10px' }}>
              {formErrors.activityFile}
            </div>
          )}

          <div className="form-inputs">
            <div className="form-left">
              <UploadThumbnail
                thumbnail={activityThumbnail}
                handleUploadThumbnail={handleUploadThumbnail}
                formErrors={formErrors.activityThumbnail}
                toggleEditComponent={toggleEditComponent}
                editContentDetails={editContentDetails}
              />

              <div className="form-dropdowns">
                <AddContentSelection
                  name="board"
                  label="Board"
                  default_option="Select"
                  values={boards}
                  id_name="board_id"
                  display_name="board_name"
                  handleIdChange={handleBoardIdChange}
                  formErrors={formErrors}
                  editContentDetails={editContentDetails}
                  setValues={setBoards}
                  id={'board_id'}
                  value={board_id}
                />

                <AddContentSelection
                  name="course"
                  default_option="Select"
                  label="Course"
                  values={courses}
                  id_name="course_id"
                  display_name="course_name"
                  formErrors={formErrors}
                  handleIdChange={handleCourseIdChange}
                  editContentDetails={editContentDetails}
                  setValues={setCourses}
                  id={'course_id'}
                  value={course_id}
                />

                <AddContentSelection
                  name="subject"
                  default_option="Select"
                  label="Subject"
                  values={subjects}
                  id_name="subject_id"
                  display_name="subject_name"
                  formErrors={formErrors}
                  handleIdChange={handleSubjectIdChange}
                  editContentDetails={editContentDetails}
                  setValues={setSubjects}
                  id={'subject_id'}
                  value={subject_id}
                />

                <AddContentSelection
                  name="topicOne"
                  default_option="Select"
                  label="Topic 1"
                  values={topicOne}
                  id_name="topic_id"
                  display_name="topic_name"
                  formErrors={formErrors}
                  handleIdChange={handleTopicOneChange}
                  editContentDetails={editContentDetails}
                  setValues={setTopicOne}
                  actual_id_name="topic_1_id"
                  id={'topic_1_id'}
                  value={topicOne_id}
                  display_eng_name="topic_name_eng"
                />

                <AddContentSelection
                  name="subTopicOne"
                  default_option="Select"
                  label="Sub Topic 1"
                  values={subTopicOne}
                  id_name="sub_topic_id"
                  display_name="sub_topic_name"
                  formErrors={formErrors}
                  handleIdChange={handleSubTopicOneIdChange}
                  editContentDetails={editContentDetails}
                  setValues={setSubTopicOne}
                  actual_id_name="sub_topic_1_id"
                  id={'sub_topic_1_id'}
                  value={subTopicOne_id}
                  display_eng_name="sub_topic_name_eng"
                />

                <AddContentSelection
                  name="topicTwo"
                  default_option="Select"
                  label="Topic 2"
                  values={topicTwo}
                  id_name="topic_id"
                  display_name="topic_name"
                  formErrors={formErrors}
                  handleIdChange={handleTopicTwoChange}
                  editContentDetails={editContentDetails}
                  setValues={setTopicTwo}
                  actual_id_name="topic_2_id"
                  id={'topic_2_id'}
                  value={topicTwo_id}
                  display_eng_name="topic_name_eng"
                />

                <AddContentSelection
                  name="subTopicTwo"
                  default_option="Select"
                  label="Sub Topic 2"
                  values={subTopictwo}
                  id_name="sub_topic_id"
                  display_name="sub_topic_name"
                  formErrors={formErrors}
                  handleIdChange={handleSubTopicTwoIdChange}
                  editContentDetails={editContentDetails}
                  setValues={setSubTopicTwo}
                  actual_id_name="sub_topic_2_id"
                  id={'sub_topic_2_id'}
                  value={subTopicTwo_id}
                  display_eng_name="sub_topic_name_eng"
                />
              </div>
            </div>
            <div className="form-right" style={{ width: '42%', marginTop: '0px' }}>
              <TextInput
                name="activityId"
                label="Content ID"
                formErrors={formErrors}
                type="text"
                value={activityId}
                handleTextInputChange={handleTextInputChange}
                readOnly={true}
              />

              <TextInput
                name="displayName"
                label="Display Name"
                formErrors={formErrors}
                value={displayName}
                type="text"
                handleTextInputChange={handleTextInputChange}
              />

              <TextInput
                name="description"
                label="Description"
                height={'72px'}
                formErrors={formErrors}
                value={description}
                type="text"
                handleTextInputChange={handleTextInputChange}
              />

              <label style={{ cursor: 'pointer' }}>
                <input
                  type="checkbox"
                  value={minRecommended}
                  onChange={() => setMinRecommended(!minRecommended)}
                  checked={minRecommended}
                />
                <span className="form-right__checkbox--text">Min Recommended</span>
              </label>

              <div className="form-right-radio" style={{ marginTop: '25px' }}>
                <div className="form-radio-category">Activity Details</div>
                <RadioButtonGroup
                  radio={activityMode}
                  name="acivityMode"
                  clicked={activityModeClicked}
                  handleChecked={handleChecked}
                  width={'45%'}
                  access_level_id={'activity_mode_id'}
                />
              </div>

              <AddContentSelection
                name="difficulty_level_id"
                default_option="Select"
                label="Difficulty level"
                values={difficultyLevels}
                id_name="difficulty_level_id"
                display_name="difficulty_level_name"
                formErrors={formErrors}
                handleIdChange={handleDifficultyLevelIdChange}
                editContentDetails={editContentDetails}
                setValues={setSubTopicTwo}
                actual_id_name="difficulty_level_id"
                id={'difficulty_level_id'}
                value={difficulty_level_id}
                marginTop={'25px'}
              />

              <div className="form-right-radio" style={{ marginTop: '25px' }}>
                <div className="form-radio-category">Type</div>
                <RadioButtonGroup
                  radio={activityCategory}
                  name="acivityCategory"
                  clicked={activityCategoryClicked}
                  handleChecked={handleChecked}
                  width={'45%'}
                  access_level_id={'activity_category_id'}
                />
              </div>

              <AddContentSelection
                name="activity_type_id"
                default_option="Select"
                label="Activity Type"
                values={activityType}
                id_name="activity_type_id"
                display_name="activity_type_name"
                formErrors={formErrors}
                handleIdChange={handleActivityTypeIdChange}
                editContentDetails={editContentDetails}
                setValues={setSubTopicTwo}
                actual_id_name="activity_type_id"
                id={'activity_type_id'}
                value={activity_type_id}
                marginTop={'25px'}
              />

              <MultiSelectDropDown
                name="playKit"
                label="Playkit"
                formErrors={formErrors}
                playKit={playKit}
                setPlayKit={setPlayKit}
                playKitArray={playKitArray}
                setPlayKitArray={setPlayKitArray}
              />

              <TextInput
                name="activityTime"
                label="Activity Time ( in min.)"
                formErrors={formErrors}
                value={activityTime}
                type="number"
                handleTextInputChange={handleTextInputChange}
              />

              <TileSearch
                name="explainerVideoTitle"
                label="Explainer Video"
                formErrors={formErrors}
                type="text"
                tileSearch={explainerVideoTitle}
                setTileSearch={setExplainerVideoTitle}
                getSelectedExplainerVideoData={getSelectedExplainerVideoData}
                explainerVideoData={explainerVideoData}
                setExplainerVideoData={setExplainerVideoData}
                runValidations={runValidations}
                widthinModal={false}
                prop_value="explainer_video_title"
              />

              <div className="form-right-radio" style={{ marginBottom: '20px' }}>
                <div className="form-radio-category">Sharing</div>
                <RadioButtonGroup
                  radio={radio}
                  name="sharing"
                  clicked={clicked}
                  handleChecked={handleChecked}
                  access_level_id={'access_level_id'}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="form-submission">
          <Link to="/activity-stack">
            <button className="cancel-btn" type="reset">
              Cancel
            </button>
          </Link>
          <button className="save-btn" type="submit" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />
    </>
  );
};

export default AddActivity;
