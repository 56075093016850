import React, { useState } from 'react';
import './Password.css';
import '../../../src/Assets/css/common.css';

const PasswordInput = (props) => {
  const { onChange, value, errors, placeholder, name, showPassword } = props;
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };

  return (
    <>
      <div className="login-password">
        <input
          type={show ? 'text' : 'password'}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={name}
          className="login-password__field"
          style={{ width: showPassword ? '60%' : '100%' }}
        />
        {showPassword && (
          <span className="login-password__show" onClick={handleShow}>
            Show Password
          </span>
        )}
      </div>
      <div className="formik-error">{errors}</div>
    </>
  );
};

export default PasswordInput;
