import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PersonRight from '../../../Assets/Login/LoginScreen/person-right.png';
import SignIn from '../../../Assets/Login/LoginScreen/signin.png';
import UserName from '../../../components/UserName/UserName';
import Password from '../../../components/Password/Password';
import Button from '../../../components/Button/Button';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import '../../../Assets/css/msedge.css';
import '../../../Assets/css/common.css';
import './Login.css';
import { loginForm } from '../../../api/login';

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required('Provide email'),
  password: Yup.string().trim().required('Password cannot be empty'),
});

const Login = (props) => {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (formData, { resetForm }) => {
      try {
        let response = await loginForm(formData);
        resetForm();
        toast.success('User logged in successfully');
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('institution_id', response.data.result.institution_id);
        localStorage.setItem('user_id', response.data.result.user_id);
        localStorage.setItem('userName', response.data.result.full_name);
        setTimeout(() => {
          props.history.push('/content-library');
        }, 2000);
      } catch (err) {
        toast.error(err.response.data.message);
      }
    },
    validateOnChange: true,
    validationSchema: loginSchema,
  });

  return (
    <>
      <div className="login-page">
        <Header />
        <div className="login__form">
          <div className="login-form-left">
            <form onSubmit={formik.handleSubmit} className="formik">
              <div className="formik-input">
                <UserName
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  errors={formik.errors.email}
                  placeholder="Username"
                />

                <Password
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  errors={formik.errors.password}
                  placeholder="Password"
                  showPassword={true}
                  name="password"
                />

                <Button type="submit" img={SignIn} name="Sign in" />
                <div className="forgotPassword">
                  <a href="/reset_password" style={{ textDecoration: 'none' }}>
                    Forgot password?
                  </a>
                </div>
              </div>
            </form>
          </div>
          <div className="login-form-right">
            <img src={PersonRight} alt="Person demonstration" />
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />
    </>
  );
};

export default Login;
