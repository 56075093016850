import React from 'react';
import './SecondaryCard.css';

const SecondaryCard = (props) => {
  const { name, prop_value, contentDetails } = props;

  return (
    <div className="secondary-card">
      <div className="secondary-card__description">
        <div className="secondary-card-content">{name}</div>
      </div>
      <div className="secondary-card__value">{contentDetails[prop_value] ? contentDetails[prop_value] : 'NA'}</div>
    </div>
  );
};

export default SecondaryCard;
