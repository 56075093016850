import React from 'react';
import './ActivityStackDashboard.css';
import SideBar from '../../components/SideBar/SideBar';
import ActivityMainBar from './ActivityMainBar/ActivityMainBar';

const ActivityStack = (props) => {
  return (
    <div className="activity-stack-main-page">
      <div className="activity-stack-main-page__bar-container">
        <SideBar />
        <ActivityMainBar />
      </div>
    </div>
  );
};

export default ActivityStack;
