/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './ActivityStackMoreFilters.css';
import ModalCancel from '../../Assets/ActivityStack/modal-cancel.png';
import CheckBoxGroup from '../CheckBoxGroup/CheckBoxGroup';
import DropDown from '../DropDown/DropDown';
import FilterButtons from '../FilterButtons/FilterButtons';
import ToggleBox from '../ToggleBox/ToggleBox';
import CheckBox from '../CheckBox/CheckBox';

const customStyles = {
  content: {
    position: 'relative',
    left: '50%',
    top: '1%',
    display: 'inline-block',
    width: '340px',
    height: '537px',
    background: '#FFFFFF',
    borderRadius: '16px',
    transform: 'translate(-50%, 0%)',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
};
Modal.setAppElement('#root');

const ActivityStackMoreFilters = (props) => {
  const {
    openModal,
    handleModalOpenClose,
    activityCategory,
    activityMode,
    activityType,
    activityModalData,
    setActivityModalData,
    setActivityCategory,
    setActivityMode,
    setActivityType,
    authors,
    setAuthors,
  } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activity_category_ids, setActivityCategoryId] = useState(activityModalData.activity_category_ids);
  const [activity_mode_ids, setActivityModeId] = useState(activityModalData.activity_mode_ids);
  const [activity_type_ids, setActivityTypeId] = useState(activityModalData.activity_type_ids);
  const [is_min_recommended, setIsRecommended] = useState(activityModalData.is_min_recommended);
  const [activity_link_type, setActivityLinkType] = useState(activityModalData.link_type);
  const [authorId, setAuthorId] = useState(activityModalData.authorId);

  useEffect(() => {
    const getSelectedValues = () => {
      getOptedValues(activityMode, activityModalData, 'activity_mode_ids', setActivityMode, 'activity_mode_id');
      getOptedValues(activityCategory, activityModalData, 'activity_category_ids', setActivityCategory, 'activity_category_id');
      getOptedValues(activityType, activityModalData, 'activity_type_ids', setActivityType, 'activity_type_id');
      getSelectedAuthor(activityModalData, authors);

      setTheActivityMode(activityMode);
      setTheActivityCategory(activityCategory);
      setTheActivityType(activityType);
    };
    try {
      getSelectedValues();
    } catch (err) {
      console.log(err);
      throw err;
    }
  }, [activityCategory, activityMode, activityType]);

  const getSelectedAuthor = (activityModalData, authors) => {
    let selectedAuthor = activityModalData.authorId;

    authors.forEach((obj) => {
      if (obj.user_id === selectedAuthor) {
        obj.selected = true;
      }
    });
    setAuthors(authors);
  };

  const updateUserId = (id) => {
    setAuthorId(id);
  };

  const updateUserValue = (values) => {
    setAuthors(values);
  };

  const getOptedValues = (values, activityModalData, id_name, updaterFunction, value_id_name) => {
    let selectedId = activityModalData[id_name];
    let selectedIdArray = selectedId.split(',');

    for (let index = 0; index < selectedIdArray.length; index++) {
      const element = selectedIdArray[index];

      values.forEach((obj) => {
        // eslint-disable-next-line
        if (obj[value_id_name] == element) {
          obj.checked = true;
        }
      });
    }
    updaterFunction(values);
  };

  const extractId = (values, propValue) => {
    let activityTypeIdArray = [];
    let activityCategoryIdArray = [];
    let activityModeIdArray = [];

    for (let index = 0; index < values.length; index++) {
      const obj = values[index];
      if (obj.hasOwnProperty('checked') && obj.checked) {
        if (propValue === 'activity_category_id') {
          activityCategoryIdArray.push(obj[propValue]);
        } else if (propValue === 'activity_mode_id') {
          activityModeIdArray.push(obj[propValue]);
        } else if (propValue === 'activity_type_id') {
          activityTypeIdArray.push(obj[propValue]);
        }
      }
    }
    if (propValue === 'activity_category_id') {
      setActivityCategoryId(activityCategoryIdArray.join(','));
    } else if (propValue === 'activity_mode_id') {
      setActivityModeId(activityModeIdArray.join(','));
    } else if (propValue === 'activity_type_id') {
      setActivityTypeId(activityTypeIdArray.join(','));
    }
  };

  const setTheActivityCategory = (values) => {
    extractId(values, 'activity_category_id');
    setActivityCategory(values);
  };

  const setTheActivityMode = (values) => {
    extractId(values, 'activity_mode_id');
    setActivityMode(values);
  };

  const setTheActivityType = (values) => {
    extractId(values, 'activity_type_id');
    setActivityType(values);
  };

  const retriveToggleValue = (value) => {
    setIsRecommended(value);
  };

  const toggleChecked = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setActivityLinkType('ALL');
    } else {
      setActivityLinkType('FILE');
    }
  };
  const modalOpenClose = () => {
    setModalIsOpen(!modalIsOpen);
    handleModalOpenClose();
  };

  const handleModalApply = () => {
    setActivityModalData({
      ...activityModalData,
      is_min_recommended: is_min_recommended,
      activity_mode_ids: activity_mode_ids,
      activity_category_ids: activity_category_ids,
      activity_type_ids: activity_type_ids,
      link_type: activity_link_type,
      authorId: +authorId,
    });
    modalOpenClose();
  };

  const sortType = [
    { sort_type_id: 1, sort_type_name: 'Most relevant' },
    { sort_type_id: 2, sort_type_name: 'Newest first' },
    { sort_type_id: 3, sort_type_name: 'Highest rating' },
  ];

  return (
    <div>
      <Modal isOpen={openModal} onRequestClose={modalOpenClose} style={customStyles} contentLabel="More Filters for Activity stack">
        <div className="activity-stack-modal">
          <div className="activity-stack-modal__header">
            <div className="activity-stack-header-text">Filter</div>
            <img src={ModalCancel} alt="close button" style={{ cursor: 'pointer' }} onClick={modalOpenClose} />
          </div>
          <div>
            <hr className="activity-stack-modal__seperator"></hr>
          </div>

          <ToggleBox
            label_1={'All Activities'}
            label_2={'Min Recommended'}
            is_min_recommended={is_min_recommended}
            retriveToggleValue={retriveToggleValue}
            font_selected_true={'bold'}
            font_selected_false={'400'}
            color_selected_true={'#2F80ED'}
            color_selected_false={'#4F4F4F'}
          />

          <div>
            <hr className="activity-stack-modal__seperator" style={{ marginTop: '-10px' }}></hr>
          </div>

          <DropDown
            name="Author"
            default_option="Sort by"
            withinModal={true}
            values={sortType}
            id_name="sort_type_id"
            display_name="sort_type_name"
            marginTop={'10px'}
            // updateUserId={updateUserId}
            // updateUserValue={updateUserValue}
            // handleModalApply={handleModalApply}
          />

          <DropDown
            name="Author"
            default_option="Author Name"
            withinModal={true}
            values={authors}
            id_name="user_id"
            display_name="full_name"
            updateUserId={updateUserId}
            updateUserValue={updateUserValue}
            marginTop={'12px'}
            // handleModalApply={handleModalApply}
          />
        </div>
        <div>
          <hr className="activity-stack-modal__seperator"></hr>
        </div>

        <div className="activity-stack-modal__checkbox-group">
          <CheckBoxGroup
            values={activityMode}
            category="Activity Mode"
            name="activity mode"
            label="activity_mode_name"
            inputValue="activity_mode_id"
            setValues={setTheActivityMode}
          />
          <div>
            <hr className="activity-stack-modal__seperator"></hr>
          </div>
          <CheckBoxGroup
            values={activityType}
            category="Activity Type"
            name="activity type"
            label="activity_type_name"
            inputValue="activity_type_id"
            marginTop={'20px'}
            setValues={setTheActivityType}
          />
          <div>
            <hr className="activity-stack-modal__seperator"></hr>
          </div>
          <CheckBoxGroup
            values={activityCategory}
            category="Activity Category"
            name="activity category"
            label="activity_category_name"
            inputValue="activity_category_id"
            marginTop={'20px'}
            setValues={setTheActivityCategory}
          />
        </div>

        <div>
          <hr className="activity-stack-modal__seperator"></hr>
        </div>
        <div className="checkbox-category" style={{ marginBottom: '8px', marginTop: '20px' }}>
          {'Others'}
        </div>
        <CheckBox
          id={1}
          name={'all'}
          value={activity_link_type}
          onChanged={toggleChecked}
          checked={activity_link_type === 'ALL' ? true : false}
          label={'Show Web links also'}
        />
        <div>
          <hr className="activity-stack-modal__seperator"></hr>
        </div>

        <FilterButtons handleModalApply={handleModalApply} modalOpenClose={modalOpenClose} apply={'Apply'} />
      </Modal>
    </div>
  );
};

export default ActivityStackMoreFilters;
