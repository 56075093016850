import React, { useState, useEffect, useRef } from 'react';
import './MultiSelectDropDown.css';
import PlayKitButton from '../PlayKitButton/PlayKitButton';
import { fetchPlayKitSuggetions } from '../../api/master_data';

const MultiSelectDropDown = (props) => {
  const { label, name, formErrors, playKit, setPlayKit, playKitArray, setPlayKitArray } = props;

  const [suggestions, setSuggestions] = useState([]);
  const [localPlayKit, setLocalPlayKit] = useState({ localPlayKit_name: '' });
  const wrapper = useRef(null);
  const searchInput = useRef();
  let filteredData = [];

  // console.log("playKitArray", playKitArray)
  const handleTextInputChange = (e) => {
    const { value } = e.target;
    setLocalPlayKit((prevState) => ({
      ...prevState,
      localPlayKit_name: value,
    }));
    getPlayKitSuggestions(value.toLowerCase());
  };

  const handleCreateClick = () => {
    setPlayKit((prevState) => ({
      ...prevState,
      id: Number(new Date()),
      name: localPlayKit.localPlayKit_name,
    }));
    setLocalPlayKit((prevState) => ({
      ...prevState,
      localPlayKit_name: '',
    }));
  };

  useEffect(() => {
    const appendPlayKit = () => {
      setPlayKitArray([...playKitArray, playKit]);
    };
    playKit.name !== '' && appendPlayKit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playKit.id]);

  const handleClickOutSide = (e) => {
    const { current: wrap } = wrapper; //wrapper.current = wrap
    if (wrap && !wrap.contains(e.target)) {
      setSuggestions([]);
    }
  };

  const updatePlayKitArray = async (value) => {
    if (value.length > 0) {
      let filteredObj = suggestions.filter((obj) => {
        return obj.name === value;
      });
      // console.log("filteredObj", filteredObj)
      setPlayKitArray([...playKitArray, filteredObj[0]]);
      setSuggestions([]);
    }
  };

  const keyStrokeFunction = (e) => {
    let keysToPress = ['Enter', 'ArrowUp', 'ArrowDown', 'Escape'];
    const keyPressed = e.key;
    if (keyPressed === 'ArrowUp' || keyPressed === 'ArrowDown' || keyPressed === 'Escape' || keyPressed === 'Enter') {
      if (keysToPress.indexOf(keyPressed) > -1) {
        e.preventDefault();
      }

      // This is to get the array of all the elments inside ul
      const resultItems = Array.from(wrapper.current.children);

      // This is to get the index of the li which is focused presently.
      const activeResultIndex = resultItems.findIndex((child) => {
        return child.querySelector('div') === document.activeElement;
      });

      if (keyPressed === 'Enter') {
        let selectedPlayKit = resultItems[activeResultIndex].querySelector('div').innerText;
        updatePlayKitArray(selectedPlayKit);
        return;
      }

      const inputIsFocused = searchInput.current;
      console.log('inputIsFocusd', inputIsFocused);
      if (keyPressed === 'ArrowUp') {
        if (inputIsFocused === document.activeElement) {
          resultItems[resultItems.length - 1].querySelector('div').focus();
        } else if (resultItems[activeResultIndex - 1]) {
          resultItems[activeResultIndex - 1].querySelector('div').focus();
        } else {
          inputIsFocused.focus();
        }
      }

      if (keyPressed === 'ArrowDown') {
        if (inputIsFocused === document.activeElement) {
          resultItems[0].querySelector('div').focus();
        } else if (resultItems[activeResultIndex + 1]) {
          resultItems[activeResultIndex + 1].querySelector('div').focus();
        } else {
          searchInput.current.focus();
        }
      }

      if (keyPressed === 'Escape') {
        setSuggestions([]);
      }
    }
  };

  useEffect(() => {
    if (suggestions.length > 0) {
      document.addEventListener('mousedown', handleClickOutSide);
      document.addEventListener('keydown', keyStrokeFunction);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
      document.removeEventListener('keydown', keyStrokeFunction);
    };
    // eslint-disable-next-line
  }, [suggestions]);

  const selectedOption = (playKit) => {
    setPlayKit(playKit);
  };

  const handleRemovePlayKit = (id) => {
    const filteredPlayKitArr = playKitArray.filter((obj) => {
      return obj.id !== id;
    });
    setPlayKitArray(filteredPlayKitArr);
  };

  const getPlayKitSuggestions = async (value) => {
    console.log('value', value);
    if (value === undefined || value === '') {
      console.log('Text field is empty');
      return setSuggestions([]);
    } else {
      console.log('Search text is ' + value);
    }
    try {
      filteredData = await fetchPlayKitSuggetions(value);
      setSuggestions(filteredData);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  return (
    <>
      <div className="form-right-text form-right-text-input" style={{ marginBottom: '0px' }}>
        {label && <div className="form-right-text__label">{label}</div>}
        <input
          className="form-right-text__input-field"
          type="text"
          value={localPlayKit.localPlayKit_name}
          onChange={handleTextInputChange}
          ref={searchInput}
        />
      </div>
      {suggestions && suggestions.length > 0 && (
        <ul className="form-right-text__input-suggestions" style={{ position: 'relative', marginTop: '1px' }} ref={wrapper}>
          {suggestions.map((playKit, index) => {
            return (
              <li key={playKit.id}>
                <div className="text-input__suggestion--element" onClick={() => selectedOption(playKit)} tabIndex={index}>
                  {playKit.name}
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {localPlayKit.localPlayKit_name !== '' && (
        <div>
          <ul className="form-right-text__input-suggestions" style={{ position: 'relative', marginTop: '1px' }} ref={wrapper}>
            <li>
              <div className="text-input__suggestion--element" onClick={handleCreateClick} tabIndex={0}>
                Create "{localPlayKit.localPlayKit_name}"
              </div>
            </li>
          </ul>
          {/* {!create && playKitArray && playKitArray.length > 0 &&
                        < PlayKitButton
                            playKitArray={playKitArray}
                            handleRemovePlayKit={handleRemovePlayKit}
                            marginTop={"25px"}
                        />
                    } */}
        </div>
      )}
      {
        /* create && */ playKitArray && playKitArray.length > 0 && (
          <PlayKitButton playKitArray={playKitArray} handleRemovePlayKit={handleRemovePlayKit} marginTop={'0px'} />
        )
      }
      {formErrors[name] && (
        <div className="add-content-form-error" style={{ marginTop: '5px' }}>
          {formErrors[name]}
        </div>
      )}
    </>
  );
};

export default MultiSelectDropDown;
