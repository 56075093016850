import React from 'react';
import './Settings.css';
import SideBar from '../../components/SideBar/SideBar';
import SettingsMainBar from './SettingsMainBar/SettingsMainBar';

const Settings = (props) => {
  return (
    <div className="settings">
      <div className="settings__bar-container">
        <SideBar />
        <SettingsMainBar />
      </div>
    </div>
  );
};

export default Settings;
