/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './ActivityStats.css';
import ActivityStackStatsInfo from './../ActivityStackStatsInfo/ActivityStackStatsInfo';
import ActivityPieChart from '../Charts/ActivityPieCharts';
import Practice from '../../Assets/ActivityStack/practice.png';
import Learning from '../../Assets/ActivityStack/learning.png';
import Easy from '../../Assets/ActivityStack/easy.png';
import Moderate from '../../Assets/ActivityStack/moderate.png';
import Advanced from '../../Assets/ActivityStack/advanced.png';
import Team from '../../Assets/ActivityStack/team.png';
import Individual from '../../Assets/ActivityStack/individual.png';
import Physical from '../../Assets/ActivityStack/physical.png';
import Digital from '../../Assets/ActivityStack/digital.png';
import Phygital from '../../Assets/ActivityStack/phygital.png';
import Worksheet from '../../Assets/ActivityStack/work-sheet.png';

const ActivityStats = (props) => {
  const { activityTableData /* activitySummary */ } = props;
  const [activity_mode_chartData, setActivityModeChartData] = useState([]);
  const [activity_type_chartData, setActivityTypeChartData] = useState([]);
  const [activity_category_chartData, setActivityCategoryChartData] = useState([]);
  const [difficulty_level_chartData, setDifficultyLevelChartData] = useState([]);
  const [activityModeColorArray, setActivityModeColorArray] = useState([]);
  const [difficultyLevelColorArray, setDifficultyLevelColorArray] = useState([]);
  const [activityTypeColorArray, setActivityTypeColorArray] = useState([]);
  const [activityCategoryColorArray, setActivityCategoryColorArray] = useState([]);

  const colorCodingArray = [
    { name: 'Learning', color: '#27AE60' },
    { name: 'Practice', color: '#F2994A' },
    { name: 'Easy', color: '#F2C94C' },
    { name: 'Moderate', color: '#9B51E0' },
    { name: 'Advanced', color: '#2D9CDB' },
    { name: 'Team', color: '#F2994A' },
    { name: 'Individual', color: '#219653' },
    { name: 'Physical', color: '#EB5757' },
    { name: 'Digital', color: '#9B51E0' },
    { name: 'Phygital', color: '#F2994A' },
    { name: 'Worksheet Activity', color: '#56CCF2' },
  ];

  const assaignColorArray = async (countObj, prop_value) => {
    let resultantColorArray = [];

    for (const keys in countObj) {
      for (let index = 0; index < colorCodingArray.length; index++) {
        const element = colorCodingArray[index];
        // eslint-disable-next-line
        if (keys == element['name']) {
          resultantColorArray.push(element['color']);
        }
      }
    }

    if (prop_value === 'activity_mode_name') {
      return setActivityModeColorArray(resultantColorArray);
    } else if (prop_value === 'activity_type_name') {
      return setActivityTypeColorArray(resultantColorArray);
    } else if (prop_value === 'activity_category_name') {
      return setActivityCategoryColorArray(resultantColorArray);
    } else if (prop_value === 'difficulty_level_name') {
      return setDifficultyLevelColorArray(resultantColorArray);
    } else {
      return null;
    }
  };

  const seggregateData = async (activityTableData, prop_value) => {
    let countObj = activityTableData.reduce((res, value) => {
      let propertyName = `${value.activity_details[prop_value]}`;
      if (res && res[propertyName]) {
        res[propertyName] += 1;
      } else {
        res[propertyName] = 1;
      }
      return res;
    }, {});
    // console.log('countObj', countObj);
    await assaignColorArray(countObj, prop_value);

    let finalChartData = [];
    for (const keys in countObj) {
      let newObj = {};
      newObj[keys] = countObj[keys];
      finalChartData.push(newObj);
    }

    if (prop_value === 'activity_mode_name') {
      return setActivityModeChartData(finalChartData);
    } else if (prop_value === 'activity_type_name') {
      return setActivityTypeChartData(finalChartData);
    } else if (prop_value === 'activity_category_name') {
      return setActivityCategoryChartData(finalChartData);
    } else if (prop_value === 'difficulty_level_name') {
      return setDifficultyLevelChartData(finalChartData);
    } else {
      return null;
    }
  };

  useEffect(() => {
    const initiateSeggregate = () => {
      seggregateData(activityTableData, 'activity_mode_name');
      seggregateData(activityTableData, 'activity_type_name');
      seggregateData(activityTableData, 'activity_category_name');
      seggregateData(activityTableData, 'difficulty_level_name');
    };
    try {
      initiateSeggregate();
    } catch (err) {
      console.log(err);
      throw err;
    }
  }, [activityTableData]);

  // console.log({ activity_category_chartData, activity_mode_chartData, activity_type_chartData, difficulty_level_chartData });
  return (
    <div className="chart-container">
      <div className="chart-container__chart">
        <div className="chart-description">
          <ActivityStackStatsInfo name="Learning" src={Learning} alt="learning activity" color={'#27AE60'} />
          <ActivityStackStatsInfo name="Practice" src={Practice} alt="practice activity" color={'#F2994A'} />
        </div>
        <ActivityPieChart chartData={activity_mode_chartData} backgroundColorArr={activityModeColorArray} />
      </div>

      <div className="chart-container__chart">
        <div className="chart-description">
          <ActivityStackStatsInfo name="Easy" src={Easy} alt="easy activity" color={'#F2C94C'} />
          <ActivityStackStatsInfo name="Moderate" src={Moderate} alt="moderate activity" color={'#9B51E0'} />
          <ActivityStackStatsInfo name="Advanced" src={Advanced} alt="advanced activity" color={'#2D9CDB'} />
        </div>
        <ActivityPieChart chartData={difficulty_level_chartData} backgroundColorArr={difficultyLevelColorArray} />
      </div>

      <div className="chart-container__chart">
        <div className="chart-description">
          <ActivityStackStatsInfo name="Team" src={Team} alt="team activity" color={'#F2994A'} />
          <ActivityStackStatsInfo name="Individual" src={Individual} alt="individual activity" color={'#27AE60'} />
        </div>
        <ActivityPieChart chartData={activity_category_chartData} backgroundColorArr={activityCategoryColorArray} />
      </div>

      <div className="chart-container__chart">
        <div className="chart-description">
          <ActivityStackStatsInfo name="Physical" src={Physical} alt="physical activity" color={'#EB5757'} />
          <ActivityStackStatsInfo name="Digital" src={Digital} alt="digital activity" color={'#9B51E0'} />
          <ActivityStackStatsInfo name="Phygital" src={Phygital} alt="phygital activity" color={'#F2994A'} />
          <ActivityStackStatsInfo name="Worksheet" src={Worksheet} alt="worksheet activity" color={'#56CCF2'} />
        </div>
        <ActivityPieChart chartData={activity_type_chartData} backgroundColorArr={activityTypeColorArray} />
      </div>
    </div>
  );
};

export default ActivityStats;
