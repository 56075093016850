/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './ExplainerVideosTable.css';
import TableControlButtons from './../TableControlButtons/TableControlButtons';
import sortArrowDown from '../../Assets/Table/TableHeader/sort-arrow-down.png';
import sortArrowUp from '../../Assets/Table/TableHeader/sort-arrow-up.png';
import NoDataFound from '../../Assets/NoDataFound.png';
import {
  filteredExplainerVideoTable,
  deleteExplainerVideo,
  fetchExplainerVideosCount,
  fetchVideoDetail,
} from '../../api/explainer_video_table';

const ExplainerVideosTable = (props) => {
  const {
    openModal,
    handleModalOpenClose,
    explainerVideoTableData,
    explainerVideosCount,
    setExplainerVideoTableData,
    limit,
    offset,
    board_id,
    course_id,
    subject_id,
    audio_language_id,
    subtitle_language_id,
    searchTerm,
    setExplainerVideosCount,
    toggleEditComponent,
    setToggleEditComponent,
    currentPage,
    setOffset,
    setCurrentPage,
  } = props;

  const [checkAll, setCheckAll] = useState(false);
  const [render, setRender] = useState(false);
  const [videoDetail, setVideoDetail] = useState({});
  const [editVideoId, setEditVideoId] = useState('');
  const listRef = useRef();
  const initialRender = useRef(false);

  const columns = [
    { column_key: 'id', columnName: 'ID', hasSort: true, columnType: 'text' },
    { column_key: 'title', columnName: 'Title', hasSort: true, columnType: 'text' },
    { column_key: 'board', columnName: 'Board', hasSort: true, columnType: 'text' },
    { column_key: 'course', columnName: 'Course', hasSort: true, columnType: 'text' },
    { column_key: 'subject', columnName: 'Subject', hasSort: true, columnType: 'text' },
    { column_key: 'audio', columnName: 'Audio', hasSort: true, columnType: 'text' },
    { column_key: 'subtitle', columnName: 'Subtitle', hasSort: true, columnType: 'text' },
    { column_key: 'duration_in_min', columnName: 'Duration', hasSort: true, columnType: 'text' },
    { column_key: 'usage', columnName: 'Usage', hasSort: true, columnType: 'text' },
  ];

  useEffect(() => {
    setOffset(limit * (currentPage - 1));
  }, [currentPage]);

  useEffect(() => {
    if (initialRender.current && listRef.current && explainerVideoTableData.length > 10) {
      const { clientHeight, scrollHeight } = listRef.current;
      const percentageFactor = (explainerVideoTableData.length - 10) / explainerVideoTableData.length;
      listRef.current.scrollTo({ top: scrollHeight * percentageFactor - clientHeight * 0.85, behaviour: 'smooth' });
    } else {
      initialRender.current = true;
    }
  }, [currentPage, JSON.stringify(explainerVideoTableData.length)]);

  useEffect(() => {
    const initiateReRender = async () => {
      setExplainerVideoTableData(
        await filteredExplainerVideoTable(
          limit,
          offset,
          board_id,
          course_id,
          subject_id,
          audio_language_id,
          subtitle_language_id,
          searchTerm,
        ),
      );
      setExplainerVideosCount(
        await fetchExplainerVideosCount(
          limit,
          offset,
          board_id,
          course_id,
          subject_id,
          audio_language_id,
          subtitle_language_id,
          searchTerm,
        ),
      );
    };
    if (render) {
      try {
        initiateReRender();
      } catch (err) {
        console.log('err in explainer video', err);
        if (err && err.message) toast.error(err.message);
        if (err && err.response.status === 400 && err.response.data && err.response.data.type === 'TOKEN_ERR') {
          toast.info('Session expired, Please login once again');
          setTimeout(() => {
            return (window.location.href = '/login');
          }, 3000);
        }
      }
    }
  }, [render, explainerVideosCount]);

  const eraseContent = async () => {
    explainerVideoTableData.forEach(async (obj) => {
      if (obj.checked) {
        await deleteExplainerVideo(+obj.id);
        setRender(!render);
      }
    });
    try {
      setExplainerVideoTableData(
        await filteredExplainerVideoTable(
          limit,
          offset,
          board_id,
          course_id,
          subject_id,
          audio_language_id,
          subtitle_language_id,
          searchTerm,
        ),
      );
      toast.success('Video removed successfully!');
    } catch (err) {
      console.log('err in explainer video', err);
      if (err && err.message) toast.error(err.message);
      if (err && err.response.status === 400 && err.response.data && err.response.data.type === 'TOKEN_ERR') {
        toast.info('Session expired, Please login once again');
        setTimeout(() => {
          return (window.location.href = '/login');
        }, 3000);
      }
    }
  };

  const handleRemoveVideo = async () => {
    confirmAlert({
      // title: 'Confirm to submit',
      message: 'Are you sure you want to remove this explainer video?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => eraseContent(),
        },
        {
          label: 'No',
        },
      ],
    });
  };

  const handleCheckBoxClick = (e) => {
    let checked = e.target.checked;
    let selectedVideoId = e.target.value;
    let trueArray = explainerVideoTableData.map((obj) => {
      // eslint-disable-next-line
      if (+selectedVideoId == obj.id) {
        return { ...obj, checked: checked };
      } else {
        return obj;
      }
    });
    setExplainerVideoTableData(trueArray);
  };

  const handleCheckAll = async (e) => {
    let checked = e.target.checked;
    setCheckAll(checked);
    let trueArray = explainerVideoTableData.map((obj) => {
      return { ...obj, checked: checked };
    });
    await setExplainerVideoTableData(trueArray);
    if (explainerVideoTableData.length === 0) {
      setCheckAll(!checkAll);
    }
  };

  const handleRowClick = async (video_id) => {
    setVideoDetail(await fetchVideoDetail(video_id));
    setEditVideoId(video_id);
    setToggleEditComponent(!toggleEditComponent);
    handleModalOpenClose(video_id);
  };

  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      const isNearBottom = scrollHeight - scrollTop - 5 < clientHeight;
      if (isNearBottom && offset <= explainerVideosCount - limit) {
        setCurrentPage(currentPage + 1);
        listRef.current.scrollTo({ top: 0, behaviour: 'smooth' });
      }
    }
  };

  return (
    <div className="explainer-video-table">
      <TableControlButtons
        count={explainerVideosCount}
        addName="+ Add Video"
        removeName="Remove Video"
        link={null}
        openModal={openModal}
        handleModalOpenClose={handleModalOpenClose}
        modalName="AddExplainerVideoModal"
        handleRemove={handleRemoveVideo}
        videoDetail={videoDetail}
        editVideoId={editVideoId}
        toggleEditComponent={toggleEditComponent}
        render={render}
        setRender={setRender}
      />
      <div className="table-responsive" onScroll={handleScroll} ref={listRef}>
        <table className="explainer-video-table-display" style={{ width: '100%', height: '100%' }}>
          <thead>
            <tr style={{ display: !explainerVideoTableData?.length && 'inline-table', zIndex: '1' }}>
              <td>
                <input type="checkbox" onChange={handleCheckAll} value={checkAll} checked={checkAll} />
              </td>
              {columns.map((ele, index) => {
                return (
                  <td key={index}>
                    <div className="explainer-video-table-column-heading">
                      <div className="explainer-video-table-column-heading-text">{ele.columnName}</div>
                      <div className="sort__arrows">
                        <img
                          src={sortArrowUp}
                          alt="sort ascending order"
                          // onClick={() => {
                          //     try {
                          //         let newArray = _.orderBy(sortData, [ele.column_key], ["asc"])
                          //         setTableData(newArray)
                          //     } catch (err) {
                          //         console.log(err)
                          //     }
                          // }}
                        />
                        <img
                          src={sortArrowDown}
                          alt="sort descending order"
                          //  onClick={() => {
                          //     try {
                          //         let newArray = _.orderBy(sortData, [ele.column_key], ["desc"])
                          //         setTableData(newArray)
                          //     } catch (err) {
                          //         console.log(err)
                          //     }
                          // }}
                        />
                      </div>
                    </div>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {explainerVideoTableData && explainerVideoTableData.length > 0 ? (
              explainerVideoTableData.map((obj, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        id={obj.id}
                        value={obj.id}
                        onChange={handleCheckBoxClick}
                        checked={obj.checked}
                        style={{ marginLeft: '8px' }}
                      />
                    </td>
                    <td onClick={() => handleRowClick(obj.id)}>{obj.id}</td>
                    <td onClick={() => handleRowClick(obj.id)}>
                      <img
                        src={obj.thumbnail_url}
                        style={{ marginRight: '20px' }}
                        className="explainer-video-table__title-thumbnail"
                        alt="thumbnail"
                      />
                      <span>{obj.title}</span>
                    </td>
                    <td onClick={() => handleRowClick(obj.id)}>{obj.board_name}</td>
                    <td onClick={() => handleRowClick(obj.id)}>{obj.course_name}</td>
                    <td onClick={() => handleRowClick(obj.id)}>{obj.subject_name}</td>
                    <td onClick={() => handleRowClick(obj.id)}>{obj.audio_language}</td>
                    <td onClick={() => handleRowClick(obj.id)}>
                      {obj.subtitle_language_json.length === 1
                        ? `${obj.subtitle_language_json[0].label}`
                        : `${obj.subtitle_language_json[0].label} + ${obj.subtitle_language_json.length - 1}`}
                    </td>
                    <td onClick={() => handleRowClick(obj.id)}>{obj.duration_min}</td>
                    <td onClick={() => handleRowClick(obj.id)}>{obj.usage}</td>
                  </tr>
                );
              })
            ) : (
              <tr className="explainer-video-table">
                <td className="no-data-block" style={{ width: '99%' }}>
                  <img
                    src={NoDataFound}
                    alt="pic showing no data found message"
                    style={{ width: '300px', marginLeft: '-85px', marginTop: '-10px' }}
                  />
                  <div className="no-data-text">{'No data found , please reset the filter or add data'}</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExplainerVideosTable;
