import https from '../init/https';

// CMDS-CNT-01: Upload Content
export const uploadContent = async (formData) => {
  try {
    let postData = await https.post(`/contents`, formData);
    return postData.data.data;
  } catch (err) {
    throw err;
  }
};

// CMDS-CNT-02:Retrieve Content List Details
export const filteredTable = async (
  board_id,
  course_id,
  subject_id,
  subSubject_id,
  topic_id,
  subTopicId,
  searchTerm,
  modalData,
  limit,
  offset,
) => {
  // console.log("Updating limit, offset inside filteredTable", limit, offset)
  const user_id = localStorage.getItem('user_id');
  let table = {};
  try {
    let condition = [],
      cond = '';
    if (limit) {
      condition.push(`limit=${limit}`);
    }
    condition.push(`offset=${offset}`);
    if (user_id) {
      condition.push(`user_id=${user_id}`);
    }
    if (board_id && +board_id !== -1) {
      condition.push(`board_id=${board_id}`);
    }
    if (course_id && +course_id !== -1) {
      condition.push(`course_id=${course_id}`);
    }
    if (subject_id && +subject_id !== -1) {
      condition.push(`subject_id=${subject_id}`);
    }
    if (subSubject_id && +subSubject_id !== -1) {
      condition.push(`sub_subject_id=${subSubject_id}`);
    }
    if (topic_id && +topic_id !== -1) {
      condition.push(`topic_1_id=${topic_id}`);
    }
    if (subTopicId && +subTopicId !== -1) {
      condition.push(`sub_topic_1_id=${subTopicId}`);
    }
    if (modalData && modalData.objectiveId && +modalData.objectiveId !== -1) {
      condition.push(`objective_ids=${modalData.objectiveId}`);
    }

    if (modalData && modalData.authorId && modalData.authorId !== -1) {
      condition.push(`added_by_user_id=${modalData.authorId}`);
    }

    if (modalData && modalData.fileTypeId && modalData.fileTypeId !== -1) {
      condition.push(`content_file_type_ids=${modalData.fileTypeId}`);
    }

    if (searchTerm && searchTerm !== '') {
      condition.push(`search_text=${searchTerm}`);
    }

    if (modalData && modalData.link_type && modalData.link_type !== '') {
      condition.push(`link_type=${modalData.link_type}`);
    }

    if (modalData && modalData.link_type && modalData.searchTerm !== '') {
      condition.push(`Tile Caption=${modalData.searchTerm}`);
    }

    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }
    console.log('cond fetch content table data', cond);
    table = await https.get(`/contents/search${cond}`);
  } catch (err) {
    console.log(err);
    if (err) {
      throw err;
    }
  }
  return table.data.data;
};

// CMDS-CNT-02-01: Get count of results for CMDS - CNT - 02
export const fetchCount = async (
  board_id,
  course_id,
  subject_id,
  subSubject_id,
  topic_id,
  subTopicId,
  searchTerm,
  modalData,
  limit,
  offset,
) => {
  const user_id = localStorage.getItem('user_id');

  try {
    let condition = [],
      cond = '';
    if (limit) {
      condition.push(`limit=${limit}`);
    }
    if (offset) {
      condition.push(`offset=${offset}`);
    }
    if (user_id) {
      condition.push(`user_id=${user_id}`);
    }
    if (board_id && +board_id !== -1) {
      condition.push(`board_id=${board_id}`);
    }
    if (course_id && +course_id !== -1) {
      condition.push(`course_id=${course_id}`);
    }
    if (subject_id && +subject_id !== -1) {
      condition.push(`subject_id=${subject_id}`);
    }
    if (subSubject_id && subSubject_id !== -1) {
      condition.push(`sub_subject_id=${subSubject_id}`);
    }
    if (modalData && modalData.objectiveId && +modalData.objectiveId !== -1) {
      condition.push(`objective_ids=${modalData.objectiveId}`);
    }

    if (modalData && modalData.authorId && +modalData.authorId !== -1) {
      condition.push(`added_by_user_id=${modalData.authorId}`);
    }

    if (modalData && modalData.fileTypeId && modalData.fileTypeId !== -1) {
      condition.push(`content_file_type_ids=${modalData.fileTypeId}`);
    }

    if (searchTerm && searchTerm !== '') {
      condition.push(`search_text=${searchTerm}`);
    }

    if (modalData && modalData.link_type && modalData.link_type !== '') {
      condition.push(`link_type=${modalData.link_type}`);
    }

    if (modalData && modalData.link_type && modalData.searchTerm !== '') {
      condition.push(`Tile Caption=${modalData.searchTerm}`);
    }
    if (topic_id && topic_id !== -1) {
      condition.push(`topic_1_id=${topic_id}`);
    }
    if (subTopicId && subTopicId !== -1) {
      condition.push(`sub_topic_1_id=${subTopicId}`);
    }
    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }
    // console.log("cond for fetch content count", cond)
    let count = await https.get(`/contents/count_contents${cond}`);
    if (count && count.data && count.data.data) {
      return count.data.data.count;
    } else {
      let err = { message: 'Fetch Content count - Data is not available' };
      throw err;
    }
  } catch (err) {
    console.log(err);
    if (err) {
      throw err;
    }
  }
};

// CMDS-CNT-03: Update content
export const editContent = async (id, formData) => {
  try {
    let editedContent = await https.put(`contents/${id}`, formData);
    return editedContent.data.data;
  } catch (err) {
    throw err;
  }
};

// CMDS-CNT-04: Delete Content
export const deleteContent = async (id) => {
  try {
    let deleteContentId = await https.delete(`/contents/${id}`);
    if (deleteContentId.status === 200) {
      return id;
    }
  } catch (err) {
    throw err;
  }
};

// CMDS-CNT-05: Retrieve Content Details
export const fetchContentDetails = async (id) => {
  try {
    let contentDetails = await https.get(`/contents/?content_id=${id}`);
    // console.log("contentDetails.data.data", contentDetails.data.data)
    if (contentDetails && contentDetails.data && contentDetails.data.data) {
      return contentDetails.data.data;
    } else {
      let err = { message: 'Fetch Individual Content detail - Data not found' };
      throw err;
    }
  } catch (err) {
    if (err) {
      throw err;
    }
  }
};

// CMDS-CNT-06: Retrieve the History of Contents
export const fetchContentHistory = async (id) => {
  try {
    let contentHistory = await https.get(`/contents/content_history?content_id=${id}`);
    // console.log("contentHistory.data.data", contentHistory.data.data)
    if (contentHistory && contentHistory.data && contentHistory.data.data) {
      return contentHistory.data.data;
    } else {
      let err = { message: 'Fetch Individual Content History  - Data not found' };
      throw err;
    }
  } catch (err) {
    if (err) {
      throw err;
    }
  }
};

// CMDS-CNT-07: Retrieve the User feedbacks of Content
export const fetchContentFeedback = async (id) => {
  try {
    let feedbackData = await https.get(`/contents/content_user_feedbacks?content_id=${id}`);

    if (feedbackData && feedbackData.data && feedbackData.data.data) {
      return feedbackData.data.data;
    } else {
      let err = { message: 'Fetch Individual Content user feedback  - Data not found' };
      throw err;
    }
  } catch (err) {
    if (err) {
      throw err;
    }
  }
};

// CMDS-CNT-08: Delete the user feedback after correct the content
export const deleteContentFeedback = async (id) => {
  let userId = localStorage.getItem('user_id');

  try {
    let deleteContentFeedbackId = await https.delete(`/contents/delete_feedback/${id}?deleted_by_user_id=${userId}`);
    console.log('deleteContentFeedbackId', deleteContentFeedbackId);
  } catch (err) {
    // let error = { message: "Error deleting the feedback" }
    // throw { message: "Error deleting the feedback" }
    throw err;
  }
  return id;
};

// CMDS-STL-APP-05: Add the Session and Assessment for Each Content
export const addSession = async (formData) => {
  console.log('formData', formData);
  try {
    let postData = await https.post(`/session_learner/create_session`, formData);
    console.log('postData', postData);
    // return postData.data.data
  } catch (err) {
    throw err;
  }
};

// CMDS-CNT-13: Retrieve the Data for Content count depends on objectives
export const fetchContentObjectivesCount = async (board_id, course_id, subject_id, subSubject_id, searchTerm, modalData) => {
  const user_id = localStorage.getItem('user_id');

  try {
    let condition = [],
      cond = '';
    if (user_id) {
      condition.push(`user_id=${user_id}`);
    }
    if (board_id && +board_id !== -1) {
      condition.push(`board_id=${board_id}`);
    }
    if (course_id && +course_id !== -1) {
      condition.push(`course_id=${course_id}`);
    }
    if (subject_id && +subject_id !== -1) {
      condition.push(`subject_id=${subject_id}`);
    }
    if (subSubject_id && subSubject_id !== -1) {
      condition.push(`sub_subject_id=${subSubject_id}`);
    }
    if (modalData && modalData.objectiveId && +modalData.objectiveId !== -1) {
      condition.push(`objective_ids=${modalData.objectiveId}`);
    }

    if (modalData && modalData.authorId && +modalData.authorId !== -1) {
      condition.push(`added_by_user_id=${modalData.authorId}`);
    }

    if (modalData && modalData.fileTypeId && modalData.fileTypeId !== -1) {
      condition.push(`content_file_type_ids=${modalData.fileTypeId}`);
    }

    if (searchTerm && searchTerm !== '') {
      condition.push(`search_text=${searchTerm}`);
    }

    if (modalData && modalData.link_type && modalData.link_type !== '') {
      condition.push(`link_type=${modalData.link_type}`);
    }

    if (modalData && modalData.link_type && modalData.searchTerm !== '') {
      condition.push(`Tile Caption=${modalData.searchTerm}`);
    }

    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }
    // console.log("cond for fetch objectives count", cond)
    let count = await https.get(`/contents/objectives/count${cond}`);
    // console.log("objectives count", count?.data?.data)
    if (count?.data?.data) {
      return count.data.data;
    } else {
      let err = { message: 'Fetch Content count - Data is not available' };
      throw err;
    }
  } catch (err) {
    console.log(err);
    if (err) {
      throw err;
    }
  }
};

// CMDS-CNT-11: Retrieve the Data for Content library Multimedia pie chart
export const fetchMultimediaChartData = async (board_id, course_id, subject_id, subSubject_id, searchTerm, modalData) => {
  const user_id = localStorage.getItem('user_id');

  try {
    let condition = [],
      cond = '';
    if (user_id) {
      condition.push(`user_id=${user_id}`);
    }
    if (board_id && +board_id !== -1) {
      condition.push(`board_id=${board_id}`);
    }
    if (course_id && +course_id !== -1) {
      condition.push(`course_id=${course_id}`);
    }
    if (subject_id && +subject_id !== -1) {
      condition.push(`subject_id=${subject_id}`);
    }
    if (subSubject_id && subSubject_id !== -1) {
      condition.push(`sub_subject_id=${subSubject_id}`);
    }
    if (modalData && modalData.objectiveId && +modalData.objectiveId !== -1) {
      condition.push(`objective_ids=${modalData.objectiveId}`);
    }

    if (modalData && modalData.authorId && +modalData.authorId !== -1) {
      condition.push(`added_by_user_id=${modalData.authorId}`);
    }

    if (modalData && modalData.fileTypeId && modalData.fileTypeId !== -1) {
      condition.push(`content_file_type_ids=${modalData.fileTypeId}`);
    }

    if (searchTerm && searchTerm !== '') {
      condition.push(`search_text=${searchTerm}`);
    }

    if (modalData && modalData.link_type && modalData.link_type !== '') {
      condition.push(`link_type=${modalData.link_type}`);
    }

    if (modalData && modalData.link_type && modalData.searchTerm !== '') {
      condition.push(`Tile Caption=${modalData.searchTerm}`);
    }

    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }
    // console.log("cond for fetchMultimediaChartData", cond)
    let multimediaChartData = await https.get(`/contents/multimedia/objectives/count${cond}`);
    // console.log("fetchMultimediaChartData", multimediaChartData?.data?.data)
    if (multimediaChartData?.data?.data) {
      return multimediaChartData.data.data;
    } else {
      let err = { message: 'Fetch Content count - Data is not available' };
      throw err;
    }
  } catch (err) {
    console.log(err);
    if (err) {
      throw err;
    }
  }
};

// CMDS-CNT-12: Retrieve the Data for Content library Readable pie chart
export const fetchReadableChartData = async (board_id, course_id, subject_id, subSubject_id, searchTerm, modalData) => {
  const user_id = localStorage.getItem('user_id');

  try {
    let condition = [],
      cond = '';
    if (board_id && +board_id !== -1) {
      condition.push(`board_id=${board_id}`);
    }
    if (course_id && +course_id !== -1) {
      condition.push(`course_id=${course_id}`);
    }
    if (subject_id && +subject_id !== -1) {
      condition.push(`subject_id=${subject_id}`);
    }
    if (subSubject_id && subSubject_id !== -1) {
      condition.push(`sub_subject_id=${subSubject_id}`);
    }
    if (modalData && modalData.objectiveId && +modalData.objectiveId !== -1) {
      condition.push(`objective_ids=${modalData.objectiveId}`);
    }

    if (modalData && modalData.authorId && +modalData.authorId !== -1) {
      condition.push(`added_by_user_id=${modalData.authorId}`);
    }

    if (modalData && modalData.fileTypeId && modalData.fileTypeId !== -1) {
      condition.push(`content_file_type_ids=${modalData.fileTypeId}`);
    }

    if (searchTerm && searchTerm !== '') {
      condition.push(`search_text=${searchTerm}`);
    }

    if (modalData && modalData.link_type && modalData.link_type !== '') {
      condition.push(`link_type=${modalData.link_type}`);
    }

    if (modalData && modalData.link_type && modalData.searchTerm !== '') {
      condition.push(`Tile Caption=${modalData.searchTerm}`);
    }

    if (user_id) {
      condition.push(`user_id=${user_id}`);
    }

    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }
    // console.log("cond for fetchReadableChartData", cond)
    let readableChartData = await https.get(`/contents/readable/objectives/count${cond}`);
    // console.log("fetchReadableChartData", readableChartData?.data?.data)
    if (readableChartData?.data?.data) {
      return readableChartData.data.data;
    } else {
      let err = { message: 'Fetch Content count - Data is not available' };
      throw err;
    }
  } catch (err) {
    console.log(err);
    if (err) {
      throw err;
    }
  }
};
