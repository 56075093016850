import React from 'react';
import './ToggleBox.css';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';

const ToggleBox = (props) => {
  const {
    label_1,
    label_2,
    is_min_recommended,
    retriveToggleValue,
    font_selected_true,
    font_selected_false,
    color_selected_true,
    color_selected_false,
  } = props;

  return (
    <div className="toggle-box">
      <label
        className="toggle-box__label"
        style={{
          fontWeight: is_min_recommended === false ? `${font_selected_true}` : `${font_selected_false}`,
          color: is_min_recommended === false ? `${color_selected_true}` : `${color_selected_false}`,
        }}
      >
        {label_1}
      </label>

      <ToggleSwitch is_min_recommended={is_min_recommended} retriveToggleValue={retriveToggleValue} />

      <label
        className="toggle-box__label"
        style={{
          fontWeight: is_min_recommended === true ? `${font_selected_true}` : `${font_selected_false}`,
          color: is_min_recommended === true ? `${color_selected_true}` : `${color_selected_false}`,
        }}
      >
        {label_2}
      </label>
    </div>
  );
};

export default ToggleBox;
