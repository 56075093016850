import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './MoreFilters.css';
import DropDown from '../DropDown/DropDown';
import CheckBoxGroup from '../CheckBoxGroup/CheckBoxGroup';
import TileSearch from '../TileSearch/TileSearch';
import FilterButtons from '../FilterButtons/FilterButtons';
import CheckBox from '../CheckBox/CheckBox';

const customStyles = {
  content: {
    position: 'relative',
    left: '50%',
    display: 'inline-block',
    width: '340px',
    height: '520px',
    background: '#FFFFFF',
    borderRadius: '16px',
    transform: 'translate(-50%, 0%)',
    overflow: 'hidden scroll',
  },
};

Modal.setAppElement('#root');

const ModalComponent = (props) => {
  const {
    openModal,
    handleModalOpenClose,
    modalData,
    setModalData,
    authors,
    objective,
    fileType,
    setAuthors,
    setObjective,
    setFileType,
    setOffset,
    initialRender,
  } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [authorId, setAuthorId] = useState(modalData.authorId);
  const [searchText, setSearchText] = useState(modalData.searchTerm);
  const [objectiveIds, setObjectiveIds] = useState(modalData.objectiveId);
  const [fileTypeIds, setFileTypeIds] = useState(modalData.fileTypeId);
  const [link_type, setLinkType] = useState(modalData.link_type);

  useEffect(() => {
    const getSelectedValues = () => {
      getSelectedAuthor(modalData, authors);
      getSelectedObjective(modalData, objective);
      getSelectedFileType(modalData, fileType);
      setTheFileType(fileType);
      setTheObjective(objective);
    };
    try {
      getSelectedValues();
    } catch (err) {
      console.log(err);
      throw err;
    }
    // eslint-disable-next-line
  }, [objective, fileType]);

  const getSelectedAuthor = (modalData, authors) => {
    let selectedAuthor = modalData.authorId;

    authors.forEach((obj) => {
      if (obj.user_id === selectedAuthor) {
        obj.selected = true;
      }
    });
    setAuthors(authors);
  };

  const getSelectedObjective = (modalData, objective) => {
    let objectiveId = modalData.objectiveId;
    let objIdArray = objectiveId.split(',');

    for (let index = 0; index < objIdArray.length; index++) {
      const element = objIdArray[index];

      objective.forEach((obj) => {
        // eslint-disable-next-line
        if (obj.objective_id == element) {
          obj.checked = true;
        }
      });
    }
    setObjective(objective);
  };

  const getSelectedFileType = (modalData, fileType) => {
    let fileTypeId = modalData.fileTypeId;
    let fileIdArray = fileTypeId.split(',');

    for (let index = 0; index < fileIdArray.length; index++) {
      const element = fileIdArray[index];
      //content_type_id
      fileType.forEach((obj) => {
        // eslint-disable-next-line
        if (obj.content_type_id == element) {
          obj.checked = true;
        }
      });
    }
    setFileType(fileType);
  };

  const updateUserId = (id) => {
    setAuthorId(id);
  };

  const updateSearchText = (searchTerm) => {
    setSearchText(searchTerm);
  };

  const updateUserValue = (values) => {
    setAuthors(values);
  };

  const setTheObjective = (values) => {
    extractId(values, 'objective_id');
    setObjective(values);
  };

  const setTheFileType = (values) => {
    extractId(values, 'content_type_id');
    setFileType(values);
  };

  const extractId = (values, propValue) => {
    let objectIdArray = [];
    let fileIdArray = [];

    for (let index = 0; index < values.length; index++) {
      const obj = values[index];
      if (obj.hasOwnProperty('checked') && obj.checked) {
        propValue === 'objective_id' ? objectIdArray.push(obj[propValue]) : fileIdArray.push(obj[propValue]);
      }
    }
    if (propValue === 'objective_id') {
      setObjectiveIds(objectIdArray.join(','));
    } else {
      setFileTypeIds(fileIdArray.join(','));
    }
  };

  const toggleChecked = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setLinkType('ALL');
    } else {
      setLinkType('FILE');
    }
  };

  const modalOpenClose = () => {
    setModalIsOpen(!modalIsOpen);
    handleModalOpenClose();
  };

  const handleModalApply = () => {
    setModalData({
      ...modalData,
      authorId: Number(authorId ?? -1),
      searchTerm: searchText,
      objectiveId: objectiveIds,
      fileTypeId: fileTypeIds,
      link_type: link_type,
    });
    setOffset(0);
    initialRender.current = false;
    modalOpenClose();
  };

  return (
    <div>
      <Modal isOpen={openModal} onRequestClose={modalOpenClose} style={customStyles} contentLabel="More Filters">
        <div /* className="modal" */>
          <div className="modal__header-text">Filter</div>
          <div>
            <hr className="modal__header-line"></hr>
          </div>
          <DropDown
            name="Author"
            default_option="Author Name"
            withinModal={true}
            values={authors}
            id_name="user_id"
            display_name="full_name"
            updateUserId={updateUserId}
            updateUserValue={updateUserValue}
            handleModalApply={handleModalApply}
          />
          <TileSearch
            name={null}
            formErrors={null}
            withinModal={true}
            label={null}
            placeholder="Tile Caption"
            tileSearch={searchText}
            setTileSearch={setSearchText}
            updateSearchText={updateSearchText}
          />

          <div className="modal-checkbox">
            <CheckBoxGroup
              values={objective}
              category="Objective"
              name="objective"
              label="objective"
              inputValue="objective_id"
              setValues={setTheObjective}
            />
            <CheckBoxGroup
              values={fileType}
              category="File Type"
              name="fileType"
              label="content_type"
              inputValue="content_type_id"
              marginTop={'20px'}
              setValues={setTheFileType}
            />

            <div className="checkbox-category" style={{ marginBottom: '8px', marginTop: '20px' }}>
              {'Others'}
            </div>

            <CheckBox
              id={3}
              name={'all'}
              value={link_type}
              onChanged={toggleChecked}
              checked={link_type === 'ALL' ? true : false}
              label={'Show Web links also'}
            />
          </div>
        </div>
        <div>
          <hr className="modal__header-line" style={{ marginTop: '10px' }}></hr>
        </div>
        <FilterButtons handleModalApply={handleModalApply} modalOpenClose={modalOpenClose} apply={'Apply'} />
      </Modal>
    </div>
  );
};

export default ModalComponent;
