import React from 'react';
import SideBar from '../../../components/SideBar/SideBar';
import AddPlayKit from './AddPlayKit/AddPlayKit';
import './UploadPlayKit.css';

const UploadPlayKit = (props) => {
  return (
    <div className="upload-playKit-main-page">
      <div className="upload-playKit-main-page__bar-container">
        <SideBar />
        <AddPlayKit />
      </div>
    </div>
  );
};

export default UploadPlayKit;
