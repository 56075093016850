import React from 'react';
import './TableControlButtons.css';
import { Link } from 'react-router-dom';
import AddExplainerVideoModal from './../AddExplainerVideoModal/AddExplainerVideoModal';

const TableControlButtons = (props) => {
  const {
    count,
    link,
    addName,
    removeName,
    handleRemove,
    openModal,
    handleModalOpenClose,
    modalName,
    videoDetail,
    editVideoId,
    toggleEditComponent,
    render,
    setRender,
  } = props;

  return (
    <div className="table-control">
      <div className="table-control__count">{count && (count === 0 || count === 1) ? `${count} record` : `${count} records`}</div>
      <div className={modalName ? 'table-control__buttons--with-modal' : 'table-control__buttons'}>
        {modalName === 'AddExplainerVideoModal' ? (
          <button className="add-content-btn" onClick={() => handleModalOpenClose(null)}>
            <div className="add-content-btn-text">{addName}</div>
          </button>
        ) : (
          <Link to={link} style={{ textDecoration: 'none' }}>
            <button className="add-content-btn">
              <div className="add-content-btn-text">{addName}</div>
            </button>
          </Link>
        )}
        <button className="remove-content-btn">
          <div className="remove-content-btn-text" onClick={handleRemove}>
            {removeName}
          </div>
        </button>
      </div>
      {openModal && (
        <AddExplainerVideoModal
          openModal={openModal}
          handleModalOpenClose={handleModalOpenClose}
          videoDetail={videoDetail}
          editVideoId={editVideoId}
          toggleEditComponent={toggleEditComponent}
          render={render}
          setRender={setRender}
        />
      )}
    </div>
  );
};

export default TableControlButtons;
