import React, { useState } from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const customStyles = {
  content: {
    position: 'relative',
    left: '50%',
    display: 'inline-block',
    width: '640px',
    height: '360px',
    background: 'none',
    border: 'none',
    transform: 'translate(-50%, 0%)',
  },
  overlay: {
    backgroundColor: 'rgba(24, 18, 38, 0.5)',
    zIndex: '3',
  },
};

const VideoPlayer = (props) => {
  const { openModal, handleModalOpenClose, url } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  console.log('url', url);
  const modalOpenClose = () => {
    setModalIsOpen(!modalIsOpen);
    handleModalOpenClose();
  };

  const testCloseOnError = () => {
    if (url) {
      toast.error('Cannot play preview for this video');
      setTimeout(() => {
        modalOpenClose();
      }, 3000);
    } else {
      modalOpenClose();
      toast.error('Cannot play video due to invalid url');
    }
  };

  return (
    <>
      <Modal isOpen={openModal} onRequestClose={modalOpenClose} style={customStyles} contentLabel="Video Player Modal">
        <ReactPlayer
          url={url}
          controls={true}
          onError={testCloseOnError}
          width={'100%'}
          height={'100%'}
          playing={true}
          pip={true}
          stopOnUnmount={false}
        />
      </Modal>
    </>
  );
};

export default VideoPlayer;
