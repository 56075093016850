import React from 'react';
import './ActivityStackExplainerVideo.css';

const ActivityStackExplainerVideo = (props) => {
  const { heading, duration, title, id } = props;

  return (
    <>
      <div className="heading">{heading}</div>
      <div className="explainer-video-title">{title ? title : 'Explainer Video Title : NA'}</div>
      <div className="explainer-video-id">{id ? id : 'Explainer Video Id : NA'}</div>
      <div className="explainer-video-duration">
        {duration ? (duration >= 1 ? `${duration} mins` : `${duration} min`) : '0 min'}
      </div>
    </>
  );
};

export default ActivityStackExplainerVideo;
