// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/ContentLibrary/ContentLibrary/MainBar/dropdown.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".explainer-video-selection-modal {\n    position: relative;\n    width: 100%;\n    height: 40px;\n    background: #F2F2F2;\n    border-radius: 8px;\n    margin-top: 70px;\n    font-family: \"Inter\";\n    appearance: none;\n    margin-right: 18px;\n    padding-left: 16px;\n    background: #f2f2f2 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 96% 50%;\n    border: none;\n    outline: none;\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 17px;\n    color: #4F4F4F;\n}", "",{"version":3,"sources":["webpack://./src/components/AddExplainerVideoSelection/AddExplainerVideoSelection.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,oBAAoB;IAGpB,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,6EAA4G;IAC5G,YAAY;IACZ,aAAa;IACb,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".explainer-video-selection-modal {\n    position: relative;\n    width: 100%;\n    height: 40px;\n    background: #F2F2F2;\n    border-radius: 8px;\n    margin-top: 70px;\n    font-family: \"Inter\";\n    -webkit-appearance: none;\n    -moz-appearance: none;\n    appearance: none;\n    margin-right: 18px;\n    padding-left: 16px;\n    background: #f2f2f2 url(\"../../Assets/ContentLibrary/ContentLibrary/MainBar/dropdown.png\") no-repeat 96% 50%;\n    border: none;\n    outline: none;\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 17px;\n    color: #4F4F4F;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
