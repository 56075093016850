import React, { useState } from 'react';
import './ResetPassword.css';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import ResetPasswordForm from '../../../components/ResetPasswordForm/ResetPasswordForm';
import SuccessMessage from '../../../components/LoginSuccess/LoginSuccess';
import PersonRight from '../../../Assets/Login/ResetPasswordScreen/person-right.png';

const ResetPassword = (props) => {
  const [toggleForm, setToggleForm] = useState(false);

  const handleToggle = () => {
    setToggleForm(!toggleForm);
  };

  return (
    <div className="reset-password-page">
      <Header />
      <div className="reset-password-page__form">
        <div className="reset-password__left">
          <div className="reset-password-form-left">
            <p id="reset-password-text">Reset Password</p>
            <p className="reset-password-box-description" style={{ color: `rgba(${60}, ${56}, ${56}, ${1})` }}>
              Please reset the password for security reasons or if you cannot remember it. We will send you a link to your
              registered email id. Click on the link, reset the password and you are ready to go again!
            </p>
            {toggleForm ? <SuccessMessage sendMail={true} /> : <ResetPasswordForm handleToggle={handleToggle} />}
          </div>
        </div>
        <div className="reset-password-form-right">
          <img src={PersonRight} alt="Person demonstration" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPassword;
