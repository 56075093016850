import https from '../init/https';

// CMDS-MD-01: Retrieve list of all boards
export const fetchBoards = async () => {
  // const institution_id = localStorage.getItem('institution_id');

  try {
    let cond = '',
      condition = [];
    // condition.push(`institution_id=${institution_id}`)
    condition.push(`institution_id=0`);

    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }
    let getBoards = await https.get(`master_data/boards${cond}`);
    return getBoards.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-MD-02: Retrieve list of Courses
export const fetchCourse = async (board_id) => {
  // const institution_id = localStorage.getItem('institution_id');

  try {
    let cond = '',
      condition = [];

    // condition.push(`institution_id=${institution_id}`)
    condition.push(`institution_id=0`);
    condition.push(`board_id=${board_id}`);

    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }
    let getCourses = await https.get(`master_data/courses${cond}`);
    return getCourses.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-MD-03: Retrieve list of Subjects
export const fetchSubjects = async (board_id, course_id) => {
  // const institution_id = localStorage.getItem('institution_id');

  try {
    let cond = '',
      condition = [];

    // condition.push(`institution_id=${institution_id}`)
    condition.push(`institution_id=0`);
    condition.push(`board_id=${board_id}`);
    condition.push(`course_id=${course_id}`);

    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }
    let getSubject = await https.get(`master_data/subjects${cond}`);
    return getSubject.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-MD-04: Retrieve list of Sub_Subjects
export const fetchSubSubjects = async (board_id, course_id, subject_id) => {
  // const institution_id = localStorage.getItem('institution_id');

  try {
    let cond = '',
      condition = [];

    // condition.push(`institution_id=${institution_id}`)
    condition.push(`institution_id=0`);
    condition.push(`board_id=${board_id}`);
    condition.push(`course_id=${course_id}`);
    condition.push(`subject_id=${subject_id}`);

    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }
    let getSubSubjects = await https.get(`master_data/sub_subjects${cond}`);
    return getSubSubjects.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-MD-05: Retrieve list of Topic
export const fetchTopics = async (board_id, course_id, subject_id, sub_subject_id) => {
  // const institution_id = localStorage.getItem('institution_id');

  try {
    let cond = '',
      condition = [];

    // condition.push(`institution_id=${institution_id}`)
    condition.push(`institution_id=0`);
    condition.push(`board_id=${board_id}`);
    condition.push(`course_id=${course_id}`);
    condition.push(`subject_id=${subject_id}`);

    if (sub_subject_id && sub_subject_id !== -1) {
      condition.push(`sub_subject_id=${sub_subject_id}`);
    }

    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }
    let getTopics = await https.get(`master_data/topics${cond}`);
    return getTopics.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-MD-06: Retrieve list of Sub Topic
export const fetchSubTopics = async (board_id, course_id, subject_id, sub_subject_id, topic_id) => {
  // const institution_id = localStorage.getItem('institution_id');

  try {
    let cond = '',
      condition = [];

    // condition.push(`institution_id=${institution_id}`)
    condition.push(`institution_id=0`);
    condition.push(`board_id=${board_id}`);
    condition.push(`course_id=${course_id}`);
    condition.push(`subject_id=${subject_id}`);

    if (sub_subject_id && sub_subject_id !== -1) {
      condition.push(`sub_subject_id=${sub_subject_id}`);
    }
    condition.push(`topic_id=${topic_id}`);

    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }
    let getSubTopics = await https.get(`master_data/sub_topics${cond}`);
    return getSubTopics.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-MD-07: Retrieve List of Objectives
export const fetchObjective = async () => {
  try {
    let getObjectives = await https.get('master_data/objectives');
    return getObjectives.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-MD-09: Retrieve Media List
export const fetchMediaList = async () => {
  try {
    let getMediaList = await https.get('master_data/media');
    return getMediaList.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-MD-10: Retrieve the Author list
export const fetchAuthorList = async () => {
  try {
    let authorList = await https.get(`master_data/users`);
    return authorList.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-MD-11: Retrieve the File type List
export const fetchFileTypeList = async () => {
  try {
    let fileTypeList = await https.get(`master_data/content_file_types`);
    return fileTypeList.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-MD-12: Search the “Tile Caption” field list
export const fetchTileCaption = async (caption) => {
  try {
    let getCaption = await https.get(`master_data/contents/search?search_text=${caption}`);
    return getCaption.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-MD-13: Retrieve the Institution Details
export const fetchInstitution = async (user_id) => {
  try {
    let institute = await https.get(`master_data/institution?user_id=${user_id}`);
    return institute.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-MD-14: Retrieve list of Access Levels
export const fetchRadioButtons = async () => {
  try {
    let radioButtons = await https.get(`master_data/access_levels`);
    return radioButtons.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-MD-15: Retrieve list of international Language and code
export const fetchLanguageList = async () => {
  try {
    let languages = await https.get(`master_data/international_languages`);
    return languages.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-MD-16: Retrieve the List of activity_category
export const fetchActivityCategory = async () => {
  try {
    let activityCategory = await https.get(`master_data/activity_category`);
    return activityCategory.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-MD-17: Retrieve the List of Activity Types
export const fetchActivityType = async () => {
  try {
    let activityType = await https.get(`master_data/activity_types`);
    return activityType.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-MD-18: Retrieve the List of Activity Mode
export const fetchActivityMode = async () => {
  try {
    let activityMode = await https.get(`master_data/activity_modes`);
    return activityMode.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-MD-19: Retrieve the List of Difficulty Levels
export const fetchDifficultyLevels = async () => {
  try {
    let difficultyLevels = await https.get(`master_data/difficulty_levels`);
    return difficultyLevels.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// CMDS-MD-20: Retrieve the List of Playkits
export const fetchPlayKitSuggetions = async (searchText) => {
  try {
    const result = await https.get(`master_data/playkits?search_text=${searchText}`);
    return result.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
