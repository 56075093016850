import React from 'react';
import deleteIcon from '../../Assets/Playkit/delete.svg';
import editIcon from '../../Assets/Playkit/edit.svg';

const ToolCard = (props) => {
  const { tool, handleRemoveTool, handleEditTool, handleTextBoxChange } = props;

  return (
    <div className="playKit-tool-holder__tool">
      <input
        type="checkbox"
        className="playKit-tool-holder__tool-checkbox"
        onChange={handleTextBoxChange}
        checked={tool?.checked ? tool?.checked : false}
        value={tool?.checked ? tool?.checked : ''}
      />
      <img
        // src={toggleEditComponent && tool?.alreadyExisted ? `${tool?.tool_image_url}` : `${tool?.base64String}`}
        // src={tool?.alreadyExisted && !tool?.base64String ? `${tool?.tool_image_url}` : `${tool?.base64String}`}
        // src={`data:${tool?.toolThumbnail?.type};base64,${tool?.tool_image_url}`}
        src={`${tool?.tool_image_url}`}
        alt="tool-thumbnail"
        className="playKit-tool-holder__tool-img"
        loading="eager"
      />
      <div className="playKit-tool-holder__tool-container">
        <div className="playKit-tool-holder__tool-container__name">{tool.tool_name}</div>
        <div>
          <img
            src={editIcon}
            alt="editIcon"
            style={{ marginRight: '10px' }}
            className="playKit-tool-holder__tool-container__controls"
            onClick={handleEditTool}
          />
          <img
            src={deleteIcon}
            alt="deleteIcon"
            className="playKit-tool-holder__tool-container__controls"
            onClick={handleRemoveTool}
          />
        </div>
      </div>
      <div className="playKit-tool-holder__tool-container__description">{tool.tool_description}</div>
    </div>
  );
};

export default ToolCard;
