import React from 'react';
import './ActivityPlayKitHolder.css';

const ActivityPlayKitHolder = (props) => {
  const { contentDetails } = props;

  return (
    <div className="playKit-holder">
      {contentDetails &&
        contentDetails.playkit_json &&
        contentDetails.playkit_json.length > 0 &&
        contentDetails.playkit_json.map((obj) => {
          return (
            <div className="playKit-holder__list" key={obj.id}>
              {obj.name} - {obj.id}
            </div>
          );
        })}
    </div>
  );
};

export default ActivityPlayKitHolder;
