import React, { useState } from 'react';
import Modal from 'react-modal';
import './ContentQuestionTimerModal.css';
import ModalCancel from '../../Assets/ActivityStack/modal-cancel.png';
import { ToastContainer, toast, Bounce } from 'react-toastify';

const customStyles = {
  content: {
    position: 'relative',
    left: '50%',
    display: 'inline-block',
    width: 'auto',
    maxHeight: '520px',
    background: '#FFFFFF',
    borderRadius: '16px',
    transform: 'translate(-50%, -5%)',
  },
};
Modal.setAppElement('#root');

const ContentQuestionTimerModal = (props) => {
  const { handleModalOpenClose, openModal, questionTimerDetails, setQuestionTimerDetails } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  console.log('questionTimerDetails inside ContentQuestionTimerModal', questionTimerDetails);
  const modalOpenClose = () => {
    setModalIsOpen(!modalIsOpen);
    handleModalOpenClose();
  };

  const handleValueChange = (e, index) => {
    let updatedTimerDetails = [...questionTimerDetails];
    updatedTimerDetails[index][e.target.name] = e.target.value;
    console.log('updatedTimerDetails inside', updatedTimerDetails);
    setQuestionTimerDetails(updatedTimerDetails);
  };

  const handleRemove = (e, index) => {
    e.preventDefault();
    // if (questionTimerDetails.length === 1) {
    //     toast.info("You cannot remove the first entry for Question Timer")
    // }
    if (questionTimerDetails.length > 0) {
      let data = [...questionTimerDetails];
      data.splice(index, 1);
      setQuestionTimerDetails(data);
    }
  };

  const handleAddMore = () => {
    let addObject = {
      question_id: '',
      time_min: '',
      time_sec: '',
      duration: '0',
    };
    if (questionTimerDetails?.length === 0) {
      setQuestionTimerDetails([...questionTimerDetails, addObject]);
    } else {
      if (
        [questionTimerDetails.length - 1].time_min !== '' &&
        questionTimerDetails[questionTimerDetails.length - 1].time_sec !== '' &&
        questionTimerDetails[questionTimerDetails.length - 1].question_id !== '' /* &&
            questionTimerDetails[questionTimerDetails.length - 1].duration !== "" */
      ) {
        setQuestionTimerDetails([...questionTimerDetails, addObject]);
      } else {
        toast.error('Fill the required details, then click to add more!');
      }
    }
  };

  const onWheelChange = (e) => {
    e.target.blur();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('questionTimerDetails', questionTimerDetails);
    if (
      questionTimerDetails?.length > 0 &&
      questionTimerDetails[questionTimerDetails.length - 1].time_min !== '' &&
      questionTimerDetails[questionTimerDetails.length - 1].time_sec !== '' &&
      questionTimerDetails[questionTimerDetails.length - 1].question_id !== '' /* &&
            questionTimerDetails[questionTimerDetails.length - 1].duration !== "" */
    ) {
      setQuestionTimerDetails(questionTimerDetails);
      modalOpenClose();
    } else {
      toast.error('Fill the required details, then click save!');
    }
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        onRequestClose={modalOpenClose}
        style={customStyles}
        contentLabel="Content Question Timer Modal"
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
      >
        <div className="question-timer-modal">
          <div className="question-timer-modal__header">
            <div className="question-timer-modal__header-text">Question Timer</div>
            <img src={ModalCancel} alt="close button" style={{ cursor: 'pointer' }} onClick={modalOpenClose} />
          </div>
          <div>
            <hr className="question-timer-modal__seperator" style={{ width: '287px' }}></hr>
          </div>
          <br></br>
          <form onSubmit={handleSubmit}>
            {questionTimerDetails &&
              questionTimerDetails.map((ele, index) => {
                return (
                  <div key={index}>
                    <input
                      name="question_id"
                      type="number"
                      placeholder="Question_ID"
                      value={ele.question_id}
                      className="form-right-text__input-field questionTimerDetails-placeholder"
                      style={{ borderBottom: '1px solid black', width: '100px', textAlign: 'center' }}
                      onChange={(e) => handleValueChange(e, index)}
                      onWheel={onWheelChange}
                    />

                    <input
                      name="time_min"
                      type="number"
                      placeholder="MM"
                      value={ele.time_min}
                      className="form-right-text__input-field questionTimerDetails-placeholder"
                      style={{ borderBottom: '1px solid black', width: '50px', textAlign: 'center', marginLeft: '10px' }}
                      onChange={(e) => handleValueChange(e, index)}
                      min="0"
                      onWheel={onWheelChange}
                    />

                    <span style={{ marginLeft: '5px' }}>:</span>

                    <input
                      name="time_sec"
                      type="number"
                      placeholder="SS"
                      value={ele.time_sec}
                      className="form-right-text__input-field questionTimerDetails-placeholder"
                      style={{ borderBottom: '1px solid black', width: '50px', marginLeft: '5px', textAlign: 'center' }}
                      onChange={(e) => handleValueChange(e, index)}
                      min="0"
                      onWheel={onWheelChange}
                    />

                    {/* <input
                                        name="duration"
                                        type="number"
                                        placeholder="Duration(sec.)"
                                        value={ele.duration}
                                        className="form-right-text__input-field"
                                        style={{ borderBottom: "1px solid black", width: "100px", marginLeft: "20px", textAlign: "center" }}
                                        onChange={(e) => handleValueChange(e, index)}
                                        min="0"
                                    /> */}

                    <button
                      className="remove-content-btn"
                      onClick={(e) => handleRemove(e, index)}
                      style={{ width: '45px', marginLeft: '14px' }}
                    >
                      <div className="remove-content-btn-text" style={{ width: 'auto' }}>
                        X
                      </div>
                    </button>
                  </div>
                );
              })}
          </form>
          <div className="form-submission" style={{ marginTop: '-4px', boxShadow: 'none', background: 'none' }}>
            <button onClick={handleAddMore} className="save-btn" style={{ margin: '0', width: '115px' }}>
              Add More..
            </button>
            <button onClick={handleSubmit} className="save-btn" style={{ margin: '0', width: '115px' }}>
              Save
            </button>
          </div>
        </div>
      </Modal>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />
    </>
  );
};

export default ContentQuestionTimerModal;
