import React from 'react';
import './BrowseFile.css';

const BrowseFile = (props) => {
  const { handleFileUpload, file, handleFileRemove, editContentDetails, toggleEditComponent, width, accept, prop_value, filePath } =
    props;

  // let fileName = ""
  // useEffect(() => {
  //     const findFileName = () => {
  //         fileName = toggleEditComponent ? editContentDetails[prop_value].substring(editContentDetails[prop_value].lastIndexOf("-") + 1) : null
  //     }
  //     findFileName()
  // }, [editContentDetails, editContentDetails[prop_value]])
  let fileName = toggleEditComponent
    ? editContentDetails[prop_value].substring(editContentDetails[prop_value].lastIndexOf('-') + 1)
    : null;

  return (
    <label
      className="attachment-file"
      style={{
        width: width ? '100%' : '510px',
        display: 'block',
        margin: width ? '12px 0 8px 0' : 'null',
      }}
    >
      <input
        type="file"
        id={file ? file.lastModified : null}
        accept={accept}
        onInput={handleFileUpload}
        onClick={(e) => {
          e.target.value = null;
        }}
      />
      {file ? (
        <div
          className="attachment-file__text"
          style={{ justifyContent: prop_value === 'video_url' ? 'space-around' : 'space-between' }}
        >
          <div
            style={{
              marginLeft: prop_value === 'video_url' ? '0px' : '20px',
              width: prop_value === 'video_url' ? '70%' : 'auto',
              wordBreak: 'break-all',
              overflowY: 'auto',
            }}
          >
            {file.name}
          </div>
          <button style={{ marginRight: prop_value === 'video_url' ? '0px' : '20px' }} onClick={handleFileRemove}>
            Remove
          </button>
        </div>
      ) : toggleEditComponent && filePath !== '' && editContentDetails.link_type === 'FILE' ? (
        <div
          className="attachment-file__text"
          style={{ justifyContent: prop_value === 'video_url' ? 'space-around' : 'space-between' }}
        >
          <div
            style={{
              marginLeft: prop_value === 'video_url' ? '0px' : '20px',
              width: prop_value === 'video_url' ? '70%' : 'auto',
              wordBreak: 'break-all',
              overflowY: 'auto',
            }}
          >
            {fileName && filePath !== '' ? fileName : 'Browse new file'}
          </div>
          <button style={{ marginRight: prop_value === 'video_url' ? '0px' : '20px' }} onClick={handleFileRemove}>
            Remove
          </button>
        </div>
      ) : (
        <div className="attachment-file__default" style={{ width: '100%' }}>
          <div>
            {editContentDetails && editContentDetails[prop_value] && editContentDetails[prop_value] === '' && toggleEditComponent
              ? 'Browse New File'
              : 'Browse file'}
          </div>
        </div>
      )}
    </label>
  );
};

export default BrowseFile;
