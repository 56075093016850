import React from 'react';
import './UserName.css';
import '../../../src/Assets/css/common.css';

const userNameInput = (props) => {
  const { onChange, value, errors, placeholder } = props;

  return (
    <>
      <div className="login-username">
        <input
          type="email"
          name="email"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          className="login-username__field"
        />
      </div>
      <div className="formik-error">{errors}</div>
    </>
  );
};

export default userNameInput;
