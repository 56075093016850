import React from 'react';
import { Link } from 'react-router-dom';
import './DisplayActivity.css';
import ArrowLeft from '../../../../Assets/ContentLibrary/ContentDetails/arrow-left.png';
import ContentMenu from '../../../../components/ContentMenu/ContentMenu';
import DisplayCards from '../../../../components/DisplayCards/DisplayCards';
import Suggestions from '../../../../components/Suggestions/Suggestions';
import TimeLine from '../../../../components/TimeLine/TimeLine';

const DisplayActivity = (props) => {
  const { handleToggle, contentDetails, activityFeedback, setActivityFeedback, activityHistory } = props;

  return (
    <div className="display-activity">
      <div className="display-activity__header">
        <Link to="/activity-stack">
          <img className="header-img" src={ArrowLeft} alt="back to content library" />
        </Link>
        <div className="header-text">
          Activity {'>'} <span style={{ textTransform: 'none' }}>Activity Details</span>
        </div>
      </div>
      <div className="display-activity__main">
        <ContentMenu contentInfo={contentDetails} id_name="activity_id" handleToggle={handleToggle} />
        <DisplayCards contentDetails={contentDetails} id_name="activity_id" />
        <Suggestions contentFeedback={activityFeedback} setContentFeedback={setActivityFeedback} />
        <TimeLine contentHistory={activityHistory} />
      </div>
    </div>
  );
};

export default DisplayActivity;
