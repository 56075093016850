import React from 'react';
import './SettingsMainBar.css';
import SettingsCheckbox from '../../../components/SettingsCheckbox/SettingsCheckbox';

const SettingsMainBar = (props) => {
  return (
    <div className="settings-main-bar">
      <div className="settings-main-bar__header">
        <div className="settings-header-text">Settings</div>
      </div>
      <div className="settings-main-bar__form">
        <div className="settings-form-checkbox-holder">
          <SettingsCheckbox name={'Content'} value={null} description={`Display Curated Content only`} />
          <SettingsCheckbox name={'Activity'} value={null} description={`Display Curated Activity only`} />
          <SettingsCheckbox name={'Questions'} value={null} description={`Display Curated Questions only`} marginTop={'-170px'} />
        </div>
        <div className="settings-main-bar__form-submission">
          {/* <Link to="/content-library">
                                <button className="cancel-btn" type="reset">Cancel</button>
                            </Link> */}
          <button className="cancel-btn" type="reset">
            Cancel
          </button>
          <button className="save-btn" type="submit">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsMainBar;
