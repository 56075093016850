import React from 'react';
import SideBar from '../../../components/SideBar/SideBar';
import AddContent from './AddContent/AddContent';
import './UploadContent.css';

const UploadContent = (props) => {
  return (
    <div className="upload-content-main-page">
      <div className="upload-content-main-page__bar-container">
        <SideBar />
        <AddContent />
      </div>
    </div>
  );
};

export default UploadContent;
