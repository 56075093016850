import React from 'react';
import './CheckBoxGroup.css';
import CheckBox from '../CheckBox/CheckBox';

const CheckBoxGroup = (props) => {
  const { values, category, name, marginTop, label, inputValue, setValues } = props;

  const toggleChecked = (e) => {
    let selectedValue = e.target.value;
    let checked = e.target.checked;

    let tempArray = values;

    tempArray.forEach((item) => {
      //eslint - to disable strict equality check warning
      // eslint-disable-next-line
      if (item[inputValue] == selectedValue) {
        if (checked) {
          item.checked = true;
        } else {
          delete item.checked;
        }
      }
    });
    setValues(tempArray);
  };

  return (
    <div style={marginTop && { marginTop: marginTop }}>
      <div className="checkbox-category">{category}</div>
      {values.map((ele, index) => {
        return (
          <div key={index} name={name}>
            <CheckBox
              id={ele[inputValue]}
              name={ele[inputValue]}
              value={ele[inputValue]}
              onChanged={toggleChecked}
              checked={ele.checked}
              label={ele[label]}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CheckBoxGroup;
