import React from 'react';
import './DisplayCards.css';
import PrimaryCard from '../PrimaryCard/PrimaryCard';
import SecondaryCard from '../SecondaryCard/SecondaryCard';
import TertiaryCard from '../TertiaryCard/TertiaryCard';
import ActivityDescriptionInfo from '../ActivityDescriptionInfo/ActivityDescriptionInfo';
import ActivityVideoHolder from '../ActivityVideoHolder/ActivityVideoHolder';
import ActivityPlayKitHolder from '../ActivityPlayKitHolder/ActivityPlayKitHolder';
import Team from '../../Assets/ActivityStack/team.png';
import Practice from '../../Assets/ActivityStack/practice.png';
import Moderate from '../../Assets/ActivityStack/moderate.png';
import Worksheet from '../../Assets/ActivityStack/work-sheet.png';
import HulaHoop from '../../Assets/ActivityStack/hula-hoop.png';

const DisplayCards = (props) => {
  const { contentDetails, id_name } = props;

  let lastUpdatedDate = contentDetails && contentDetails.last_updated;
  let displayUpdatedDate = new Date(lastUpdatedDate).toLocaleDateString('en-in', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  let uploadedDate = contentDetails && contentDetails.uploaded_date;
  let displayUploadedDate = new Date(uploadedDate).toLocaleDateString('en-in', { day: 'numeric', month: 'long', year: 'numeric' });

  return (
    <div className="display-cards">
      <div className="display-cards__primary">
        {id_name === 'content_id' ? (
          <>
            <PrimaryCard contentDetails={contentDetails} name={'Board'} prop_value={'board_name'} />
            <PrimaryCard contentDetails={contentDetails} name={'Course'} prop_value={'course_name'} />
            <PrimaryCard contentDetails={contentDetails} name={'Subject'} prop_value={'subject_name'} />
            <PrimaryCard contentDetails={contentDetails} name={'Objective'} prop_value={'objective_name'} />
          </>
        ) : id_name === 'activity_id' ? (
          <>
            <PrimaryCard contentDetails={contentDetails} name={'Board'} prop_value={'board_name'} width={'320px'} />
            <PrimaryCard contentDetails={contentDetails} name={'Course'} prop_value={'course_name'} width={'320px'} />
            <PrimaryCard contentDetails={contentDetails} name={'Subject'} prop_value={'subject_name'} width={'320px'} />
          </>
        ) : null}
      </div>

      <div className="display-cards__secondary">
        <SecondaryCard contentDetails={contentDetails} name={'Topic 1'} prop_value={'topic_1_name'} />
        <SecondaryCard
          contentDetails={contentDetails}
          name={'Sub Topic 1'}
          prop_value={contentDetails.sub_topic_1_id && contentDetails.sub_topic_1_id !== -1 && 'sub_topic_1_name'}
        />
        <SecondaryCard
          contentDetails={contentDetails}
          name={'Topic 2'}
          prop_value={contentDetails.topic_2_id && contentDetails.topic_2_id !== -1 && 'topic_2_name'}
        />
        <SecondaryCard
          contentDetails={contentDetails}
          name={'Sub Topic 2'}
          prop_value={contentDetails.sub_topic_2_id && contentDetails.sub_topic_2_id !== -1 && 'sub_topic_2_name'}
        />
      </div>
      {id_name === 'activity_id' ? (
        <div className="activity-details-container">
          <div className="activity-details-container__box1">
            <div className="activity-description">
              <ActivityDescriptionInfo name={'Team'} src={Team} />
              <ActivityDescriptionInfo name={'Practice'} src={Practice} />
              <ActivityDescriptionInfo name={'Moderate'} src={Moderate} />
              <ActivityDescriptionInfo name={'Work Sheet'} src={Worksheet} />
            </div>
            <span className="activity-section-seperator"></span>
            <div className="activity-playkit-details">
              <ActivityDescriptionInfo name={'Playkit'} src={HulaHoop} marginTop={'26px'} />
              <ActivityPlayKitHolder contentDetails={contentDetails} />
            </div>
          </div>
          <ActivityVideoHolder contentDetails={contentDetails} />
        </div>
      ) : null}
      <div className="display-cards__tertiary">
        <TertiaryCard name={'Uploaded'} date={displayUploadedDate} />
        <TertiaryCard name={'Last Updated'} date={displayUpdatedDate} />
        <TertiaryCard
          name={'Duration'}
          date={
            contentDetails.duration_in_min <= 1
              ? `${id_name === 'content_id' ? contentDetails.duration_in_min : contentDetails.activity_time_in_min} min`
              : `${id_name === 'content_id' ? contentDetails.duration_in_min : contentDetails.activity_time_in_min} mins`
          }
        />
      </div>
    </div>
  );
};

export default DisplayCards;
