import React from 'react';
import './ContentMenuBtns.css';

const ContentMenuBtns = (props) => {
  const { src, prop_value, contentInfo, msg, width } = props;

  return (
    <div className="stats-detail" style={{ width: width && width }}>
      {src ? <img className="stats-detail__icon" src={src} alt="view count" /> : null}
      <div className="stats-detail__count">{contentInfo[prop_value] ? contentInfo[prop_value] : msg}</div>
    </div>
  );
};

export default ContentMenuBtns;
