import React from 'react';
import './PlayKitTextInput.css';

const PlayKitTextInput = (props) => {
  const { width, label, value, handleChange, characters, name, max, errorMessage, height, placeHolder } = props;

  return (
    <div className="playKit-input-holder" style={{ width: width }}>
      <label className="playKit-input-holder__label">{label}</label>
      {height ? (
        <textarea
          className="playKit-text-input"
          style={{
            height: height ? height : 'auto',
            resize: 'none',
            lineBreak: 'anywhere',
          }}
          type="text"
          value={value}
          onChange={handleChange}
          name={name}
          maxLength={max}
          placeholder={placeHolder ?? placeHolder}
        />
      ) : (
        <input
          className="playKit-text-input"
          type="text"
          value={value}
          onChange={handleChange}
          name={name}
          maxLength={max}
          placeholder={placeHolder ?? placeHolder}
        />
      )}
      <div className="playKit-input-err-char__holder">
        <div className="playKit-input-holder__err">{errorMessage}</div>
        <div className="playKit-input-holder__char">{characters}</div>
      </div>
    </div>
  );
};

export default PlayKitTextInput;
