import React from 'react';
import './TimeLine.css';
import NoDataFound from '../../Assets/NoDataFound.png';
import EditHistory from '../EditHistoryBox/EditHistory';

const TimeLine = (props) => {
  const { contentHistory } = props;

  const weekday = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

  return (
    <div className="timeline-container">
      <div className="timeline-container__header">
        <div className="timeline-container__header-text">Edit History</div>
      </div>
      <div className="timeline-container__body" style={{ minHeight: contentHistory.length > 0 ? '500px' : '130px' }}>
        {contentHistory && contentHistory.length > 0 ? (
          contentHistory.map((obj, index) => {
            let date = obj.history_date;
            let editDate = new Date(date);
            let day = weekday[editDate.getDay()];
            let historyDate = new Date(date).toLocaleDateString('en-in', { day: 'numeric', month: 'long', year: 'numeric' });

            return (
              <div key={index}>
                <div className="timeline-container__body-date-text">DATE</div>
                <div className="timeline-container__body-details-text">DETAILS</div>
                <EditHistory
                  type={obj.type}
                  day={day}
                  historyDate={historyDate}
                  description={obj.description}
                  userName={obj.user_name}
                  leftRight={index % 2 === 0 ? 'even' : 'odd'}
                  className={
                    index % 2 === 0 ? 'timeline-container__body-history-box-left' : 'timeline-container__body-history-box-right'
                  }
                  pointerClass={index % 2 === 0 ? 'pointer-left' : 'pointer-right'}
                  dateBoxPositionLeft={index % 2 !== 0 ? '96%' : '-3'}
                  top={index % 2 === 0 ? (index === 0 ? '10%' : `${(index + 1) * 10}%`) : `${(index + 1) * 10}%`}
                />
              </div>
            );
          })
        ) : (
          <div className="no-data-block" style={{ width: '99%', marginTop: '15px' }}>
            <img
              src={NoDataFound}
              alt="pic showing no data found message"
              style={{ width: '150px', height: 'auto', marginTop: '-10px' }}
            />
            <div className="no-data-text">{'No history found for this Content.'}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeLine;
