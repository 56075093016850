import React from 'react';
import './ActivityStackStatsInfo.css';

const ActivityStackStatsInfo = (props) => {
  const { name, src, alt, color } = props;
  return (
    <div className="description-info">
      <img src={src} alt={alt} />
      <div className="description-info__title" style={{ color: color && color }}>
        {name}
      </div>
    </div>
  );
};

export default ActivityStackStatsInfo;
