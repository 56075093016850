import React, { useState, useEffect } from 'react';
import './Stats.css';
import MultiMedia from '../../Assets/ContentLibrary/ContentLibrary/MainBar/multi-media.png';
import Readable from '../../Assets/ContentLibrary/ContentLibrary/MainBar/readable.png';
import PieChart from '../Charts/PieChart';

const Stats = (props) => {
  const { multimediaChartData, readableChartData, contentObjectives } = props;

  const [multiMediaCount, setMultiMediaCount] = useState(0);
  const [readableCount, setReadbleCount] = useState(0);
  const [multiMediaPieChartData, setMultiMediaPieChartData] = useState([]);
  const [readAblePieChartData, setReadAblePieChartData] = useState([]);
  const [statsDisplayData, setStatsDisplayData] = useState([]);
  const backgroundColorArr = ['#2D9CDB', '#219653', '#BB6BD9', '#F2994A', '#BDBDBD'];

  useEffect(() => {
    const getCount = async () => {
      await getMediaCount(multimediaChartData, 'multiMedia');
      await getMediaCount(readableChartData, 'readAble');
      await getObjectiveCounts(multimediaChartData, 'multiMedia');
      await getObjectiveCounts(readableChartData, 'readAble');
      await getObjectiveCounts(contentObjectives, 'statsDisplayData');
    };
    try {
      getCount();
    } catch (err) {
      console.log(err);
      throw err;
    }
  }, [multimediaChartData, readableChartData, contentObjectives]);

  const getObjectiveCounts = async (arrayOfObjectives, str) => {
    if (arrayOfObjectives.length >= 4) {
      const top4Items = arrayOfObjectives.slice(0, 4);
      const otherContentObjectiveCount = arrayOfObjectives.reduce((acc, ele) => {
        acc['Others'] = ele['objective_count'];
        return acc;
      }, {});
      const finalChartData = [...top4Items, otherContentObjectiveCount];
      str === 'multiMedia'
        ? setMultiMediaPieChartData(finalChartData)
        : str === 'readAble'
          ? setReadAblePieChartData(finalChartData)
          : setStatsDisplayData(finalChartData);
    } else {
      str === 'multiMedia'
        ? setMultiMediaPieChartData(arrayOfObjectives)
        : str === 'readAble'
          ? setReadAblePieChartData(arrayOfObjectives)
          : setStatsDisplayData(arrayOfObjectives);
    }
  };

  const getMediaCount = async (dataArr, str) => {
    const count = dataArr.reduce((acc, ele) => {
      return (acc += +ele['objective_count']);
    }, 0);
    if (str === 'multiMedia') {
      return setMultiMediaCount(count);
    } else if (str === 'readAble') {
      return setReadbleCount(count);
    }
  };

  if (statsDisplayData.length !== 0) {
    for (let index = 0; index < statsDisplayData.length; index++) {
      let element = statsDisplayData[index];
      element.color = backgroundColorArr[index % backgroundColorArr.length];
    }
  }
  // console.log("readablePiechartData, readableChartData, contentObjectives, statsDisplayData", readAblePieChartData, readableChartData, contentObjectives, statsDisplayData)

  return (
    <div className="chart-container">
      <div className="chart-container__multimedia-info">
        <div className="multimedia-info-count">
          <img src={MultiMedia} alt="multimedia media type" />
          <div className="count-display">
            <div className="stats-display-name">Multimedia</div>
            <div className="stats-display-number">{multiMediaCount}</div>
          </div>
        </div>
        <PieChart chartData={multiMediaPieChartData} backgroundColorArr={backgroundColorArr} name={'content'} />
      </div>
      <div className="chart-container__readable-info">
        <div className="readable-info-count">
          <img src={Readable} alt="readable media type" />
          <div className="count-display">
            <div className="stats-display-name">Readables</div>
            <div className="stats-display-number">{readableCount}</div>
          </div>
        </div>
        <PieChart chartData={readAblePieChartData} backgroundColorArr={backgroundColorArr} name={'content'} />
      </div>
      <div className="chart-container__stats-display">
        <div className="stats-display-header">Total</div>
        <div className="stats-display-body">
          {statsDisplayData.map((obj, index) => {
            return (
              <div className="stats-display-element" key={index}>
                <div className="element-color-code" style={{ backgroundColor: obj.color }}></div>
                <div className="element-info">
                  {obj['objective_name'] ? obj['objective_name'] : 'Others'}
                  <span style={{ marginLeft: '15px' }}>{obj['objective_count'] ? obj['objective_count'] : obj['Others']}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Stats;
