import React from 'react';
import './ContentLibrary.css';
import MainBar from './MainBar/MainBar';
import SideBar from '../../../components/SideBar/SideBar';

const ContentLibrary = (props) => {
  return (
    <div className="main-page">
      <div className="main-page__bar-container">
        <SideBar />
        <MainBar />
      </div>
    </div>
  );
};

export default ContentLibrary;
