/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { NavLink, Link } from 'react-router-dom';
import './PlayKitTable.css';
import TableControlButtons from '../TableControlButtons/TableControlButtons';
import editPlayKit from '../../Assets/Table/TableBody/editPlayKit.png';
import deletePlayKit from '../../Assets/Table/TableBody/deletePlayKit.png';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { deleteSinglePlayKit } from '../../api/playKit';
import playKitBoy from '../../Assets/Table/TableBody/playKitBoy.png';

const PlayKitTable = (props) => {
  const { tableData, count, limit, offset, currentPage, setOffset, setCurrentPage, updateTableData } = props;
  const [checkAll, setCheckAll] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const listRef = useRef();
  const initialRender = useRef(false);
  const tableLength = tableData ?? JSON.stringify(tableData.length);

  useEffect(() => {
    setOffset(limit * (currentPage - 1));

    if (initialRender.current && listRef.current && tableData.length > 12) {
      const { clientHeight, scrollHeight } = listRef.current;
      const percentageFactor = (tableData.length - 10) / tableData.length;
      listRef.current.scrollTo({ top: scrollHeight * percentageFactor - clientHeight * 0.85, behaviour: 'smooth' });
    } else {
      initialRender.current = true;
    }
  }, [currentPage, tableLength, limit, tableData.length]);

  const columns = [
    { column_key: 'id', columnName: 'Play kit Name', hasSort: true, columnType: 'text' },
    { column_key: 'title', columnName: 'Description', hasSort: false, columnType: 'text' },
    { column_key: 'board', columnName: 'Actions', hasSort: false, columnType: 'btns' },
  ];

  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      const isNearBottom = scrollHeight - scrollTop - 10 < clientHeight;
      if (isNearBottom && offset <= count - limit) {
        setCurrentPage(currentPage + 1);
        listRef.current.scrollTo({ top: 0, behaviour: 'smooth' });
      }
    }
  };

  const handleCheckBoxClick = (e) => {
    let checked = e.target.checked;
    let selectedContentId = +e.target.value;
    if (checked) {
      setCheckedIds([...checkedIds, +selectedContentId]);
    } else {
      const updateCheckedlist = checkedIds.filter((ele) => +ele !== +selectedContentId);
      setCheckedIds(updateCheckedlist);
    }
  };

  const handleCheckAll = async (e) => {
    let check = e.target.checked;
    setCheckAll(check);
    if (check) {
      let checkedAllArr = tableData.map((obj) => obj.playkit_id);
      setCheckedIds(checkedAllArr);
    } else {
      setCheckedIds([]);
    }
  };

  const eraseContent = async (playKitId) => {
    try {
      const result = await deleteSinglePlayKit(playKitId);
      // console.log('result', result);
      if (result?.data?.status) {
        toast.success('Play kit deleted successfully!');
        const updatedList = tableData?.filter((ele) => ele.playkit_id !== playKitId);
        updateTableData(updatedList);
      } else {
        toast.error('Error occured while deleting play kit');
      }
    } catch (err) {
      console.log('error in deleting playkit', err);
      toast.error('Error occured while deleting play kit');
    }
  };

  const handleSinglePlaykitRemove = async (id) => {
    confirmAlert({
      // title: 'Confirm to submit',
      message: 'Are you sure you want to remove this Play Kit?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => eraseContent(id),
        },
        {
          label: 'No',
        },
      ],
    });
  };

  const eraseMultiplePlaykit = async () => {
    const deletedResultArr = await Promise.allSettled(
      checkedIds.map(async (ele) => {
        const result = await deleteSinglePlayKit(+ele);
        return { ...result, selectedId: ele };
      }),
    );

    const fullfilledArr = deletedResultArr
      .filter((obj) => {
        if (obj.status === 'fulfilled') {
          return obj;
        } else return null;
      })
      .map((ele) => ele?.value?.data?.data?.playkit_id);

    const failedArr = deletedResultArr.filter((ele) => ele.status === 'rejected');
    // console.log({ fullfilledArr, failedArr });

    if (fullfilledArr.length <= 0) {
      toast.error('Playkits selected failed to delete!');
    } else if (fullfilledArr.length > 0 && failedArr.length > 0) {
      toast.error('Some of the playkits selected failed to delete!');
      const updatedList = tableData
        .map((playKitObj) => {
          if (fullfilledArr.includes(+playKitObj?.playkit_id)) {
            return null;
          } else return playKitObj;
        })
        .filter((ele) => ele !== null);
      updateTableData(updatedList);
      setCheckedIds([]);
    } else {
      toast.success('All selected playkits are successfully deleted');
      const updatedList = tableData
        .map((playKitObj) => {
          if (fullfilledArr.includes(+playKitObj?.playkit_id)) {
            return null;
          } else return playKitObj;
        })
        .filter((ele) => ele !== null);
      updateTableData(updatedList);
      setCheckedIds([]);
    }
  };

  const handleRemoveMultiplePlaykit = async () => {
    if (checkedIds.length) {
      return confirmAlert({
        message: 'Are you sure you want to remove selected Play Kits?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => eraseMultiplePlaykit(),
          },
          {
            label: 'No',
          },
        ],
      });
    } else {
      toast.info('No playkits are selected to delete');
    }
  };

  return (
    <div className="playkit-table">
      <TableControlButtons
        count={count}
        addName="+ Add Playkit"
        removeName="Remove Playkit"
        link={'/upload-playKit'}
        handleRemove={handleRemoveMultiplePlaykit}
        modalName={'PlayKit Add Button'}
      />
      <div className="playkit-table-responsive" onScroll={handleScroll} ref={listRef}>
        <table className="playkit-table-display">
          <thead>
            <tr style={{ display: !tableData?.length && 'inline-table', zIndex: '1' }}>
              <td>
                <input type="checkbox" checked={checkAll} onChange={handleCheckAll} />
              </td>
              {columns.map((ele, index) => {
                return (
                  <td key={index}>
                    <div className="explainer-video-table-column-heading">
                      <div className="explainer-video-table-column-heading-text">{ele.columnName}</div>
                    </div>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableData?.length > 0 ? (
              tableData.map((obj, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        id={obj.playkit_id}
                        value={obj.playkit_id}
                        // checked={obj.checked ? obj.checked : false}
                        checked={checkedIds.includes(obj.playkit_id)}
                        onChange={handleCheckBoxClick}
                      />
                    </td>
                    <td>{obj?.playkit_name ? `${obj.playkit_name[0].toUpperCase()}${obj.playkit_name.slice(1)}` : 'NA'}</td>
                    <td>
                      {obj?.playkit_description
                        ? `${obj.playkit_description[0].toUpperCase()}${obj.playkit_description.slice(1)}`
                        : 'NA'}
                    </td>
                    <td>
                      <Link
                        to={{
                          pathname: `/playKit-details/${obj.playkit_id}`,
                          state: {
                            playKitClicked: obj, // <-- the mapped obj from data
                            mode: true,
                          },
                        }}>
                        <img src={editPlayKit} alt="edit-playkit" style={{ scale: '1.2', cursor: 'pointer' }} />
                      </Link>
                      <img
                        src={deletePlayKit}
                        alt="delete-playkit"
                        onClick={() => handleSinglePlaykitRemove(obj.playkit_id)}
                        style={{ scale: '1.2', cursor: 'pointer' }}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr style={{ border: 'none' }}>
                <td className="plakit-no-data">
                  <div className="plakit-no-data__text">{'There is no Play Kit, reset the filter or Add Playkit'}</div>
                  <img src={playKitBoy} alt="no-plakit-found" />
                  <NavLink className="playkit-add-btn" to={'/upload-playKit'}>
                    + Add Play Kit
                  </NavLink>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlayKitTable;
