import React from 'react';
import './UploadThumbnail.css';
import AddContentThumbNil from '../../Assets/ContentLibrary/UploadContent/add-content-thumbnail.png';

const UploadThumbnail = (props) => {
  const { thumbnail, handleUploadThumbnail, formErrors, toggleEditComponent, editContentDetails, width } = props;

  return (
    <div>
      <label className="form-thumbnil" style={{ width: width ? width : '440px' }}>
        {toggleEditComponent ? (
          <img
            src={editContentDetails.thumbnail_url}
            className={width ? 'form-thumbnil__image-explainer-video' : 'form-thumbnil__image'}
            alt="thumbnail"
          />
        ) : thumbnail ? (
          <img
            // className="form-thumbnil__image"
            className={width ? 'form-thumbnil__image-explainer-video' : 'form-thumbnil__image'}
            src={URL.createObjectURL(thumbnail)}
            alt="thumbnail"
          />
        ) : (
          <img
            src={AddContentThumbNil}
            // style={{ marginLeft: "25px" }}
            className={width ? 'form-thumbnil__image-explainer-video' : 'form-thumbnil__image'}
            alt="default-thumbnail"
          />
        )}
        <input type="file" accept=".png, .jpg, .jpeg" className="form-thumbnil__image" onChange={handleUploadThumbnail} />
        <div className="form-thumbnil__text">
          {thumbnail ? <div>{thumbnail.name}</div> : toggleEditComponent ? 'Change Thumbnail' : '+ Add thumbnail'}
        </div>
      </label>
      {formErrors && (
        <div className="add-content-form-error" style={{ marginTop: '5px' }}>
          {formErrors}
        </div>
      )}
    </div>
  );
};

export default UploadThumbnail;
