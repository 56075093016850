import React from 'react';
import './UploadActivity.css';
import SideBar from '../../../components/SideBar/SideBar';
import AddActivity from './AddActivity/AddActivity';

const UploadActivity = (props) => {
  return (
    <div className="upload-activity-main-page">
      <div className="upload-activity-main-page__bar-container">
        <SideBar />
        <AddActivity />
      </div>
    </div>
  );
};

export default UploadActivity;
