import React from 'react';

function ToolControlButtons(props) {
  const { toolCount, handleAddToolClick, handleDeleteTool } = props;

  return (
    <div className="playKit-tool-holder__control">
      <div className="playKit-tool-holder__text">{`Included Tools - ${toolCount}`}</div>
      <div className="playKit-tool-holder__control-btns">
        <div className="playKit-tool-holder__control-btns__add" onClick={handleAddToolClick}>
          + Add Tool
        </div>
        <div className="playKit-tool-holder__control-btns__delete" onClick={handleDeleteTool}>
          Delete Tools
        </div>
      </div>
    </div>
  );
}

export default ToolControlButtons;
