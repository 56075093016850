import React, { useState } from 'react';
import './ActivityVideoHolder.css';
import ActivityStackExplainerVideo from '../ActivityStackExplainerVideo/ActivityStackExplainerVideo';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import ExplainerVideoDefaultThumbnail from '../../Assets/ActivityStack/ActivityDetails/explainer_video_thumbnail.png';
import { toast } from 'react-toastify';

const ActivityVideoHolder = (props) => {
  const { contentDetails } = props;

  const [openModal, setOpenModal] = useState(false);

  const handleModalOpenClose = () => {
    contentDetails?.explainer_video?.explainer_video_url
      ? setOpenModal(!openModal)
      : toast.error('Cannot play video due to invalid url');
  };

  return (
    <div className="activity-video-holder">
      <div className="activity-video-holder__detail">
        <ActivityStackExplainerVideo
          heading={'Explainer Video'}
          title={contentDetails && contentDetails.explainer_video && contentDetails.explainer_video.explainer_video_title}
          id={contentDetails && contentDetails.explainer_video && contentDetails.explainer_video.explainer_video_id}
          duration={
            contentDetails && contentDetails.explainer_video && contentDetails.explainer_video.explainer_video_duration_inMin
          }
        />
      </div>
      <div className="activity-video-holder__container">
        <img
          className="activity-video-holder__thumbnail"
          src={
            contentDetails && contentDetails.explainer_video && contentDetails.explainer_video.explainer_video_thumbnail_url
              ? contentDetails.explainer_video.explainer_video_thumbnail_url
              : ExplainerVideoDefaultThumbnail
          }
          alt="explainer video thumbnail"
        />
        <button className="preview-btn" onClick={handleModalOpenClose}>
          Preview
        </button>
      </div>
      {openModal && (
        <VideoPlayer
          openModal={openModal}
          handleModalOpenClose={handleModalOpenClose}
          url={contentDetails && contentDetails.explainer_video && contentDetails.explainer_video.explainer_video_url}
        />
      )}
    </div>
  );
};

export default ActivityVideoHolder;
