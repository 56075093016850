import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import UserName from '../../components/UserName/UserName';
import Button from '../../components/Button/Button';
import 'react-toastify/dist/ReactToastify.css';
import '../../Assets/css/msedge.css';
import './ResetPasswordForm.css';
import { passwordResetMail } from '../../api/login';

const resetPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required('Provide Email'),
});

const ResetPasswordForm = (props) => {
  const { handleToggle } = props;

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (formData, { resetForm }) => {
      try {
        let res = await passwordResetMail(formData);
        toast.success(res.message);
        resetForm();
        setTimeout(() => {
          handleToggle();
        }, 4000);
      } catch (err) {
        toast.error(err.message);
      }
    },
    validateOnChange: true,
    validationSchema: resetPasswordSchema,
  });
  return (
    <>
      <div className="reset-password-form">
        <div className="reset-password-form__container">
          <form onSubmit={formik.handleSubmit} className="formik" style={{ justifyContent: 'flex-start' }}>
            <div className="formik-input" style={{ width: '100%', padding: '0%' }}>
              <UserName
                onChange={formik.handleChange}
                value={formik.values.email}
                errors={formik.errors.email}
                placeholder="Please enter registered email id here"
              />

              <Button type="submit" name="Submit" />
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        autoClose={3000}
        transition={Bounce}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ResetPasswordForm;
