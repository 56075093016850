import React from 'react';
import './EditHistory.css';

const EditHistory = (props) => {
  const { type, day, historyDate, description, userName, leftRight, className, pointerClass, dateBoxPositionLeft, top } = props;
  let monthAndYear = historyDate.slice(3);
  let numericDay = historyDate.slice(0, 2);

  return (
    <div className="edit-history-box">
      {leftRight === 'even' ? (
        <span className="edit-history-box__seperator" style={{ marginLeft: '-25px' }}></span>
      ) : (
        <span className="edit-history-box__seperator" style={{ marginLeft: '830px' }}></span>
      )}
      <div className={className} style={{ top: top }}>
        <div className="edit-history-box__date-box" style={{ left: dateBoxPositionLeft }}>
          <div className="edit-history-box__date-day">{`${day}, ${numericDay}`}</div>
          <div className="edit-history-box__month-year">{`${monthAndYear}`}</div>
        </div>
        <div className="edit-history-box__history">
          <div className="edit-history-box__history-type">
            <div className="edit-history-box__history-type-description">{type}</div>
            <div className="edit-history-box__history-type-description-creator">{`By: ${userName}`}</div>
          </div>
          <div className="edit-history-box__history-comment-text" style={{ width: '350px' }}>
            {description}
          </div>
          <div className={pointerClass}></div>
        </div>
      </div>
    </div>
  );
};

export default EditHistory;
