/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './ActivityMainBar.css';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RiseLoader from 'react-spinners/RiseLoader';
import Filter from '../../../components/Filter/Filter';
import ActivityStats from '../../../components/ActivityStats/ActivityStats';
import ActivityStackTable from '../../../components/ActivityStackTable/ActivityStackTable';
// import Pagination from '../../../components/Pagination/Pagination';
import {
  fetchBoards,
  fetchCourse,
  fetchSubjects,
  fetchSubSubjects,
  fetchTopics,
  fetchSubTopics,
  fetchActivityCategory,
  fetchActivityType,
  fetchActivityMode,
  fetchAuthorList,
} from '../../../api/master_data';
import { fetchActivityStackTable, fetchActivityStackCount, fetchActivitySummary } from '../../../api/activity_stack';

const ActivityMainBar = (props) => {
  const [boards, setBoards] = useState([]);
  const [board_id, setBoardId] = useState(-1);
  const [courses, setCourses] = useState([]);
  const [course_id, setCourseId] = useState(-1);
  const [subjects, setSubjects] = useState([]);
  const [subject_id, setSubjectId] = useState(-1);
  const [subSubjects, setSubSubjects] = useState([]);
  const [subSubject_id, setSubSubjectId] = useState(-1);
  const [topics, setTopics] = useState([]);
  const [topic_id, setTopicId] = useState(-1);
  const [subTopics, setSubTopics] = useState([]);
  const [subTopic_id, setSubTopicId] = useState(-1);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [activityTableData, setActivityTableData] = useState([]);
  const [activityCount, setActivityCount] = useState(0);
  const [activitySummary, setActivitySummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem('user_id');

  //Activity Stack More filters
  const [activityModalData, setActivityModalData] = useState({
    is_min_recommended: false,
    activity_mode_ids: '',
    activity_type_ids: '',
    activity_category_ids: '',
    link_type: 'ALL',
    authorId: -1,
  });
  const [authors, setAuthors] = useState([]);
  const [activityCategory, setActivityCategory] = useState([]);
  const [activityMode, setActivityMode] = useState([]);
  const [activityType, setActivityType] = useState([]);
  // const [sortType, setSortType] = useState([])

  // Pagination
  let limit = 10;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  // const paginate = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  //   setOffset(limit * (pageNumber - 1));
  // };

  // console.log("activityTableData", activityTableData)
  // console.log("activityModalData", activityModalData)
  // console.log("activityCount", activityCount)
  // console.log('activitySummary', activitySummary);

  // Activity stack more filter data
  useEffect(() => {
    const getAllOptions = async () => {
      setLoading(true);
      setAuthors(await fetchAuthorList());
      setActivityCategory(await fetchActivityCategory());
      setActivityMode(await fetchActivityMode());
      setActivityType(await fetchActivityType());
      setLoading(false);
    };
    try {
      getAllOptions();
    } catch (err) {
      console.log('err fetchAll getAllOptions ActivityMainBar', err);
      toast.error('Something went wrong try again');
      if (err?.message) {
        toast.error(err.message);
      }
      setLoading(false);
    }
  }, []);

  // Initial Rendering
  useEffect(() => {
    const fetchAll = async () => {
      setLoading(true);
      setBoards(await fetchBoards());
      setCourses(await fetchCourse(board_id));
      setSubjects(await fetchSubjects(board_id, course_id));
      setSubSubjects(await fetchSubSubjects(board_id, course_id, subject_id));
      setTopics(await fetchTopics(board_id, course_id, subject_id, subSubject_id));
      setSubTopics(await fetchSubTopics(board_id, course_id, subject_id, subSubject_id, topic_id));
      userId &&
        setActivityTableData(
          await fetchActivityStackTable(
            limit,
            offset,
            board_id,
            course_id,
            subject_id,
            topic_id,
            subTopic_id,
            searchTerm,
            activityModalData,
          ),
        );
      setActivityCount(
        await fetchActivityStackCount(
          limit,
          offset,
          board_id,
          course_id,
          subject_id,
          topic_id,
          subTopic_id,
          searchTerm,
          activityModalData,
        ),
      );
      setLoading(false);
    };
    try {
      fetchAll();
    } catch (err) {
      console.log('err fetchAll ActivityMainBar', err);
      toast.error('Something went wrong try again');
      if (err?.message) {
        toast.error(err.message);
      }
      if (err && err.response.status === 400 && err.response.data && err.response.data.type === 'TOKEN_ERR') {
        toast.info('Session expired, Please login once again');
        setTimeout(() => {
          return (window.location.href = '/login');
        }, 3000);
      }
      setLoading(false);
    }
  }, [limit, offset, board_id, course_id, subject_id, topic_id, subTopic_id, searchTerm, activityModalData, userId]);

  // Pagination and activity details page id removal
  useEffect(() => {
    const getTableData = async () => {
      setLoading(true);
      setActivityTableData(
        await fetchActivityStackTable(
          limit,
          offset,
          board_id,
          course_id,
          subject_id,
          topic_id,
          subTopic_id,
          searchTerm,
          activityModalData,
        ),
      );
      setActivityCount(
        await fetchActivityStackCount(
          limit,
          offset,
          board_id,
          course_id,
          subject_id,
          topic_id,
          subTopic_id,
          searchTerm,
          activityModalData,
        ),
      );
      setActivitySummary(
        await fetchActivitySummary(board_id, course_id, subject_id, topic_id, subTopic_id, searchTerm, activityModalData),
      );
      setCurrentPage(currentPage);
      localStorage.removeItem('clickedContentId');
      setLoading(false);
    };
    try {
      getTableData();
    } catch (err) {
      console.log('err getTableData ActivityMainBar', err);
      toast.error('Something went wrong try again');
      if (err?.message) {
        toast.error(err.message);
      }
      if (err && err.response.status === 400 && err.response.data && err.response.data.type === 'TOKEN_ERR') {
        toast.info('Session expired, Please login once again');
        setTimeout(() => {
          return (window.location.href = '/login');
        }, 3000);
      }
      setLoading(false);
    }
  }, [board_id, course_id, subject_id, subSubject_id, searchTerm, activityModalData, limit, offset, currentPage]);

  const handleModalOpenClose = () => {
    setOpenModal(!openModal);
  };

  const handleIdChange = async (e) => {
    let value = e.target.name;
    if (value === 'Board') {
      setBoardId(e.target.value);
    } else if (value === 'Course') {
      setCourseId(e.target.value);
    } else if (value === 'Subject') {
      setSubjectId(e.target.value);
    } else if (value === 'Sub-subject') {
      setSubSubjectId(e.target.value);
    } else if (value === 'Topic') {
      setTopicId(e.target.value);
    } else if (value === 'Sub-topic') {
      setSubTopicId(e.target.value);
    } else if (value === 'Search-bar') {
      setSearchTerm(e.target.value);
    }
  };

  return (
    <>
      {loading ? (
        <div className="loading__spinners">
          <RiseLoader
            color="#CC435F"
            margin={5}
            cssOverride={{
              position: 'absolute',
              top: '50%',
              left: '45%',
              zIndex: 1,
            }}
            loading={loading}
            size={50}
            speedMultiplier={1}
          />
        </div>
      ) : null}
      <div className="activity-stack-main-bar">
        <div className="activity-stack-main-bar__header">
          <div className="page-name">Activity Stack</div>
        </div>
        <div className="activity-stack-main-bar__filter-component">
          <Filter
            boards={boards}
            courses={courses}
            subjects={subjects}
            subSubjects={subSubjects}
            topics={topics}
            subTopics={subTopics}
            handleIdChange={handleIdChange}
            searchTerm={searchTerm}
            openModal={openModal}
            handleModalOpenClose={handleModalOpenClose}
            modalName={'ActivityStackModal'}
            activityCategory={activityCategory}
            activityMode={activityMode}
            activityType={activityType}
            authors={authors}
            setAuthors={setAuthors}
            setActivityCategory={setActivityCategory}
            setActivityMode={setActivityMode}
            setActivityType={setActivityType}
            activityModalData={activityModalData}
            setActivityModalData={setActivityModalData}
          />
        </div>
        <div className="activity-stack-main-bar__stats-component">
          <ActivityStats activityTableData={activityTableData} activitySummary={activitySummary} />
        </div>
        <div className="activity-stack-main-bar__table-component">
          <ActivityStackTable
            activityCount={activityCount}
            setActivityCount={setActivityCount}
            activityTableData={activityTableData}
            setActivityTableData={setActivityTableData}
            offset={offset}
            board_id={board_id}
            course_id={course_id}
            subject_id={subject_id}
            topic_id={topic_id}
            subTopic_id={subTopic_id}
            searchTerm={searchTerm}
            activityModalData={activityModalData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setOffset={setOffset}
            LIMIT={limit}
          />
        </div>
        {/* <div className="activity-stack-main-bar__pagination-component">
                    <Pagination
                        paginate={paginate}
                        count={activityCount}
                        limit={limit}
                        currentPage={currentPage}
                    />
                </div> */}
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />
    </>
  );
};

export default ActivityMainBar;
