import React, { useEffect, useState, useRef } from 'react';
import './Table2.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import * as _ from 'lodash'
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableControlButtons from '../TableControlButtons/TableControlButtons';
import View from '../../Assets/Table/TableHeader/view.png';
import Study_Circles from '../../Assets/Table/TableHeader/circle.png';
import Namaste from '../../Assets/Table/TableHeader/namaste.png';
import sortArrowUp from '../../Assets/Table/TableHeader/sort-arrow-up.png';
import sortArrowDown from '../../Assets/Table/TableHeader/sort-arrow-down.png';
import notCurated from '../../Assets/Table/TableBody/not-curated.png';
import Curated from '../../Assets/Table/TableBody/curated.png';
import Institution from '../../Assets/Table/TableBody/institution.png';
import Private from '../../Assets/Table/TableBody/private.png';
import Global from '../../Assets/Table/TableBody/global.png';
import NoDataFound from '../../Assets/NoDataFound.png';
import { filteredTable, deleteContent, fetchCount } from '../../api/content';

const Table = (props) => {
  const {
    board_id,
    course_id,
    subject_id,
    subSubject_id,
    searchTerm,
    modalData,
    LIMIT,
    offset,
    tableData,
    setTableData,
    count,
    /* sortData */ setCount,
    setCurrentPage,
    setOffset,
    currentPage,
    topic_id,
    subTopicId,
  } = props;
  const [checkAll, setCheckAll] = useState(false);
  const [render, setRender] = useState(false);
  const listRef = useRef();
  const initialRender = useRef(false);
  const columns = [
    { column_key: 'display_name', columnName: 'Display Name', hasSort: true, columnType: 'text' },
    { column_key: 'tile_caption', columnName: 'Tile Caption', hasSort: false, columnType: 'text' },
    { column_key: 'file_type', columnName: 'File Type', hasSort: true, columnType: 'text' },
    { column_key: 'added_date', columnName: 'Date', hasSort: true, columnType: 'text' },
    { column_key: 'duration_in_min', columnName: 'Duration (min.)', hasSort: true, columnType: 'text' },
    { column_key: 'is_curated', columnName: 'Curated', hasSort: false, columnType: 'text' },
    { column_key: 'sharing', columnName: 'Sharing', hasSort: false, columnType: 'text' },
    {
      column_key: 'View',
      columnName: View,
      hasSort: false,
      columnType: 'image',
      imagePath: '../../Assets/Table/TableHeader/Eye.png',
    },
    {
      column_key: 'Namaste',
      columnName: Namaste,
      hasSort: false,
      columnType: 'image',
      imagePath: '../../Assets/Table/TableHeader/Namaste.png',
    },
    {
      column_key: 'Study_Circle',
      columnName: Study_Circles,
      hasSort: false,
      columnType: 'image',
      imagePath: '../../Assets/Table/TableHeader/Circle.png',
    },
  ];
  // console.log("tabledata", tableData)

  /* useEffect(() => {
        setOffset(LIMIT * (currentPage - 1))
    }, [currentPage]) */
  const tableLength = tableData ?? JSON.stringify(tableData.length);
  useEffect(() => {
    setOffset(LIMIT * (currentPage - 1));

    if (initialRender.current && listRef.current && tableData.length > 10) {
      const { clientHeight, scrollHeight } = listRef.current;
      const percentageFactor = (tableData.length - 10) / tableData.length;
      listRef.current.scrollTo({ top: scrollHeight * percentageFactor - clientHeight * 0.85, behaviour: 'smooth' });
    } else {
      initialRender.current = true;
    }
  }, [currentPage, tableLength, LIMIT, setOffset, tableData.length]);

  useEffect(() => {
    const initiateReRender = async () => {
      setCount(
        await fetchCount(
          board_id,
          course_id,
          subject_id,
          subSubject_id,
          topic_id,
          subTopicId,
          searchTerm,
          modalData,
          LIMIT,
          offset,
        ),
      );
      setTableData(
        await filteredTable(
          board_id,
          course_id,
          subject_id,
          subSubject_id,
          topic_id,
          subTopicId,
          searchTerm,
          modalData,
          LIMIT,
          offset,
        ),
      );
    };
    if (render) {
      try {
        initiateReRender();
      } catch (err) {
        console.log(err);
        if (err && err.message) toast.error(err.message);
        if (err && err.response.status === 400 && err.response.data && err.response.data.type === 'TOKEN_ERR') {
          toast.info('Session expired, Please login once again');
          setTimeout(() => {
            return (window.location.href = '/login');
          }, 3000);
        }
      }
    }
    // eslint-disable-next-line
  }, [render, count]);

  const eraseContent = async () => {
    tableData.forEach(async (obj) => {
      if (obj.checked) {
        await deleteContent(+obj.content_id);
        setRender(!render);
      }
    });
    try {
      setTableData(await filteredTable(board_id, course_id, subject_id, subSubject_id, searchTerm, modalData, LIMIT, offset));
      setCount(
        await fetchCount(
          board_id,
          course_id,
          subject_id,
          subSubject_id,
          topic_id,
          subTopicId,
          searchTerm,
          modalData,
          LIMIT,
          offset,
        ),
      );
    } catch (err) {
      console.log(err);
      if (err && err.message) toast.error(err.message);
      if (err && err.response.status === 400 && err.response.data && err.response.data.type === 'TOKEN_ERR') {
        toast.info('Session expired, Please login once again');
        setTimeout(() => {
          return (window.location.href = '/login');
        }, 3000);
      }
    }
  };

  const handleRemoveContent = async () => {
    confirmAlert({
      // title: 'Confirm to submit',
      message: 'Are you sure you want to remove this content?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => eraseContent(),
        },
        {
          label: 'No',
        },
      ],
    });
  };

  const handleCheckBoxClick = (e) => {
    let checked = e.target.checked;
    let selectedContentId = e.target.value;
    let trueArray = tableData.map((obj) => {
      // eslint-disable-next-line
      if (+selectedContentId == obj.content_id) {
        return { ...obj, checked: checked };
      } else {
        return obj;
      }
    });
    setTableData(trueArray);
  };

  const handleCheckAll = async (e) => {
    let checked = e.target.checked;
    setCheckAll(checked);
    let trueArray = tableData.map((obj) => {
      return { ...obj, checked: checked };
    });
    await setTableData(trueArray);
    if (count === 0) {
      setCheckAll(!checkAll);
    }
  };

  const handleRowClick = (id) => {
    return (window.location.href = `/content-details/${id}`);
  };

  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      const isNearBottom = scrollHeight - scrollTop - 5 < clientHeight;
      if (isNearBottom && offset < count - LIMIT && count > LIMIT) {
        setCurrentPage(currentPage + 1);
        listRef.current.scrollTo({ top: 0, behaviour: 'smooth' });
      }
    }
  };

  return (
    <>
      <div className="content-table">
        <TableControlButtons
          count={count}
          addName="+ Add content"
          removeName="Remove content"
          link={'/upload-content'}
          handleRemove={handleRemoveContent}
        />
        <div className="table-responsive" onScroll={handleScroll} ref={listRef}>
          <table id="table" className="content-table-display" style={{ width: '100%', height: '100%' }}>
            <thead>
              <tr style={{ display: !tableData?.length && 'inline-table' }}>
                <td>
                  <input type="checkbox" onChange={handleCheckAll} />
                </td>
                {columns.map((ele, index) => {
                  return (
                    <td key={index}>
                      {ele.columnType === 'text' ? (
                        ele.hasSort ? (
                          <div className="row-data-has-sort">
                            <div className="row-data-header-text">{ele.columnName}</div>
                            <div className="sort__arrows">
                              <img
                                src={sortArrowUp}
                                alt="sort ascending order"
                                /* onClick={() => {
                                                          try {
                                                              let newArray = _.orderBy(sortData, [ele.column_key], ["asc"])
                                                              setTableData(newArray)
                                                          } catch (err) {
                                                              console.log(err)
                                                          }
                                                      }} */
                              />
                              <img
                                src={sortArrowDown}
                                alt="sorting descending order"
                                /* onClick={() => {
                                                          try {
                                                              let newArray = _.orderBy(sortData, [ele.column_key], ["desc"])
                                                              setTableData(newArray)
                                                          } catch (err) {
                                                              console.log(err)
                                                          }
                                                      }} */
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="row-data-header-text">{ele.columnName}</div>
                        )
                      ) : (
                        <div>
                          <img src={ele.columnName} alt={ele.column_key} title={ele.column_key} />
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableData && tableData.length > 0 ? (
                tableData?.map((ele, index) => {
                  let date = ele.added_date?.slice(0, 10);
                  let displayDate = new Date(date).toLocaleDateString('en-us', { day: 'numeric', month: 'short', year: 'numeric' });
                  return (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          id={ele.content_id}
                          value={ele.content_id}
                          onChange={handleCheckBoxClick}
                          checked={ele.checked}
                        />
                      </td>
                      <td onClick={() => handleRowClick(ele.content_id)}>{ele.display_name}</td>
                      <td onClick={() => handleRowClick(ele.content_id)}>{ele.tile_caption ? ele.tile_caption : 'NA'}</td>
                      <td onClick={() => handleRowClick(ele.content_id)}>{ele.file_type ? ele.file_type : 'Web URL'}</td>
                      <td onClick={() => handleRowClick(ele.content_id)}>{displayDate}</td>
                      <td onClick={() => handleRowClick(ele.content_id)}>{ele.duration_in_min}</td>
                      <td onClick={() => handleRowClick(ele.content_id)}>
                        {ele.is_curated ? <img src={Curated} alt="curated" /> : <img src={notCurated} alt="not curated" />}
                      </td>
                      <td onClick={() => handleRowClick(ele.content_id)}>
                        {ele.sharing !== 'Global' ? (
                          ele.sharing === 'Organisation' ? (
                            <img src={Institution} alt="sharing is organisation" />
                          ) : (
                            <img src={Private} alt="sharing is private" />
                          )
                        ) : (
                          ele.sharing === 'Global' && <img src={Global} alt="Sharing is global" />
                        )}
                      </td>
                      <td onClick={() => handleRowClick(ele.content_id)}>{ele.view}</td>
                      <td onClick={() => handleRowClick(ele.content_id)}>{ele.Namaste}</td>
                      <td onClick={() => handleRowClick(ele.content_id)}>{ele.study_circle_count}</td>
                    </tr>
                  );
                })
              ) : (
                <tr className="table table-responsive">
                  <td className="no-data-block" style={{ width: '99%' }}>
                    <img
                      src={NoDataFound}
                      alt="pic showing no data found message"
                      style={{ width: '300px', marginLeft: '-85px', marginTop: '-10px' }}
                    />
                    <div className="no-data-text">{'No data found , please reset the filter or add data'}</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />
    </>
  );
};

export default Table;
