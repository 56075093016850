import React from 'react';
import './Header.css';
import Logo from '../../Assets/logo.png';

const Header = (props) => {
  return (
    <div className="header">
      <div className="header__logo">
        <img src={Logo} alt="Stucle-logo" />
      </div>
      <hr className="header__line"></hr>
    </div>
  );
};

export default Header;
