import React from 'react';
import './AddExplainerVideoSelection.css';

const AddExplainerVideoSelection = (props) => {
  const { name, default_option, values, id_name, display_name, marginTop, handleIdChange, value, formErrors } = props;

  return (
    <div>
      <select
        className="explainer-video-selection-modal"
        style={{ marginTop: marginTop ? marginTop : null }}
        onChange={handleIdChange}
        name={name}
        value={value}
      >
        <option value="-1">{default_option}</option>
        {values &&
          values.map((ele, index) => {
            return (
              <option key={index} value={ele[id_name]}>
                {ele[display_name]}
              </option>
            );
          })}
      </select>
      {formErrors && formErrors[name] && (
        <div className="add-content-form-error" style={{ marginTop: '5px', marginBottom: '0' }}>
          {formErrors[name]}
        </div>
      )}
    </div>
  );
};

export default AddExplainerVideoSelection;
